import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'person-phone-list',
  templateUrl: './person-phone-list.component.html',
  styleUrls: ['./person-phone-list.component.scss']
})
export class PersonPhoneListComponent implements OnInit {

  @Input("data") data = [];

  constructor(){}      

  /**
   * 
   * Copy
   * 
   */
  copy(email){

 
    const el = document.createElement('textarea');
    el.value = email;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

   }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}


}

<div class="user-profile">
    <div class="icon" [matMenuTriggerFor]="menu">
        <i class="material-icons">person</i>
    </div>
</div>
<mat-menu class="user-menu" #menu="matMenu">
    <div class="item" mat-menu-item *ngIf="user != null">
        <i class="material-icons">person</i>
        <span>{{user.name}}</span> 
    </div> 
    <!--<div class="item item-button" mat-menu-item (click)="toRoute('/user/profile')">
        <i class="material-icons">notes</i>
        <span>Meu perfil</span>
    </div>-->
    <div class="item item-button" mat-menu-item (click)="logout()">
        <i class="material-icons">logout</i>
        <span>Sair da minha conta</span>
    </div>
</mat-menu>
<loader *ngIf="loader"></loader>

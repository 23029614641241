import { Directive, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const MASK_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MaskNumberDirective),
  multi: true,
};
declare var $:any;

@Directive({
  selector: '[mask-number]',
  providers: [
    MASK_INPUT_CONTROL_VALUE_ACCESSOR,
  ]
})
export class MaskNumberDirective extends DefaultValueAccessor implements OnInit,OnChanges{
  
  @Output("maskChange") maskChange  = new EventEmitter();
  @Input("value") value:any         = 0;
  @Input("setValueIn") setValueIn   = new EventEmitter();
  @Input("precision") precision:any = 2;  

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef
  ) { 

    super(renderer2,el,false);

  }
  setValue(value){
    this.maskChange.emit(this.formatValue(value));
  }
  /***
   * 
   * Seta o formato para o campo
   * 
   */
  setFormat(){
    
    let self = this;

    $(this.el.nativeElement).maskMoney({
      prefix:'', 
      allowNegative: false, 
      thousands:'.', 
      decimal:'.', 
      affixesStay: true,
      formatOnBlur: true,
      selectAllOnFocus: true,
      precision: parseInt(this.precision)
    });
    $(this.el.nativeElement).on('change', function(event) {
      //let value = self.unmask(event.target.value);
      //self.setValue(value); 
    });
    $(this.el.nativeElement).on('keyup', function(event) {
      let value = self.unmask(event.target.value);
      self.setValue(value);
    });

  }
  unmask(value){

    return $(this.el.nativeElement).maskMoney("unmasked")[0];

  }
  setMask(){
    $(this.el.nativeElement).maskMoney("mask");
  }
  formatValue(value){
    return parseFloat(value).toFixed(parseInt(this.precision));
  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(): void {
    console.log(this.precision);
  }
  /**
   * 
   * Changes values
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes: any) {
    
    if(changes.value){
      this.value = this.formatValue(changes.value.currentValue);
      changes.value.currentValue = this.value;
      this.el.nativeElement.value = this.value;
      this.setFormat();
      this.setMask();
    }

  }
  

}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmInputComponent } from 'src/app/components/form/input/dm-input/dm-input.component';
import { FormTitleComponent } from 'src/app/components/form/form-title/form-title.component';
import { FormToolbarComponent } from 'src/app/components/form/form-toolbar/form-toolbar.component';
import { PartialModule } from '../partial/partial.module';
import { DirectiveModule } from '../directive/directive.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { CKEditorModule } from 'ngx-ckeditor';
import { DmInputMultipleSelectComponent } from 'src/app/components/form/input/dm-input-multiple-select/dm-input-multiple-select.component';

@NgModule({
  declarations: [
    DmInputComponent,
    FormTitleComponent,
    FormToolbarComponent,   
    DmInputMultipleSelectComponent      
  ],
  exports: [
    DmInputComponent,
    FormTitleComponent,
    FormToolbarComponent,   
    DmInputMultipleSelectComponent
  ],
  imports: [
    MaterialModule,
    CommonModule,   
    PartialModule,
    DirectiveModule,
    FormsModule,
    CKEditorModule, 
    ReactiveFormsModule
  ]
})
export class FormModule{}

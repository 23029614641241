import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteAdminGuard } from './guard/route-admin-guard';

const routes: Routes = [{
  path: "",
  loadChildren: () => import('src/app/modules/pages/home-page/home-page.module').then(m => m.HomePageModule),
},{
  path: "login",
  loadChildren: () => import('src/app/modules/pages/auth-page/auth-page.module').then(m => m.AuthPageModule),
},{
  path: "user/users",    
  loadChildren: () => import('src/app/modules/pages/user/users-page/users-page.module').then(m => m.UsersPageModule),
  canActivate: [RouteAdminGuard]
},{
  path: "user/group",    
  loadChildren: () => import('src/app/modules/pages/user/user-group-page/user-group-page.module').then(m => m.UserGroupPageModule),
  canActivate: [RouteAdminGuard]
},{ 
  path: "user/profile",    
  loadChildren: () => import('src/app/modules/pages/user/user-profile-page/user-profile-page.module').then(m => m.UserProfilePageModule),
  canActivate: [RouteAdminGuard]
},{
  path: "register/overview",        
  loadChildren: () => import('src/app/modules/pages/register/register-overview-page/register-overview-page.module').then(m => m.RegisterOverviewPageModule),
  canActivate: [RouteAdminGuard]
},{
  path: "register/users",    
  loadChildren: () => import('src/app/modules/pages/register/user-page/user-page.module').then(m => m.UserPageModule),
  canActivate: [RouteAdminGuard]
},{
  path: "register/product/product",    
  loadChildren: () => import('src/app/modules/pages/register/product-page/product-page.module').then(m => m.ProductPageModule),
  canActivate: [RouteAdminGuard]
},{
  path: "register/product/support",    
  loadChildren: () => import('src/app/modules/pages/register/product-support-page/product-support-page.module').then(m => m.ProductSupportPageModule),
  canActivate: [RouteAdminGuard]
},{
  path: "register/product/category",      
  loadChildren: () => import('src/app/modules/pages/register/product-category-page/product-category-page.module').then(m => m.ProductCategoryPageModule),
  canActivate: [RouteAdminGuard]
},{
  path: "register/product/collection",       
  loadChildren: () => import('src/app/modules/pages/register/product-collection-page/product-collection-page.module').then(m => m.ProductCollectionPageModule),  
  canActivate: [RouteAdminGuard]
},{
  path: "register/person/person",       
  loadChildren: () => import('src/app/modules/pages/register/person/person-page/person-page.module').then(m => m.PersonPageModule), 
  canActivate: [RouteAdminGuard]
},{
  path: "financial/overview",       
  loadChildren: () => import('src/app/modules/pages/financial/financial-overview-page/financial-overview-page.module').then(m => m.FinancialOverviewPageModule), 
  canActivate: [RouteAdminGuard] 
},{
  path: "financial/account-movement/category",       
  loadChildren: () => import('src/app/modules/pages/financial/financial-account-category-page/financial-account-movement-category-page.module').then(m => m.FinancialAccountMovementCategoryPageModule), 
  canActivate: [RouteAdminGuard]
},{
  path: "financial/account-movement/pay",       
  loadChildren: () => import('src/app/modules/pages/financial/financial-account-movement-pay-page/financial-account-movement-pay-page.module').then(m => m.FinancialAccountMovementPayPageModule), 
  canActivate: [RouteAdminGuard]
},{
  path: "financial/account-movement/receive",       
  loadChildren: () => import('src/app/modules/pages/financial/financial-account-movement-receive-page/financial-account-movement-receive-page.module').then(m => m.FinancialAccountMovementReceivePageModule), 
  canActivate: [RouteAdminGuard]
},{
  path: "financial/bank",       
  loadChildren: () => import('src/app/modules/pages/financial/bank-page/bank-page.module').then(m => m.BankPageModule), 
  canActivate: [RouteAdminGuard]
},{
  path: "financial/bank-account",        
  loadChildren: () => import('src/app/modules/pages/financial/bank-account-page/bank-account-page.module').then(m => m.BankAccountPageModule), 
  canActivate: [RouteAdminGuard]
},{
  path: "financial/account-movements/:id",          
  loadChildren: () => import('src/app/modules/pages/financial/financial-account-movements-page/financial-account-movements-page.module').then(m => m.FinancialAccountMovementsPageModule), 
  canActivate: [RouteAdminGuard]   
},{
  path: "financial/account-extract",          
  loadChildren: () => import('src/app/modules/pages/financial/financial-account-extract-page/financial-account-extract-page.module').then(m => m.FinancialAccountExtractPageModule), 
  canActivate: [RouteAdminGuard]   
},{
  path: "sale/sale",          
  loadChildren: () => import('src/app/modules/pages/sale/sale-page/sale-page.module').then(m => m.SalePageModule),  
  canActivate: [RouteAdminGuard]    
},{
  path: "sale/order-type",          
  loadChildren: () => import('src/app/modules/pages/sale/order-type-page/order-type-page.module').then(m => m.OrderTypePageModule),  
  canActivate: [RouteAdminGuard]    
},{
  path: "sale/cart",           
  loadChildren: () => import('src/app/modules/pages/sale/sale-cart-page/sale-cart-page.module').then(m => m.SaleCartPageModule),  
  //canActivate: [RouteAdminGuard]    
},{
  path: "sale/cart/success",             
  loadChildren: () => import('src/app/modules/pages/sale/sale-cart-success-page/sale-cart-success-page.module').then(m => m.SaleCartSuccessPageModule),  
  //canActivate: [RouteAdminGuard]    
},{
  path: "sale/order-seller",           
  loadChildren: () => import('src/app/modules/pages/sale/order-seller-page/order-seller-page.module').then(m => m.OrderSellerPageModule),  
  canActivate: [RouteAdminGuard]    
},{
  path: "sale/products",            
  loadChildren: () => import('src/app/modules/pages/sale/sale-products-page/sale-products-page.module').then(m => m.SaleProductsPageModule),  
  canActivate: [RouteAdminGuard]    
},{
  path: "inventory/inventory",          
  loadChildren: () => import('src/app/modules/pages/inventory/inventory-page/inventory-page.module').then(m => m.InventoryPageModule),  
  canActivate: [RouteAdminGuard]    
},{
  path: "inventory/inventory-movement",           
  loadChildren: () => import('src/app/modules/pages/inventory/inventory-movement-page/inventory-movement-page.module').then(m => m.InventoryMovementPageModule),  
  canActivate: [RouteAdminGuard]    
},{
  path: "crm/contact/contact-origin",            
  loadChildren: () => import('src/app/modules/pages/crm/crm-contact-origin-page/crm-contact-origin-page.module').then(m => m.CrmContactOriginPageModule),  
  canActivate: [RouteAdminGuard]    
},{
  path: "crm/contact/contact",                
  loadChildren: () => import('src/app/modules/pages/crm/crm-contact-page/crm-contact-page.module').then(m => m.CrmContactPageModule),   
  canActivate: [RouteAdminGuard]    
},{
  path: "crm/funnel/funnel",              
  loadChildren: () => import('src/app/modules/pages/crm/crm-funnel-page/crm-funnel-page.module').then(m => m.CrmFunnelPageModule),     
  canActivate: [RouteAdminGuard]    
},{
  path: "crm/funnel/overview",              
  loadChildren: () => import('src/app/modules/pages/crm/crm-overview-page/crm-overview-page.module').then(m => m.CrmOverviewPageModule),     
  canActivate: [RouteAdminGuard]     
},{
  path: "crm/funnel/funnel/:id",              
  loadChildren: () => import('src/app/modules/pages/crm/crm-funnel-contacts-page/crm-funnel-contacts-page.module').then(m => m.CrmFunnelContactsPageModule),     
  //canActivate: [RouteAdminGuard]     
},{
  path: "purchase/order-person",                
  loadChildren: () => import('src/app/modules/pages/purchase/purchase-order-person-page/purchase-order-person-page.module').then(m => m.PurchaseOrderPersonPageModule),     
  canActivate: [RouteAdminGuard]     
},{     
  path: '**',  
  pathMatch: 'full', 
  redirectTo: ''   
}];

@NgModule({
  imports: [RouterModule.forRoot(routes,{relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

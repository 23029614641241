import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { MenuService } from 'src/app/services/menu.service';
import { StorageService } from 'src/app/services/storage.service';

declare var $:any;

@Component({
  selector: 'menu-light',
  templateUrl: './menu-light.component.html',
  styleUrls: ['./menu-light.component.scss']
})
export class MenuLightComponent implements OnInit,AfterViewInit {

  @ViewChild("menuContent") menuContent:ElementRef;
  public menuHtml   = "";
  public rules = null;

  constructor(
    private router: Router,
    private menu: MenuService,
    private storage: StorageService,
    private app: AppService
  ){}
  
  /**
  * 
  * Create menu
  * 
  */
  createMenu(){

    this.menuContent.nativeElement.innerHTML = this.menu.getMenuHtml(this.rules);
  
  }
  /**
   * 
   * Ações
   * 
   */
   menuActions(){

    let self = this;
    
    $(this.menuContent.nativeElement).find("a").click(function(){
    
      let item     = $(this);
      let itemIcon = item.find(".seta");
      let itemIconAllSubItens = item.parent().find(".seta");
      let subItens = typeof(item.attr("data-sub-itens")) == "undefined" ? false : true;
      let route    = item.attr("data-route");
      let ul         = item.parent().find("ul").first();
      let ulAllSubItens  = item.parent().find("ul");

      if(subItens){

        if(ul.hasClass("open")){
          ul.removeClass("open");
          ulAllSubItens.removeClass("open");
          itemIcon.text("arrow_right");
          itemIconAllSubItens.text("arrow_right");
        }else{
          ul.addClass("open");
          itemIcon.text("arrow_drop_down");
        }
        
      }else{
        self.router.navigate([route])
      }
    
    });
  

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{

    this.menuHtml = this.menu.getMenuHtml();

  }
  /**
   * 
   * Seta o usuário logado
   * 
   */
   setPermissoes(){

    this.app.updateUser.subscribe(usuario => {
      this.rules = usuario.rules;
      this.createMenu();
      this.menuActions(); 
    });
    
    let usuario = this.storage.getUser();

    if(usuario != null){
      this.rules = usuario.rules;
    }

  }
  /**
   * 
   * After view init
   * 
   */
  ngAfterViewInit(): void {
    this.setPermissoes();
    this.createMenu();
    this.menuActions();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sidebar-default',   
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  constructor(){}   

  /**
   * 
   * Inicaliza as Funções
   * 
   */
  ngOnInit() {
  }

}

<div class="dm-modal">
    <div class="modal-body">
        <div class="icon">
            <i class="material-icons">
                highlight_off
            </i>
        </div>
        <div class="content">  
            <p>Deseja deletar o serviço <strong>{{name}}</strong> do carrinho?</p>
        </div>
    </div>
    <div class="modal-footer">
        <div class="center">
          <button type="button" class="btn btn-one btn-icon" (click)="delete()">
            SIM
          </button> 
          <button type="button" class="btn btn-two btn-icon" (click)="dialog.close()">
            NÃO
          </button> 
        </div>
    </div>
</div>
<loader *ngIf="loader"></loader>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty'
})
export class EmptyPipe implements PipeTransform {

  transform(value:any,args:any): any {
    
    return value === null || value === "null" || value === "" ? "---" : value;

  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';
import { StorageService } from 'src/app/services/storage.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { PaymentErrorModalComponent } from '../modal/payment-error-modal/payment-error-modal.component';
import { PaymentFileErrorModalComponent } from '../modal/payment-file-error-modal/payment-file-error-modal.component';

@Component({
  selector: 'payment-order',
  templateUrl: './payment-order.component.html',
  styleUrls: ['./payment-order.component.scss']
})
export class PaymentOrderComponent implements OnInit {

  @Input("cart") cart        = null;
  @Input("methods") methods  = null;
  public loader              = false;
  public dataItem            = null;
  public content             = 1;
  public maxInstallments     = 0;
  public passengers          = [];
  public comboPassengers     = [];
  @Output("success") success = new EventEmitter();
  
  constructor(
    private api: ApiService, 
    private modal: ModalService,
    private validator: ValidatorService,
    private cartService: CartService,
    private router: Router,
    private dialog: MatDialog,
    private storage: StorageService
  ){}

  /**
   * 
   * @param data 
   * 
   */
  _changeCombo(data){

    this.comboPassengers = [];
    
    setTimeout(() => {
      
      for(let index = 0;index < data.adt.length;index++) {
        
        this.comboPassengers.push(data.adt[index]);
        
      }
      for(let index = 0;index < data.snr.length;index++) {
        
        this.comboPassengers.push(data.snr[index]); 
        
      }
    
    },100);

  }
  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.validator.clear();

      this.validator.isEmpty(this.dataItem.name,"name","- Informe o Nome do Cliente.");
      this.validator.isEmpty(this.dataItem.email,"email","- Informe o E-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- O E-mail informado possui um formato inválido. Informe um formato válido.");
      this.validator.isEmpty(this.dataItem.phone,"phone","- Informe o Telefone.");
      this.validator.isEmpty(this.dataItem.birthday,"birthday","- Informe a Data de Nascimento.");
      this.validator.isEmpty(this.dataItem.cpf,"cpf","- Informe o CPF.");
      this.validator.validCpf(this.dataItem.cpf,"cpf","- O CPF informado é inválido.");
      this.validator.isEmpty(this.dataItem.rg,"rg","- Informe o RG.");

      if(this.dataItem.payment.payment_method_type_id == 1){
        this.validator.isEmpty(this.dataItem.payment.card.holder_name,"holder_name","- Informe o Nome impresso no cartão (Dados do Pagamento).");
        this.validator.isEmpty(this.dataItem.payment.card.number,"number","- Informe o Número do Cartão (Dados do Pagamento).");
        this.validator.isEmpty(this.dataItem.payment.card.expiration_date,"expiration_date","- Informe a Data de Validade (Dados do Pagamento).");
        this.validator.isEmpty(this.dataItem.payment.card.security_code,"security_code","- Informe o Código de Segurança (Dados do Pagamento).");
        this.validator.isEmpty(this.dataItem.payment.card.installments,"installments","- Informe a quantidade de parcelas (Dados do Pagamento).");
      } 

      this.validator.isEmpty(this.dataItem.titular_passenger.document,"titular_document","- Informe o Titular da Reserva.");
      this.validator.isEmpty(this.dataItem.titular_passenger.cpf,"titular_cpf","- Informe o CPF do Passageiro Titular.");
      this.validator.validCpf(this.dataItem.titular_passenger.cpf,"titular_cpf","- O CPF do Passageiro Titular é inválido.");
      this.validator.isEmpty(this.dataItem.titular_passenger.rg,"titular_rg","- Informe o RG do Passageiro Titular.");
      this.validator.isEmpty(this.dataItem.titular_passenger.birthday,"titular_birthday","- Informe a Data de Nascimento do Passageiro Titular.");
      this.validator.isEmpty(this.dataItem.titular_passenger.phone,"titular_phone","- Informe o Telefone do Passageiro Titular.");
      
      if(!this.validator.passes()){
        this.modal.open(this.validator.getMessagesHtml());
        return false;
      }

      this.dataItem.passengers = this.cartService.formatPassengersToList(this.passengers);    
      this.loader = true;

      this.api.sale().order().payment(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          let dataFile     = response.data.file;
          let dataPayment  = response.data.payment;
          
          if(dataFile.status == 500){

            this.dialog.open(PaymentFileErrorModalComponent,{
              width: "500px",
              height: "auto",
              panelClass: ['animated-modal','slideInUp'],
              data: {
                detalhes: dataFile.data
              }
            });

          }else if(dataPayment.status == 500){

            this.dialog.open(PaymentErrorModalComponent,{
              width: "500px",
              height: "auto",
              panelClass: ['animated-modal','slideInUp'],
              data: {
                file: dataFile.data,
                detalhes: dataPayment.data,
                isLink: this.dataItem.payment.payment_method_type_id == 5 ? true : false
              }
            });

          }else{

            this.success.emit(response.data);
             
            this.cartService.updateTotal.emit({
              total: 0,
              qtd: 0
            });

            this.router.navigateByUrl("/sale/cart/payment-approved");

            this.storage.setPayment({  
              paymentLinkData: dataPayment.data,
              hash: response.data.hash, 
              id: response.data.id,
              email: response.data.email,
              total: response.data.total,
              file: dataFile.data,
              isPaymentLink: this.dataItem.payment.payment_method_type_id == 5 ? true : false,
              solicitar_passageiros: response.data.solicitar_passageiros
            });
                   

          }
          
        }else if(response.status == 422){

          this.modal.openPassengers(response.data);

        }else{

          let error   = this.api.formatError(response);
          this.modal.open(error.message);

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.modal.open(error.message);

      });

    }catch(e){

      this.loader = false;

      this.modal.open(e.message);

    }

  }
  /**
   * 
   * Get user by cpf
   * 
   */
  getUserByCpf(cpf){

    try{

      this.api.sale().order().getUserByCpf(cpf).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          if(response.data != null){
            this.setUserByCpf(response.data);
          }

        }

      },(response) => {

        this.loader = false;

      });

    }catch(e){

      
    }

  }
  /**
   * 
   * Set user by cpf
   * 
   */
  setUserByCpf(user){

    if(this.dataItem.name === "" || this.dataItem.name === null){
      this.dataItem.name = user.name;
    }
    if(this.dataItem.email === "" || this.dataItem.email === null){
      this.dataItem.email = user.email;
    }
    if(this.dataItem.birthday === "" || this.dataItem.birthday === null){
      this.dataItem.birthday = user.birthday; 
    }
    if(this.dataItem.rg === "" || this.dataItem.rg === null){
      this.dataItem.rg = user.rg;
    }
    if(this.dataItem.phone === "" || this.dataItem.phone === null){
      this.dataItem.phone = user.phone;
    }

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      name: this.cart.cart.name,
      birthday: this.cart.cart.birthday,
      cpf: this.cart.cart.cpf,
      rg: this.cart.cart.rg,
      email: this.cart.cart.email,
      phone: this.cart.cart.phone,  
      passengers: [],
      titular_passenger: {
        name: null,
        last_name: null,
        cpf: "",
        rg: null,
        type: null,
        birthday: null,
        phone: null,
        use_data: null,
        document: ""
      },
      payment: {
        payment_method_id: null,
        payment_company_id: null,
        payment_method_type_id: null,
        card: { 
          holder_name: "",
          number: "",
          expiration_date: "",
          security_code: "",
          installments: 1
        }
      }
    }

    if(this.methods != null){
      this.dataItem.payment.payment_method_id      = this.methods[0].id;
      this.dataItem.payment.payment_method_type_id = this.methods[0].payment_method_type_id;
      this.dataItem.payment.payment_company_id     = this.methods[0].payment_company_id;
      this.maxInstallments                         = this.methods[0].max_installments;
    }

  }
  /**
   * 
   * Set passengers
   * 
   */
  setPassengers(){

    if(this.cart.passengers != null){

      let passengers = [];

      for (let index = 0; index < this.cart.passengers.length; index++) {
        
        if(this.cart.passengers[index].type == "adt" || this.cart.passengers[index].type == "snr"){

          passengers.push(this.cart.passengers[index]);  

        }
        
      }

      this.comboPassengers = passengers;

    }

  }
  /**
   * 
   * Change titular
   * 
   */
  _changeTitular(p){

    this.dataItem.titular_passenger.name      = p.name;
    this.dataItem.titular_passenger.last_name = p.last_name;
    this.dataItem.titular_passenger.document  = p.document;
    this.dataItem.titular_passenger.type      = p.type;

  }
  /**
   * 
   * Change phone
   * 
   * @param data 
   * 
   */
  _changePhone(data){

    if(this.dataItem.titular_passenger.use_data){
      this.dataItem.titular_passenger.phone = data;
    }

  }
  /**
   * 
   * Change birthday
   * 
   * @param data 
   * 
   */
   _changeBirthday(data){

    if(this.dataItem.titular_passenger.use_data){
      this.dataItem.titular_passenger.birthday = data;
    }

  }
  /**
   * 
   * Change cpf
   * 
   * @param data 
   * 
   */
   _changeCpf(data){

    if(this.dataItem.titular_passenger.use_data){
      this.dataItem.titular_passenger.cpf = data;
    }
    this.validator.clear();
    this.validator.validCpf(data,"cpf","Cpf inválido.");

    if(this.validator.passes()){

      this.getUserByCpf(data);

    }

  }
  /**
   * 
   * Change rg
   * 
   * @param data 
   * 
   */
   _changeRg(data){

    if(this.dataItem.titular_passenger.use_data){
      this.dataItem.titular_passenger.rg = data;
    }

  }
  /**
   * 
   * Change use data
   * 
   */
  _changeUseData(data){

    if(data === true || data === "true"){

      this.setTitularPassengerByData();

    } 

  }
  /***
   * 
   * Set titular passenger by data
   * 
   * 
   **/
  setTitularPassengerByData(){

    this.dataItem.titular_passenger.cpf      = this.dataItem.cpf;
    this.dataItem.titular_passenger.rg       = this.dataItem.rg;
    this.dataItem.titular_passenger.birthday = this.dataItem.birthday;
    this.dataItem.titular_passenger.phone    = this.dataItem.phone;

  }
  /**
   * 
   * Set titular passenger
   * 
   */
  setTitularPassenger(){

    if(this.cart != null){

      if(this.cart.titular_passenger != null){

        this.dataItem.titular_passenger = this.cart.titular_passenger;

      }

    }

  }
  /**
   * 
   * Select
   * 
   */
  _select(m){

    this.dataItem.payment.payment_method_id      = m.id;
    this.dataItem.payment.payment_method_type_id = m.payment_method_type_id;
    this.dataItem.payment.payment_company_id     = m.payment_company_id;
    this.maxInstallments                         = m.max_installments;   

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.initDataItem();
    this.setPassengers();
    this.setTitularPassenger();
  }

}

<div class="sale-order-report-btn">
    <div class="box">
        <button type="button" class="print" (click)="_open(1)">
            <i class="fa-solid fa-print"></i>
        </button>    
    </div>
    <div class="box">
        <button type="button" class="download" (click)="_open(2)">
            <i class="fa-solid fa-file-arrow-down"></i>
        </button>  
    </div>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'checkout-payment-page',
  templateUrl: './checkout-payment-page.component.html',
  styleUrls: ['./checkout-payment-page.component.scss']
})
export class CheckoutPaymentPageComponent implements OnInit {

  constructor() { }

  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

<nav *ngIf="total > pageLimit">
  <ul class="pagination">
    <li class="page-item">
      <a class="page-link" aria-label="Previous" (click)="onPrev()">
        <span aria-hidden="true">&laquo;</span>
        <span class="sr-only">Previous</span>
      </a>
    </li>
    <li *ngFor="let p of pages;" class="page-item" [ngClass]="{'active': pageNumber == p.number}">
      <a class="page-link" *ngIf="displayLi(p)" (click)="onClick(p.number)">
        {{p.text}}
      </a>
    </li>
    <li class="page-item">
      <a class="page-link" aria-label="Next" (click)="onNext()">
        <span aria-hidden="true">&raquo;</span>
        <span class="sr-only">Next</span>
      </a>
    </li>
    <li class="select-footer">
      <mat-form-field>
        <label>Página:</label> 
        <mat-select [(ngModel)]="pageNumber" name="pageNumber" >
          <mat-option *ngFor="let p of pages;" [value]="p.number" (click)="onClick(p.number)">
            {{p.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>  
    </li>
    <li class="select-footer">
      <mat-form-field>
        <label>LIMITE:</label>
        <mat-select [(ngModel)]="pageLimit" name="pageLimit" #inputPageLimit (ngModelChange)="onChangePageLimit()">
          <mat-option *ngFor="let l of itemsLimit;" [value]="l">
            {{l}}
          </mat-option>
        </mat-select>
      </mat-form-field>  
    </li>  
  </ul>
</nav>

import { Component, OnInit, Renderer2,ElementRef, forwardRef, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FormControl, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { AppService } from '../../../services/app.service';
import { map, startWith, takeUntil } from 'rxjs/operators';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DmComboStateComponent),
  multi: true
};

declare var $:any;

@Component({
  selector: 'dm-combo-state',
  templateUrl: './dm-combo-state.component.html',
  styleUrls: ['./dm-combo-state.component.scss'],
  providers: [
    VALUE_ACCESSOR
  ]
})
export class DmComboStateComponent extends DefaultValueAccessor implements OnInit  {

  @Input("data") data     = [];
  @Input("label") label   = "Cidade:"; 
  @Input("ngModel") value = null;
  @Input("text") text     = null;
  @Input("name") name     = "value";
  @Input("type") type     = "request";
  public loader = false;
  @Input("estadoId") estadoId = null;
  @ViewChild("select") select:ElementRef;
  public comboCtrl    = new FormControl();
  public comboFilter: Observable<any>;
  public isOpenSelect = false;
  @Output("changeValue") changeValue = new EventEmitter();
  
  constructor(
    private renderer2: Renderer2,
    private el: ElementRef,
    private _app: AppService,
    private _api: ApiService 
  ){ 
    super(renderer2,el,false);
  }
  /**
   * 
   * Atualiza o valor na VIEW
   * 
   */
  onChangeValue(){
    
    super.writeValue(this.value);
    this.onChange(this.value);
    
  }
   /**
   * 
   * Get dataItem
   * 
   */
    _getDataItem(){

      let data = null;
  
      for(let index = 0; index < this.data.length; index++) {
  
        if(this.value == this.data[index]["id"]){
  
          data = this.data[index];
          break;
  
        }
        
      }
  
      return data;
  
  }
  /***
   * 
   * Verifica a mudança
   * 
   */
   _change(){

      
    let item = this._getDataItem();
    
    if(item != null){
      this.changeValue.emit(item);
    }
    this.onChangeValue();

  }
  /**
   * 
   * Init select
   * 
   */
   initSelect(){

    let self = this;  

    if(typeof(this.select.nativeElement) != "undefined"){

      $(this.select.nativeElement).val(this.value).select2({
        allowClear: false
      });
      $(self.select.nativeElement).on("select2:select",function(e){
        
        let value = $(this).val();        

        self.value = value;
        self._change();  
      
      });

    }


  }
  /**
   * 
   * Seta os dados para o Combo
   * 
   */
   setDataCombo(){

    this.data = [{
      text: "Acre",
      value: 2
    },{
      text: "Alagoas",
      value: 14
    },{
      text: "Amazonas",
      value: 3
    },{
      text: "Amapá",
      value: 6
    },{
      text: "Bahia",
      value: 16
    },{
      text: "Ceará",
      value: 10
    },{
      text: "Distrito Federal",
      value: 27
    },{
      text: "Bahia",
      value: 16
    },{
      text: "Espírito Santo",
      value: 18
    },{
      text: "Goiás",
      value: 26
    },{
      text: "Maranhão",
      value: 8
    },{
      text: "Minas Gerais",
      value: 17
    },{
      text: "Mato Grosso do Sul",
      value: 24
    },{
      text: "Mato Grosso",
      value: 25
    },{
      text: "Pará",
      value: 5
    },{
      text: "Paraíba",
      value: 12
    },{
      text: "Pernambuco",
      value: 13
    },{
      text: "Piauí",
      value: 9
    },{
      text: "Paraná",
      value: 21
    },{
      text: "Rio de Janeiro",
      value: 19
    },{
      text: "Rio Grande do Norte",
      value: 11
    },{
      text: "Rondônia",
      value: 1
    },{
      text: "Roraima",
      value: 1
    },{
      text: "Rio Grande do Sul",
      value: 23
    },{
      text: "Santa Catarina",
      value: 22
    },{
      text: "Sergipe",
      value: 15
    },{
      text: "São Paulo",
      value: 20
    },{
      text: "Tocantins",
      value: 7
    }]

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.setDataCombo();
  }
  /**
   * 
   * On Changes
   * 
   * @param changes 
   * 
   */
   ngOnChanges(changes:any):void{  

    if(changes.value){ 
      if(changes.value.currentValue == null || changes.value.currentValue == "null"){
        this.initSelect(); 
      }
    }

  }
  /**
   * 
   * Init select
   * 
   */
  ngAfterViewInit(): void {
    this.initSelect();
  }
}

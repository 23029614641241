import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input("itens") itens = null;
  public listItens = [{
    text: "Visão Geral",
    routerLink: "/"
  }];  

  constructor(
    private route: Router
  ){}

  /**
   * 
   * To route
   * 
   */
  toRoute(link){

    this.route.navigateByUrl(link);

  }
  /**
   * 
   * Seta os itens do breadcrumbs
   * 
   */
  setItens(){
    
    if(this.itens != null){
          
      if(typeof(this.itens) == "string"){
        this.itens = JSON.parse(this.itens); 
      }
      this.listItens = this.listItens.concat(this.itens);
    
    }  
  
  }
  /**
  * 
  * Inicializa as Funções
  * 
  * 
  */
  ngOnInit(){
   this.setItens();
  }

}

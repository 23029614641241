<div class="modal-form dm-grid-form-filter">
  <form-title [form]="this"></form-title> 
  <form (submit)="filtrar()">
    <div class="form-toolbar">
      <button type="submit" mat-raised-button> 
        <i class="material-icons">search</i>
        <span>Filtrar</span>
      </button>
      <button type="button" mat-raised-button (click)="clear()"> 
        <i class="material-icons">clear</i>
        <span>Limpar</span>
      </button> 
      <button type="button" mat-raised-button (click)="close()"> 
        <i class="material-icons">close</i>
        <span>Fechar</span> 
      </button> 
    </div>  
    <div class="modal-form-content form-custom">
        <div [ngSwitch]="data.dataItem.type">
          <div *ngSwitchCase="'integer'">
            <dm-combo-filter-number [(ngModel)]="dataItem.operador"></dm-combo-filter-number>
            <mat-form-field>
              <label>{{data.dataItem.label}}</label>
              <input type="text" matInput [(ngModel)]="dataItem.value" name="value" integer />
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'text'"> 
            <dm-combo-filter-text [(ngModel)]="dataItem.operador"></dm-combo-filter-text>
            <mat-form-field>
              <label>{{data.dataItem.label}}</label>
              <input type="text" matInput [(ngModel)]="dataItem.value" name="value" /> 
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'cpf'">
            <dm-combo-filter-text [(ngModel)]="dataItem.operador"></dm-combo-filter-text> 
            <mat-form-field>
              <label>{{data.dataItem.label}}</label>
              <input type="text" matInput [(ngModel)]="dataItem.value" name="value" mask="999.999.999-99" /> 
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'boolean'"> 
            <label>{{data.dataItem.label}}</label><br>
            <mat-radio-group [(ngModel)]="dataItem.value" name="value">
              <mat-radio-button value='true'>Sim</mat-radio-button>
              <mat-radio-button value='false'>Não</mat-radio-button>
            </mat-radio-group>
          </div> 
          <div *ngSwitchCase="'tipo_pagamento'">
            <mat-form-field>
              <label>{{data.dataItem.label}}</label>
              <mat-select [(ngModel)]="dataItem.value" name="value">
                <mat-option value="null">---</mat-option>
                <mat-option value="1">CARTÃO</mat-option>
                <mat-option value="2">BOLETO</mat-option>
                <mat-option value="3">CARNÊ</mat-option>
              </mat-select>
            </mat-form-field>
          </div> 
          <div *ngSwitchCase="'data_cart'">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field>
                  <label>DATA INICIAL</label>
                  <input type="text" matInput [(ngModel)]="dataItem.value" name="value" mask="99-99-9999" /> 
                </mat-form-field>  
              </div> 
              <div class="col-md-6">
                <mat-form-field>
                  <label>DATA FINAL</label>
                  <input type="text" matInput [(ngModel)]="dataFim.value" name="data_fim" mask="99-99-9999" /> 
                </mat-form-field>  
              </div>  
            </div>  
          </div>
        </div>  
    </div>  
  </form>
</div>

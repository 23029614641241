<div class="financial-movement-summary-total box-sombra">
    <div class="header">
      <h3>Total do Período (<strong>{{params.date_start | dateExtenso}}</strong> à <strong>{{params.date_end | dateExtenso}}</strong>)</h3>
    </div>
    <div class="content">
      <div class="row">
        <div class="col-md-2">
          <strong>Total Lançamentos:</strong>
        </div> 
        <div class="col-md-10">
          <span>{{total}}</span>
        </div>  
      </div>
      <div class="row">
        <div class="col-md-2">
          <strong *ngIf="type == 1">Pago:</strong>
          <strong *ngIf="type == 2">Recebido:</strong>
        </div> 
        <div class="col-md-10">
          <span *ngIf="totais.total_pago_recebido != null">{{totais.total_pago_recebido | currency:'R$ '}}</span>
          <span *ngIf="totais.total_pago_recebido == null">R$ 0.00</span>
        </div>  
      </div> 
      <div class="row">
        <div class="col-md-2">
          <strong *ngIf="type == 1">À Pagar:</strong>
          <strong *ngIf="type == 2">À Receber:</strong>
        </div> 
        <div class="col-md-10">
          <span *ngIf="totais.total_pagar_receber != null">{{totais.total_pagar_receber | currency:'R$ '}}</span>
          <span *ngIf="totais.total_pagar_receber == null">R$ 0.00</span>
        </div>  
      </div> 
      <div class="row">
        <div class="col-md-2">
          <strong *ngIf="type == 1">Vencidas:</strong>
          <strong *ngIf="type == 2">Em atraso:</strong>
        </div> 
        <div class="col-md-10">
          <span *ngIf="totais.total_vencidas != null">{{totais.total_vencidas | currency:'R$ '}}</span>
          <span *ngIf="totais.total_vencidas == null">R$ 0.00</span>
        </div>  
      </div>
      <div class="row">
        <div class="col-md-2">
          <strong *ngIf="type == 1">Total de Pagamentos:</strong>
          <strong *ngIf="type == 2">Total de Recebimentos:</strong>
        </div> 
        <div class="col-md-10">
          <span *ngIf="totais.total_valor != null">{{totais.total_valor | currency:'R$ '}}</span>
          <span *ngIf="totais.total_valor == null">R$ 0.00</span>
        </div>  
      </div>  
    </div>  
</div>
  
  
<div class="dm-combo-data form-custom">
  <label>{{label}}</label>
  <div class="combo-content-input">
    <select [(ngModel)]="value" name="_value" #select>
      <option [value]="value"  *ngIf="data.length == 0 && value != null">
        {{text}}
      </option>
      <option value="">
        ---
      </option>
      <option *ngFor="let d of data" [value]="d.value">
        {{d.text}}
      </option>
    </select>
    <div class="alert alert-info loader-message-combo" *ngIf="loader">
      Carregando as cidades
    </div> 
  </div> 
</div>    
   
import { Pipe, PipeTransform } from '@angular/core';
import { AppService } from '../services/app.service';

@Pipe({
  name: 'filterCustom'
})
export class FilterCustomPipe implements PipeTransform {  
  
  constructor(
    private app: AppService
  ){

  }

  transform(items:any,dataItem:any):any {
    
    /*if (!items) {
      return items;
    } 
    return items.filter(item => this.app.removerAcentos(item.name.toLowerCase()).indexOf(this.app.removerAcentos(dataItem.name.toLowerCase())) !== -1);
    */

    return items.filter((item) => this.applyFilter(item,dataItem)); 

  }

  applyFilter(item,filter): boolean {
    
    let status = true;

    for (let field in filter) {
      //if (filter[field]) {
        if (typeof filter[field] === 'string') {
          if (this.app.removerAcentos(item[field]).toString().toLowerCase().indexOf(this.app.removerAcentos(filter[field]).toString().toLowerCase()) === -1) {
            status = false;
            break;
          }
        } else if (typeof filter[field] === 'number') {
          if (item[field] !== filter[field]) {
            status = false;
            break;
          }
        }else if (typeof filter[field] === 'boolean'){
          if (item[field] !== filter[field]) {
            status = false;
            break;
          }
        }
      //}
    }
    return status;
  }

}

<div class="payment-order">
    <div class="box box-sombra">
        <div class="header">
            <h3 class="title-h3">Dados do Comprador</h3>
        </div>
        <div class="content"> 
            <div class="row">
                <div class="col-md-4">  
                    <dm-input (changeValue)="_changeCpf($event)" type="mask" setMask="999.999.999-99" [(ngModel)]="dataItem.cpf" label="CPF:" placeholder="***.***.***-**" name="cpf"></dm-input>
                </div>
                <div class="col-md-4">   
                    <dm-input type="uppercase" [(ngModel)]="dataItem.name" label="Nome do Cliente:" name="name"></dm-input>
                </div>
                <div class="col-md-4">  
                    <dm-input [(ngModel)]="dataItem.email" placeholder="nome@provedor.com" label="E-mail:" name="email"></dm-input>
                </div>  
            </div>
            <div class="row">
                <div class="col-md-4">  
                    <dm-input (changeValue)="_changePhone($event)" type="mask" setMask="(99) 99999-9999" [(ngModel)]="dataItem.phone" label="Telefone:" placeholder="(**) *****-****" name="phone"></dm-input>
                </div> 
                <div class="col-md-4">  
                    <dm-input (changeValue)="_changeBirthday($event)" type="mask" setMask="99/99/9999" [(ngModel)]="dataItem.birthday" label="Data de Nascimento:" placeholder="dd/MM/yyyy" name="birthday"></dm-input>
                </div>
                <div class="col-md-4">  
                    <dm-input (changeValue)="_changeRg($event)" [(ngModel)]="dataItem.rg" label="RG:" name="rg"></dm-input>
                </div>
            </div>    
        </div>     
    </div>
    <div class="box box-sombra">
        <div class="header">
            <h3 class="title-h3">Dados dos Passageiros</h3>
        </div>
        <div class="content">       
            <payment-order-passenger (changeCombo)="_changeCombo($event)" [passengers]="passengers" [cart]="cart"></payment-order-passenger>
        </div>
    </div>
    <div class="box box-sombra">
        <div class="header">
            <h3 class="title-h3">Informações do Passageiro Titular</h3>
        </div>
        <div class="content" *ngIf="comboPassengers.length > 0">
            <combo-titular-passenger [value]="dataItem.titular_passenger.document" (changeValue)="_changeTitular($event)" label="Selecione o titular da reserva:" [data]="comboPassengers"></combo-titular-passenger>
            <div class="linha"></div>      
                <dm-input type="slide-toggle" (changeValue)="_changeUseData($event)" label="Utilizar as mesmas informações dos Dados do Comprador." [(ngModel)]="dataItem.titular_passenger.use_data" name="use_data"></dm-input>
            <div class="linha"></div>
            <div class="row">
                <div class="col-md-3">  
                    <dm-input type="mask" setMask="999.999.999-99" [(ngModel)]="dataItem.titular_passenger.cpf" label="CPF:" placeholder="***.***.***-**" name="titular_passenger.cpf"></dm-input>
                </div>
                <div class="col-md-3">  
                    <dm-input [(ngModel)]="dataItem.titular_passenger.rg" label="RG:" name="tituar_passenger.rg"></dm-input>
                </div>
                <div class="col-md-3">  
                    <dm-input type="mask" setMask="(99) 99999-9999" [(ngModel)]="dataItem.titular_passenger.phone" label="Telefone:" placeholder="(**) *****-****" name="ttular_passenger.phone"></dm-input>
                </div> 
                <div class="col-md-3">  
                    <dm-input type="mask" setMask="99/99/9999" [(ngModel)]="dataItem.titular_passenger.birthday" label="Data de Nascimento:" placeholder="dd/MM/yyyy" name="titular_passenger.birthday"></dm-input>
                </div> 
            </div>    
        </div> 
        <div class="content" *ngIf="comboPassengers.length == 0">
            <p>Informe os dados dos passageiros primeiro.</p>
        </div>   
    </div>
    <div class="box box-sombra">
        <div class="header">
            <h3 class="title-h3">Dados dos Pagamento</h3>
        </div>
        <div class="content">
            <div class="methods" *ngIf="methods.length == 1">
                <div [ngSwitch]="methods[0].payment_method_type_id">  
                    <div *ngSwitchCase="'1'">     
                        <payment-card [maxInstallments]="maxInstallments" [total]="cart.total.total" [dataItem]="dataItem.payment.card"></payment-card>
                    </div> 
                </div>
            </div>   
            <div class="methods" *ngIf="methods.length > 1">
                <div class="methods-header">
                    <ul>
                        <li *ngFor="let m of methods" [ngClass]="{'active': m.id == dataItem.payment.payment_method_id}" (click)="_select(m)">
                            <strong>{{m.title}}</strong>  
                        </li>
                    </ul>    
                </div>   
                <div class="methods-content">
                    <div class="item" *ngFor="let m of methods">
                        <div [ngSwitch]="m.payment_method_type_id" *ngIf="m.id == dataItem.payment.payment_method_id">  
                            <div *ngSwitchCase="'1'">     
                                <payment-card [maxInstallments]="maxInstallments" [total]="cart.total.total" [dataItem]="dataItem.payment.card"></payment-card>
                            </div> 
                            <div *ngSwitchCase="'2'">     
                               Boleto (Função não implementada)
                            </div>
                            <div *ngSwitchCase="'3'">     
                                Transferência Eletrônica (Função não implementada)
                            </div> 
                            <div *ngSwitchCase="'4'">     
                                Cartão de Débito (Função não implementada)
                            </div> 
                            <div *ngSwitchCase="'5'">     
                                <p class="message-default">
                                    A reserva será realizada e o link de pagamento para enviar para o cliente será gerado.<br>
                                    Clique em <strong>Finalizar e Gerar link de Pagamento</strong>
                                </p>    
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
    <div class="box box-sombra">
        <div class="content">
            <div class="content-button center">
                <button type="button" class="btn btn-one btn-icon" (click)="send()">
                    <i class="material-icons">paid</i>
                    <span *ngIf="dataItem.payment.payment_method_type_id != 5">Finalizar e Pagar o pedido</span>
                    <span *ngIf="dataItem.payment.payment_method_type_id == 5">Finalizar e Gerar link de Pagamento</span>
                </button> 
            </div>   
        </div>
    </div>
</div>  
<loader *ngIf="loader"></loader> 

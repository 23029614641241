import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'cart-invoice-btn',
  templateUrl: './cart-invoice-btn.component.html',
  styleUrls: ['./cart-invoice-btn.component.scss']
})
export class CartInvoiceBtnComponent implements OnInit {

  public loader = false;

  constructor(
    private modal: ModalService
  ){ }

  /**
   * 
   * Send
   * 
   */
  send(){

    this.modal.open("Função não implementada.");

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'purchase-order-person-status',
  templateUrl: './purchase-order-person-status.component.html',
  styleUrls: ['./purchase-order-person-status.component.scss']
})
export class PurchaseOrderPersonStatusComponent implements OnInit {

  @Input("text") text:any = null;
  @Input("id") id:any = null;

  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}

import { Component, ElementRef } from '@angular/core';
import { ApiService } from './services/api.service';
import { AppService } from './services/app.service';
import { ModalService } from './services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DateService } from './services/date.service';
import { FinancialService } from './services/financial.service';
import { SaleService } from './services/sale.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    AppService,ApiService,ModalService,DateService,FinancialService,SaleService
  ]
})
export class AppComponent {
  
  title = 'sistema';

  constructor(
    public translate: TranslateService,
    private elementRef:ElementRef
  ){

    translate.addLangs(['pt-BR']);
    translate.setDefaultLang('pt-BR');
    
  

  }
  

}

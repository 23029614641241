<div class="cart-steps box-sombra">
    <div class="header">
        <h3 class="title-h3">Resumo</h3>
    </div>
    <div class="content" *ngIf="dataItem != null">    
        <ul>
            <li>
                <span class="name">{{dataItem.name}}</span>
            </li>  
            <li class="flex-center" *ngIf="dataItem.date != null && dataItem.date !== ''">
                <i class="material-icons">event</i>  
                <span>{{dataItem.date}}</span>
            </li> 
            <li *ngIf="dataItem.date != null && dataItem.date !== ''">
                <h4 class="title-h4">Valores para o dia <strong class="color-green">{{dataItem.date}}</strong>:</h4>
                <div class="content flex-center">
                    <div class="box">
                        <i class="material-icons">paid</i> 
                    </div> 
                    <div class="box">
                        <span>Adulto: {{dataItem.tariff_adt | currency: " R$ "}}</span><br>
                        <span>Melhor Idade: {{dataItem.tariff_snr | currency: " R$ "}}</span><br>
                        <span>Criança: {{dataItem.tariff_chd | currency: " R$ "}}</span>
                    </div>
                </div>
            </li> 
            <li *ngIf="dataItem.date != null && dataItem.date !== ''">
                <div class="content-qtd flex-center" *ngIf="dataItem.qtd_adt > 0">
                    <div class="item">
                        <strong>Adultos</strong><br>
                        <span>{{dataItem.qtd_adt}}</span>
                    </div>
                    <div class="item">
                        <strong>M. Idade</strong><br>
                        <span>{{dataItem.qtd_snr}}</span>
                    </div>  
                    <div class="item">
                        <strong>Criança</strong><br>
                        <span>{{dataItem.qtd_chd}}</span>
                    </div> 
                </div>
            </li> 
            <li class="flex-center" *ngIf="dataItem.total != null">
                <i class="material-icons">paid</i> 
                <span class="total">{{dataItem.total | currency: " R$ "}}</span>
            </li>    
        </ul>    
    </div> 
</div>    
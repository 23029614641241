import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';
import { ServicoService } from 'src/app/services/servico.service';

@Component({
  selector: 'cart-add',
  templateUrl: './cart-add.component.html',
  styleUrls: ['./cart-add.component.scss']
})
export class CartAddComponent implements OnInit {

  @Input("product") product = null;
  @Input("dateIn") dateIn   = null;
  @Input("dateOut") dateOut = null;
  @Input("hotel") hotel     = null; 
  public loader = false;
  public step = 1;
  public dataTypes:any = {};
  public dataItem:any = {};
  public message = "";
  public disponibilityArray = [];
  public requireFlight      = false;
  public total  = 0;
  @Output("updateDataItem") updateDataItem = new EventEmitter();
  @Input("dialog") dialog;

  constructor(
    public app: AppService,
    public api: ApiService,
    public modal: ModalService,
    public servico: ServicoService,
    public cart: CartService
  ){


  }
  /**
   * 
   * Store
   * 
   */
  store(){

    try{


      this.loader = true;

      this.api.sale().cart().store(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.cart.sucessModal(this.dataItem.name); 
          this.cart.updateTotal.emit(response.data.total);  
          this.initDataItem();
          this.dialog.close();

        }else{

          let error   = this.api.formatError(response);
          this.modal.open(error.message);

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.modal.open(error.message);

      });

    }catch(e){

      this.loader = false;
      this.modal.open(e.message);

    }

  }
  /**
   * 
   * Set step
   * 
   */
  setStep(step){

    this.step = step;

  }
  /***
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      name: this.product.name,
      manage_id: this.product.manage_id,
      product_id: this.product.id,
      type_service: null,
      type_service_id: null,
      type_transport: null,
      type_transport_id: null,
      type_hiring: null, 
      parameter_service_id: this.product.parameter_service_id,
      zone_region_hotel_id: null,
      zone_region_hotel: null,
      macro_region: "",
      macro_region_id: null,
      city_id: null,
      date: null,
      tariff_adt: 0,
      tariff_snr: 0,
      tariff_chd: 0,
      tariff_inf: 0,
      tariff_service: 0,
      hotel: null,
      hotel_id: null,
      currency_code: "",
      qtd_adt: 1,
      qtd_chd: 0,
      qtd_inf: 0,
      qtd_snr: 0,
      airline: "",
      airline_code: "",
      flight_number: "",
      flight_time: null,
      flight_require: false,
      airport: "",
      flight_number_out: "",
      flight_time_out: null,
      airport_out: null,
      date_out: null,
      lotacao_maxima: 0,
      address_require: false,
      address: "",
      hotel_fixed: this.product.hotel_fixed,
      service_in_out: this.product.service_in_out,
      total: null,
      maximum_capacity: 0
    }
 
    if(this.dataItem.parameter_service_id == 1 || this.dataItem.parameter_service_id == 2 || this.dataItem.service_in_out){
      this.requireFlight           = true;
      this.dataItem.flight_require = this.requireFlight;
    }
    if(this.dataItem.hotel_fixed){     
      this.dataItem.hotel_id              = this.hotel.hotel_id;
      this.dataItem.hotel                 = this.hotel.hotel;
      this.dataItem.city_id               = this.hotel.city_id;
      this.dataItem.zone_region_hotel_id  = this.hotel.zone_region_hotel_id; 
      this.dataItem.zone_region_hotel     = this.hotel.zone_region_hotel;  
    }

    this.updateTotal();  
    this.updateDataItem.emit(this.dataItem); 
    
  }
  /**
   * 
   * Add Cart
   * 
   */
  addCart(){

    try{
      
      this.loader = false;

      this.api.sale().cart().store(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.modal.open("O produto foi adicionado ao carrinho.");

        }else{

          let error   = this.api.formatError(response);
          this.modal.open(error.message);

        }

      }, (response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.modal.open(error.message);

      });

    }catch(e){

      this.loader = false;  
      this.modal.open("Hove um erro: "+e.message);

    }


  }
  /**
   * 
   * Tipo de Serviço
   * 
   */
   getTypes(){

    try{

      this.loader  = true;
      this.message      = "Carregando as informações. Aguarde!";

      this.api.products().product().types(
        this.product.id
      ).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          
          let data = this.servico.agrupaPorTipoServico(response.data);
          
          this.dataTypes = {
            data: data.response,
            countTipo: data.countTipo,
            contemRegular: data.contemRegular,
            contemPrivativo: data.contemPrivativo, 
            status: 1,
            success: data.response.length > 0 ? true : false
          };
          this.checkTypeProduct(); 

        }else{
          
          this.dataTypes = {
            data: "Houve um erro no carregamento.",
            countTipo: null,
            contemRegular: false,
            contemPrivativo: false,
            status: 500,
            success: false
          }
           
        }
        
      },(response) => {

        this.loader= false;

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Checa os tipos de serviço
   * 
   * 
   */
   checkTypeProduct(){

    if(this.dataTypes.countTipo == 1){

      this.dataItem.type_service_id = this.dataTypes.data[0].idTipoServico;
      this.dataItem.type_service    = this.dataTypes.data[0].tipoServico;

      if(this.dataItem.type_service_id == 1){
        if(this.dataItem.hotel_id == null){
          if(this.product.hotel_fixed){
            this.setStep(4);
            return false;
          }
          this.setStep(3);
        }else{
          this.setStep(4);
        }
      }

    }else{

      if(this.dataTypes.contemRegular){ 

        this.dataItem.type_service_id = 1;
        this.dataItem.type_service    = "Regular";

      }else if(this.dataTypes.contemPrivativo){
        
        this.dataItem.type_service_id = 2;
        this.dataItem.type_service    = "Privativo";
      
      }
      
    }

  }
  /**
   * 
   * Change Date
   * 
   */
  _changeDate(data){

    this.dataItem.date      = data.date;
    this.disponibilityArray = data.disponibilityArray;

    let tariffs:any = this.servico.getTarifasByDate2(
      this.dataItem.date,
      this.dataItem.zone_region_hotel_id,
      this.dataItem.city_id,
      this.disponibilityArray
    );

    this.dataItem.tariff_adt      = tariffs.valor_adt; 
    this.dataItem.tariff_chd      = tariffs.valor_chd; 
    this.dataItem.tariff_snr      = tariffs.valor_snr; 
    this.dataItem.tariff_service  = tariffs.valor_servico; 
    this.dataItem.currency_code   = tariffs.codigo_moeda; 
    this.dataItem.type_hiring     = tariffs.tipo_contratacao; 
 
    this.updateTotal(); 
    this.updateDataItem.emit(this.dataItem);

  }
  /**
   * 
   * Change QTDs
   * 
   * @param value 
   * 
   */
   _changeQtd(value){

    this.dataItem.qtd_adt = value.qtd_adt;
    this.dataItem.qtd_chd = value.qtd_chd;
    this.dataItem.qtd_inf = value.qtd_inf;
    this.dataItem.qtd_snr = value.qtd_snr;

    this.updateTotal();

  }
  /**
   * 
   * Update total
   * 
   * 
   */
  updateTotal(){

    if(this.dataItem.type_hiring == 1){

      this.total          = (this.dataItem.qtd_adt*this.dataItem.tariff_adt)+(this.dataItem.qtd_snr*this.dataItem.tariff_snr)+(this.dataItem.qtd_chd*this.dataItem.tariff_chd)+(this.dataItem.qtd_inf*this.dataItem.tariff_inf);
      this.dataItem.total = this.total;
    }else{

      this.total          = this.dataItem.tariff_service;
      this.dataItem.total = this.total;

    }


  }
  /**
   * 
   * Change hotel
   * 
   */
  _changeHotel(value){

    this.dataItem.hotel      = value.hotelPousada;
    this.dataItem.hotel_id   = value.idHotelPousada;

    if(value.idZonaRegiaoHotel.length > 0){
      this.dataItem.zone_region_hotel_id = value.idZonaRegiaoHotel;
    }
    if(value.zonaRegiaoHotel.length > 0){
      this.dataItem.zone_region_hotel = value.zonaRegiaoHotel;
    }
    if(value.idCidadeMunicipio.length > 0){
      this.dataItem.city_id = value.idCidadeMunicipio;
    }
    if(value.solicitarEndereco === "true" || value.solicitarEndereco === true){
      this.dataItem.address_require = true;
    }else{
      this.dataItem.address_require = false;
    }

  }
  /**
   * 
   * Next Step
   * 
   */
   next(){


    let validation        = {data: "",status:true};

    if(this.step == 1){
      validation = this.servico.validStep1(this.dataItem);
    }
    if(this.step == 2){
      validation = this.servico.validStep2(this.dataItem);
    }
    if(this.step == 3){
      validation = this.servico.validStep3(this.dataItem);
    }
    if(this.step == 4){
      validation = this.servico.validStep4(this.dataItem);
    }
    if(this.step == 5 && this.requireFlight){
      //if(!this.dataItem.informar_horario){
        validation = this.servico.validFlight(this.dataItem);
      //}else{
        //validation = this.servico.validHorario(this.dataItem);
      //}
    }
    if(this.step == 6){

      validation = this.servico.validQtds(this.dataItem);

    } 

    if(validation.status){
      
      this.step++;
      if(this.step == 3 && this.product.hotel_fixed){
        this.step++;
      }
      if(this.step == 5 && !this.requireFlight){
        this.step++;
      }
      if(this.step == 5){
        setTimeout(() => {
          //this.createCalendar();
        });
      }
    }else{
      this.modal.open(validation.data);
    }  

    if(this.step == 2){
      if(this.dataTypes.status == null){
        this.getTypes();
      }  
    }

  }
  /**
   * 
   * Prev Step
   * 
   */
  prev(){
    
    this.step--;

    if(this.step == 5 && !this.requireFlight){
      this.step--;
    }

  }
  /**
   * 
   * Set airport
   * 
   */
  changeAirport(data,type=1){

    if(type == 1){
      this.dataItem.airport    = data.text;
    }else{
      this.dataItem.airport_out = data.text;
    }

  }
  /**
   * 
   * Set airline
   * 
   */
   changeAirline(data,type=1){

    if(type == 1){
      this.dataItem.airline      = data.text;
      this.dataItem.airline_code = data.code;
    }else{
      this.dataItem.airline_out      = data.text;
      this.dataItem.airline_code_out = data.code;
    }

  }
  /**
   * 
   * Set airport
   * 
   */
   changeFlightNumber(data,type=1){

    if(type == 1){
      this.dataItem.flight_number    = data;
    }else{
      this.dataItem.flight_number_out = data;
    }

  }
  /**
   * 
   * Set airport
   * 
   */
   changeFlightTime(data,type=1){

    if(type == 1){
      this.dataItem.flight_time = data;
    }else{
      this.dataItem.flight_time_out = data;
    }

  }/**
   * 
   * Set airport
   * 
   */
  changeDate(data){

    this.dataItem.date_out = data;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    setTimeout(() => {
      this.getTypes();
    },300);
    this.initDataItem();
  }

}

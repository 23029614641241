<div class="cart-passenger-qtd">
    <form class="form-custom">
      <div class="row">
        <div class="col-md-6" *ngIf="product.show_adt">
          <div class="item">
            <label>Adultos</label>
            <span class="info">
              {{product.passenger_label_adt}}
            </span>
            <div class="content">
                <div class="content-input">  
                <input type="text" [(ngModel)]="qtd_adt" name="qtd_adt" integer (change)="_changeAdt()" />
                </div>  
                <div class="content-buttons">
                    <button type="button" class="btn btn-two" (click)="menos('adt')">
                        <span>-</span>
                    </button> 
                    <button type="button" class="btn btn-one" (click)="mais('adt')">
                        <span>+</span>
                    </button>  
                </div> 
            </div>
            <div class="tariff">
                <span>Valor: {{dataItem.tariff_adt | currency:"R$ "}}</span>
            </div> 
          </div> 
        </div> 
        <div class="col-md-6" *ngIf="product.show_snr"> 
          <div class="item">
            <label>Melhor Idade</label>
            <span class="info">
              {{product.passenger_label_snr}}
            </span>
            <div class="content">
                <div class="content-input">
                <input type="text" [(ngModel)]="qtd_snr" name="qtd_snr" integer (change)="_changeValue()" />
                </div>  
                <div class="content-buttons">
                    <button type="button" class="btn btn-two" (click)="menos('snr')">
                        <span>-</span>
                    </button> 
                    <button type="button" class="btn btn-one" (click)="mais('snr')">
                        <span>+</span>
                    </button>  
                </div>  
            </div>
            <div class="tariff">
                <span>Valor: {{dataItem.tariff_snr | currency:"R$ "}}</span>
              </div>
          </div> 
        </div>
        <div class="col-md-6" *ngIf="product.show_chd">
          <div class="item">
            <label>Criança Pagante</label>
            <span class="info">
              {{product.passenger_label_chd}}
            </span>
            <div class="content">
                <div class="content-input">
                <input type="text" [(ngModel)]="qtd_chd" name="qtd_chd" integer (change)="_changeValue()" />
                </div>  
                <div class="content-buttons">
                    <button type="button" class="btn btn-two" (click)="menos('chd')">
                        <span>-</span>
                    </button> 
                    <button type="button" class="btn btn-one" (click)="mais('chd')">
                        <span>+</span>
                    </button>  
                </div>  
            </div>
            <div class="tariff">
                <span>Valor: {{dataItem.tariff_chd | currency:"R$ "}}</span>
              </div>
          </div>
        </div>  
        <div class="col-md-6" *ngIf="product.show_inf">
          <div class="item">
            <label>Criança cortesia</label>
            <span class="info">
              {{product.passenger_label_inf}}
            </span>
            <div class="content">
                <div class="content-input">
                <input type="text" [(ngModel)]="qtd_inf" name="qtd_inf" integer (change)="_changeValue()"/>
                </div>  
                <div class="content-buttons">
                    <button type="button" class="btn btn-two" (click)="menos('inf')">
                        <span>-</span>
                    </button> 
                    <button type="button" class="btn btn-one" (click)="mais('inf')">
                        <span>+</span>
                    </button>  
                </div> 
            </div>
          </div>
        </div>   
      </div> 
    </form>   
</div>
  
  
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cart-flight-data',
  templateUrl: './cart-flight-data.component.html',
  styleUrls: ['./cart-flight-data.component.scss']
})
export class CartFlightDataComponent implements OnInit {

  @Input("dataItem") dataItem:any             = {}; 
  @Input("airline") airline                        = null;
  @Input("airlineCode") airlineCode                = null;
  @Input("airport") airport                        = null;
  @Input("flightNumber") flightNumber              = null;
  @Input("flightTime") flightTime                  = null;
  @Output("changeAirline") changeAirline           = new EventEmitter();
  @Output("changeAirport") changeAirport           = new EventEmitter();
  @Output("changeFlightNumber") changeFlightNumber = new EventEmitter();
  @Output("changeFlightTime") changeFlightTime     = new EventEmitter();
  @Output("changeDate") changeDate     = new EventEmitter();
  @Input("date") date = false;
  @Input("minDate") minDate = null;
  @Input("showDate") showDate = false;

  constructor() { }

  /**
   * 
   * Change value
   * 
   */
  _changeAirport(data){ 
    this.changeAirport.emit(data);   
  }
  /**
   * 
   * Change value
   * 
   */
   _changeAirline(data){ 
    this.changeAirline.emit(data);   
  }
  /**
   * 
   * Change flight number
   * 
   */
  _changeFlightNumber(data){
    this.changeFlightNumber.emit(data);
  }
  /**
   * 
   * Change flight time
   * 
   */
  _changeFlightTime(data){
    this.changeFlightTime.emit(data);
  }
  /**
   * 
   * Change flight time
   * 
   */
   _changeDate(data){
    this.changeDate.emit(data);
  }
  /***
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}

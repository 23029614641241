import { MenuService } from './../../../../services/menu.service';
import { Component, OnInit, ElementRef, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import { AppService } from 'src/app/services/app.service';

declare var Mmenu:any;

@Component({
  selector: 'menu-default',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit,AfterViewInit {

  public menuHtml = "";
  @ViewChild("menuContent") menuContent:ElementRef;
  public acessoAdmin = false;
  public user:any    = null;

  constructor(
    private menu: MenuService,
    @Inject(DOCUMENT) private document: HTMLDocument,
    private router: Router,
    private storage: StorageService,
    private app: AppService
  ){}
    
  toRoute(value){

    this.router.navigateByUrl(value);  

  }
  /**
   * 
   * New Menu
   * 
   */
  newMenu(){

    const navExpand = [].slice.call(this.document.querySelectorAll('.nav-expand'))

    const backLink = `<li class="nav-item">
            <a class="nav-link nav-back-link" href="javascript:;">
              Voltar
              <img src='/assets/logo/simbolo.svg' width='25px' height='25px' alt='Digtal Movement - Agência Digital' />
            </a>
        </li>`;

    navExpand.forEach(item => {
        item.querySelector('.nav-expand-content').insertAdjacentHTML('afterbegin', backLink)
        item.querySelector('.nav-link').addEventListener('click', () => { 
          item.classList.add('active')
        })
        item.querySelector('.nav-back-link').addEventListener('click', () => item.classList.remove('active'))
    })


    }
    /**
     * 
     * Set user
     * 
     */
    setUser(u=null){

      this.user = u == null ? this.storage.getUser() : u;

      if(this.user != null){
        this.acessoAdmin = this.user.acessoAdmin == true || this.user.acessoAdmin == "true" ? true : false;
      }

    }
    /**
     * 
     * On data
     * 
     */
    onData(){

      this.app.updateUser.subscribe(response => {

        this.setUser(response);

      });

    }
    /**
     * 
     * Inicializa as Funções
     * 
     */
    ngOnInit(){

      this.menuHtml = this.menu.getMenuHtml();
      this.setUser();
      this.onData();

    }
  /**
   * 
   * Init after view loaded
   * 
   */
  ngAfterViewInit(): void {
    
    setTimeout(() => {
      this.newMenu();
    },500);
        
  }

}

<div class="dm-modal">
    <div class="modal-title">
      <span class="text">Compartilhar</span>
      <div class="item item-close">
        <i class="material-icons" (click)="dialog.close()">close</i> 
      </div>
    </div> 
    <div class="modal-body">
      <div class="box">
        <h3 class="title-h3">Enviar por e-mail</h3>
        <form (submit)="sendEmail()">
          <div class="row">
            <div class="col-md-8">  
              <dm-input [(ngModel)]="dataItem.email" placeholder="nome@provedor.com.br" name="email"></dm-input>
            </div>
            <div class="col-md-4">
              <button type="submit" class="btn btn-one btn-icon">
                <i class="material-icons">
                  forward_to_inbox
                </i>
                <span>Enviar</span>
              </button>  
            </div>
          </div> 
        </form>   
      </div>  
    </div>
</div>
<loader *ngIf="loader"></loader>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ModalService } from 'src/app/services/modal.service';
import { StorageService } from 'src/app/services/storage.service';
import { ValidatorService } from 'src/app/services/validator.service';
import { PaymentErrorModalComponent } from '../modal/payment-error-modal/payment-error-modal.component';

@Component({
  selector: 'payment-order-link',
  templateUrl: './payment-order-link.component.html',
  styleUrls: ['./payment-order-link.component.scss']
})
export class PaymentOrderLinkComponent implements OnInit {

  @Input("paymentData") paymentData = null;
  @Input("methods") methods  = null;
  public loader              = false;
  public dataItem            = null;
  public content             = 1;
  public maxInstallments     = 0;
  public passengers          = [];
  public comboPassengers     = [];
  @Output("success") success = new EventEmitter();
  
  
  constructor(
    private api: ApiService,
    private app: AppService,
    private validator: ValidatorService,
    private modal: ModalService,
    private dialog: MatDialog,
    private storage: StorageService,
    private router: Router
  ){}

  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.validator.clear();

      this.validator.isEmpty(this.dataItem.payment_id,"payment_id","- Informe o ID do Pagamento.");
      this.validator.isEmpty(this.dataItem.file,"file","- O file não foi informado.");

      if(this.dataItem.payment.payment_method_type_id == 1){
        this.validator.isEmpty(this.dataItem.payment.card.holder_name,"holder_name","- Informe o Nome impresso no cartão (Dados do Pagamento).");
        this.validator.isEmpty(this.dataItem.payment.card.number,"number","- Informe o Número do Cartão (Dados do Pagamento).");
        this.validator.isEmpty(this.dataItem.payment.card.expiration_date,"expiration_date","- Informe a Data de Validade (Dados do Pagamento).");
        this.validator.isEmpty(this.dataItem.payment.card.security_code,"security_code","- Informe o Código de Segurança (Dados do Pagamento).");
        this.validator.isEmpty(this.dataItem.payment.card.installments,"installments","- Informe a quantidade de parcelas (Dados do Pagamento).");
      } 

      if(!this.validator.passes()){
        this.modal.open(this.validator.getMessagesHtml());
        return false;
      }

      this.loader = true;

      this.api.paymentLink().order().payment(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          let dataFile     = response.data.file;
          let dataPayment  = response.data.payment;

          if(dataPayment.status == 500){

            this.dialog.open(PaymentErrorModalComponent,{
              width: "500px",
              height: "auto",
              panelClass: ['animated-modal','slideInUp'],
              data: {
                file: dataFile.data,
                detalhes: dataPayment.data,
                isLink: this.dataItem.payment.payment_method_type_id == 5 ? true : false
              }
            });

          }else{

            this.success.emit(response.data);

            this.storage.setPaymentLink({  
              paymentLinkData: this.paymentData,
              hash: response.data.hash, 
              id: response.data.id,
              email: response.data.email,
              total: response.data.total,
              file: dataFile.data
            });

            this.router.navigateByUrl("/payment-link/order/paid");

          }

        }else{

          let error   = this.api.formatError(response);

          this.modal.open(error.message);

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);

        this.modal.open(error.message);

      });

    }catch(e){

      this.loader = false;
      this.modal.open("Houve algo inesperado: "+e.message);

    }

  }
  /**
   * 
   * Init data item
   * 
   */
   initDataItem(){

    this.dataItem = { 
      payment_id: this.paymentData.payment_id,
      file: this.paymentData.file,
      payment: {
        payment_method_id: null,
        payment_company_id: null,
        payment_method_type_id: null,
        card: { 
          holder_name: "",
          number: "",
          expiration_date: "",
          security_code: "",
          installments: 1
        }
      }
    }

    if(this.methods != null){
      this.dataItem.payment.payment_method_id      = this.methods[0].id;
      this.dataItem.payment.payment_method_type_id = this.methods[0].payment_method_type_id;
      this.dataItem.payment.payment_company_id     = this.methods[0].payment_company_id;
      this.maxInstallments                         = this.methods[0].max_installments;
    }

  }
  /**
   * 
   * Select
   * 
   */
   _select(m){

    this.dataItem.payment.payment_method_id      = m.id;
    this.dataItem.payment.payment_method_type_id = m.payment_method_type_id;
    this.dataItem.payment.payment_company_id     = m.payment_company_id;
    this.maxInstallments                         = m.max_installments;   

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }

}

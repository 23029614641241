<div class="payment-order-passenger form-custom">
    <div class="item" *ngFor="let item of cart.items;let i=index;">
        <div class="content-body">
            <div class="content-left">
                <div class="capa" *ngIf="item.image != null" [ngStyle]="{'background-image': 'url(' + item.image + ')'}">
                </div>  
                <div class="capa" *ngIf="item.image == null" style="background-image:url('/images/produto/sem-foto/default-capa.jpg');">
                </div>    
                <span class="nome">{{item.name}}</span> 
                <span class="data-utilizacao">{{item.data_utilizacao_formatada}}</span>
            </div> 
            <div class="content-right">
                <div class="passageiros">
                    <div class="passageiro-item" *ngFor="let p of item.passengers;let y=index">  
                        <span class="name">Passageiro {{y+1}} - {{p.type | typePassengerText}}</span> 
                        <div class="content-select" *ngIf="i > 0 && comboPassenger[p.type].length > 0">
                            <combo-passengers (changeValue)="_clickPassenger($event,p)" label="Selecione um passageiro:" [data]="comboPassenger[p.type]"></combo-passengers>
                        </div>
                        <div class="row">
                            <div class="col-md-4"> 
                                <input type="text" [(ngModel)]="p.name" name="name" placeholder="Nome*" name="name" uppercase (input)="_changeInput(p)" /> 
                            </div> 
                            <div class="col-md-4">   
                                <input type="text" [(ngModel)]="p.last_name" name="last_name" placeholder="Sobrenome*" name="last_name" uppercase (input)="_changeInput(p)" />
                            </div> 
                            <div class="col-md-4">  
                                <input type="text" [(ngModel)]="p.document" name="document" placeholder="Documento*" name="document" (input)="_changeInput(p)" />
                            </div>     
                        </div>
                    </div> 
                </div>  
            </div>  
        </div>
    </div>   
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Routes } from 'src/app/routes/routes';
import { ApiService } from 'src/app/services/api.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'cart-download-btn',
  templateUrl: './cart-download-btn.component.html',
  styleUrls: ['./cart-download-btn.component.scss']
})
export class CartDownloadBtnComponent implements OnInit {

  @Input("id") id = null;
  public loader = false;

  constructor(
    private api: ApiService,
    private modal: ModalService
  ){ }

  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.loader = true;

      this.api.sale().cart().download(this.id).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          var link = document.createElement('a');  
          link.href = Routes.sale.cart.download+"/"+response.data;
          link.download = "cotacao.pdf";
          link.click();

        }else{

          let error   = this.api.formatError(response);
          this.modal.open(error.message);

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.modal.open(error.message);

      });

    }catch(e){

      this.loader = false;
      this.modal.open("Houve um erro: "+e.message);

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ModalService } from 'src/app/services/modal.service';
import { ValidatorService } from 'src/app/services/validator.service';

@Component({
  selector: 'cart-share-modal',
  templateUrl: './cart-share-modal.component.html',
  styleUrls: ['./cart-share-modal.component.scss']
})
export class CartShareModalComponent implements OnInit {

  public id:any       = null;
  public dataItem:any = {};
  public loader       = false;

  constructor(
    public dialog: MatDialogRef<CartShareModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private validator: ValidatorService,
    private modal: ModalService,
    private api: ApiService
  ){}
  
  /**
   * 
   * Send email
   * 
   */
  sendEmail(){

    try{

      this.validator.clear();
      this.validator.isEmpty(this.dataItem.email,"email","- Informe um e-mail.");
      this.validator.isEmail(this.dataItem.email,"email","- O e-mail informado é inválido.");

      if(!this.validator.passes()){ 
        this.modal.open(this.validator.getMessagesHtml());
        return false;
      }

      this.loader = true;

      this.api.sale().cart().shareEmail(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.modal.open("O e-mail foi enviado com sucesso.");
          this.initDataItem();
        
        }else{

          let error   = this.api.formatError(response);
          this.modal.open(error.message);

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.modal.open(error.message);

      });

    }catch(e){

      this.loader = false;

      this.modal.open(e.message);

    }

  }
  /**
   * 
   * Init Data Item
   * 
   **/
  initDataItem(){

    this.dataItem = {
      email: "",
      id: this.data.id
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }

}

<div [ngSwitch]="type" class="form-custom {{classCustom}}">
    <div *ngSwitchCase="'color'" class="input-item"> 
      <label [hidden]="!label">{{label}}<mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input #color type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [disabled]="disabled" [placeholder]="placeholder"/>
      <div class="bg-color" [ngStyle]="{'background': value}"></div> 
    </div>    
    <div *ngSwitchCase="'mask'" class="input-item">
        <label [hidden]="!label">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
        <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [disabled]="disabled" [placeholder]="placeholder" [mask]="setMask" />
    </div>
    <div *ngSwitchCase="'integer'" class="input-item">
      <label [hidden]="!label">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [disabled]="disabled" [placeholder]="placeholder" integer />
    </div>
    <div *ngSwitchCase="'uppercase'" class="input-item">
      <label [hidden]="!label">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [placeholder]="placeholder" [disabled]="disabled" uppercase />
    </div>
    <div *ngSwitchCase="'apelido'" class="input-item">
      <label [hidden]="!label">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [placeholder]="placeholder" [disabled]="disabled" apelido />
    </div>
    <div *ngSwitchCase="'text'" class="input-item"> 
      <label [hidden]="!label">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [placeholder]="placeholder" [disabled]="disabled" />
    </div>
    <div *ngSwitchCase="'password'" class="input-item input-password">
      <label [hidden]="!label">{{label}} <mat-icon [matTooltip]="info" matTooltipPosition="right" *ngIf="info != null">info</mat-icon></label>
      <input [type]="typeInput"  [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [placeholder]="placeholder" [disabled]="disabled" autocomplete="off" />
      <div class="icon" (click)="setType()">
        <i class="material-icons" *ngIf="typeInput == 'text'">visibility</i>
        <i class="material-icons" *ngIf="typeInput == 'password'">visibility_off</i>
    </div>  
    </div>
    <div *ngSwitchCase="'textarea'" class="input-item">
      <label [hidden]="!label">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <textarea [(ngModel)]="value" (input)="onChangeValue()" [placeholder]="placeholder" [rows]="rows" [name]="name" [disabled]="disabled" [maxlength]="max"></textarea>
    </div>  
    <div *ngSwitchCase="'cpf'" class="input-item"> 
      <label>{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" placeholder="000.000.000-00" (input)="onChangeValue()" (maskChange)="maskChange($event)" [name]="name" mask="999.999.999-99" [disabled]="disabled" />
    </div> 
    <div *ngSwitchCase="'checkbox'" class="content-slide-toggle"> 
      <mat-slide-toggle [(ngModel)]="value" (change)="onChangeValue()" [name]="name" [disabled]="disabled">
        {{label}}
      </mat-slide-toggle>
    </div>
    <div *ngSwitchCase="'date'" class="input-item input-date">  
      <label [hidden]="!label">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null" matTooltipPosition="right">info</mat-icon></label>
      <div class="content-input">
        <div class="icon" (click)="openCalendar()">
          <i class="material-icons">today</i>
        </div> 
        <input type="text" [(ngModel)]="value" placeholder="dd/MM/yyyy" (change)="onChangeValue()" (maskChange)="maskChange($event)" mask="99/99/9999" [name]="name"  #datepicker />
      </div>  
    </div>
    <div *ngSwitchCase="'datepicker'" class="input-item"> 
      <label style="margin:0;">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null" matTooltipPosition="right">info</mat-icon></label> 
      <input type="text" [value]="_date" placeholder="99-99-9999" [matDatepicker]="picker" (dateChange)="onDate($event)" (change)="onChangeValue()" (maskChange)="maskChange($event)" mask="99-99-9999" [name]="name" [min]="_minDate" [max]="_maxDate" />
      <mat-datepicker-toggle matSuffix (click)="_openPicker()"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker> 
    </div>
    <div *ngSwitchCase="'money'" class="input-item">  
      <label *ngIf="label != null && label != ''">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [value]="value" (maskChange)="maskChange($event)" [name]="name" (setValueIn)="setValueMoney"  mask-money  />
    </div> 
    <div *ngSwitchCase="'percent'" class="input-item">  
      <label *ngIf="label != null && label != ''">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [value]="value" (maskChange)="maskChange($event)" [name]="name" (setValueIn)="setValueMoney"  mask-percent  />
    </div> 
    <div *ngSwitchCase="'number'" class="input-item"> 
      <label *ngIf="label != null && label != ''">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" matInput [value]="value" (maskChange)="maskChange($event)" [name]="name" (setValueIn)="setValueMoney"  mask-number [disabled]="disabled" />
    </div> 
    <div *ngSwitchCase="'cnpj'" class="input-item"> 
      <label>{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text"[(ngModel)]="value" placeholder="00.000.000/0000-00" (input)="onChangeValue()" (maskChange)="maskChange($event)" [name]="name" mask="99.999.999/9999-99" [disabled]="disabled" />
    </div>
    <div *ngSwitchCase="'slide-toggle'" class="content-slide-toggle input-item"> 
      <mat-slide-toggle [(ngModel)]="value" (change)="onChangeValue()" [name]="name" [disabled]="disabled">
        {{label}}  
      </mat-slide-toggle>
    </div>  
    <div *ngSwitchCase="'ckeditor'" class="input-item"> 
      <label>{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <ck-editor [name]="name" [(ngModel)]="value" allowedContent="true"  skin="moono-lisa" language="pt-BR" [fullPage]="false" (change)="onChangeValue()"></ck-editor>
    </div>
    <div *ngSwitchCase="'time'" class="input-item">
      <label [hidden]="!label">{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [disabled]="disabled" [placeholder]="placeholder" mask="99:99" />
    </div>
    <div *ngSwitchCase="'cep'"> 
      <label>{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" placeholder="00000-000" (input)="onChangeValue()" (maskChange)="maskChange($event)" [name]="name" mask="99999-999" [disabled]="disabled" />
    </div> 
    <div *ngSwitchCase="'email'"> 
      <label>{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null" matTooltipPosition="right">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" (change)="onChangeValue()" (maskChange)="maskChange($event)" [name]="name" />
    </div>
    <div *ngSwitchCase="'telefone'"> 
      <label>{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" placeholder="(99) 9999-9999" (input)="onChangeValue()" (maskChange)="maskChange($event)" [name]="name" mask="(99) 9999-9999" [disabled]="disabled" />
    </div>
    <div *ngSwitchCase="'celular'"> 
      <label>{{label}} <mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" placeholder="(99) 99999-9999" (input)="onChangeValue()" (maskChange)="maskChange($event)" [name]="name" mask="(99) 99999-9999" [disabled]="disabled" />
    </div>
    <div *ngSwitchCase="'whatsapp'" class="content-whatsapp">   
      <label>{{label}}<mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" placeholder="(99) 99999-9999" (input)="onChangeValue()" (maskChange)="maskChange($event)" [name]="name" mask="(99) 99999-9999" [disabled]="disabled" />
      <div class="icon" (click)="openWhatsApp()">
        <i class="fab fa-whatsapp"></i>
      </div>  
    </div>
    <div *ngSwitchCase="'link'" class="content-link">   
      <label>{{label}}<mat-icon [matTooltip]="info" *ngIf="info != null">info</mat-icon></label>
      <input type="text" [(ngModel)]="value" (input)="onChangeValue()" [name]="name" [placeholder]="placeholder" [disabled]="disabled" />
      <div class="icon" (click)="openLink()"> 
        <i class="fa-solid fa-square-arrow-up-right"></i> 
      </div>  
    </div>
 </div>
  
  
  
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'cart-total-btn',
  templateUrl: './cart-total-btn.component.html',
  styleUrls: ['./cart-total-btn.component.scss']
})
export class CartTotalBtnComponent implements OnInit {

  public qtd   = 0;
  public total = 0; 

  constructor(
    private router: Router,
    private cart: CartService
  ){}

  /**
   * 
   * To cart
   * 
   */
  toCart(){

    this.router.navigateByUrl("/sale/cart"); 

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.cart.updateTotal.subscribe(data => {

      this.qtd   = data.qtd;
      this.total = data.total; 

    });
    
  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.onData();
  }

}

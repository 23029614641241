import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'person-email-list',
  templateUrl: './person-email-list.component.html',
  styleUrls: ['./person-email-list.component.scss']
})
export class PersonEmailListComponent implements OnInit {

  @Input("data") data = [];

  constructor(){}   

  /**
   * 
   * Copy
   * 
   */
  copy(email){
 
    const el = document.createElement('textarea');
    el.value = email;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

   }
  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{} 

}

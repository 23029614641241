import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { MenuService } from '../services/menu.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class RouteAdminGuard implements CanActivate {

  constructor(
    private router: Router, 
    private storage: StorageService,
    private app: AppService,
    private menu: MenuService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
      let user = this.storage.getUser();
 
      if(user !=  null){

        if(user.rules != null){
          
          let status = this.menu.isPermitedView(user.rules,state.url);
          if(!status){

            this.router.navigateByUrl("/");

          }

        }

        if(user.acessoAdmin === false || user.acessoAdmin === "false"){
          this.router.navigateByUrl("/");
        }

      }else{
        this.router.navigateByUrl("/"); 
      }

      return true;

  }
}
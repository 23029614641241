<div class="dm-cart-dados-voo form-custom">
    <div class="dm-cart-dados-voo-content">
      <form class="dm-form">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <combo-airport [text]="airport" [value]="airport" (changeValue)="_changeAirport($event)"></combo-airport>
            </div>
          </div>   
          <div class="col-md-6">
            <div class="form-group">
              <combo-airline [text]="airline" [value]="airlineCode" (changeValue)="_changeAirline($event)"></combo-airline>
            </div>
          </div> 
        </div> 
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label>Número do Voo</label> 
              <dm-input type="integer" [(ngModel)]="flightNumber" name="flight_number" (changeValue)="_changeFlightNumber($event)"></dm-input>
            </div>
          </div> 
          <div class="col-md-6">
            <div class="form-group">
              <label>Horário do Voo</label>   
              <dm-input type="time" [(ngModel)]="flightTime" name="flight_time" (changeValue)="_changeFlightTime($event)"></dm-input>
            </div>
          </div>  
        </div>
        <div class="row" *ngIf="showDate != false">
          <div class="col-md-6">
            <div class="form-group">
              <label>Data de Retorno</label>  
              <dm-input type="date" [(ngModel)]="date" name="date" [minDate]="minDate" (changeValue)="_changeDate($event)"></dm-input>
            </div>
          </div>  
        </div>  
      </form>  
    </div>  
</div>
   
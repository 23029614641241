<div class="page-filter">
    <div class="page-filter-header" (click)="_showFilter()">
        <span>Filtrar</span>
        <i class="fas fa-arrow-circle-down ng-star-inserted"></i>
    </div>
    <div class="page-filter-content" *ngIf="showFilter">
        <form class="form form-custom" (submit)="sendFilter()">
            <div class="row">
                <div class="col-md-3">
                      <dm-input [(ngModel)]="dataItem.description" label="Descrição:" name="description"></dm-input>
                </div>  
                <div class="col-md-3"> 
                      <dm-combo (updateDataItem)="_changeCategory($event)" [text]="dataItem.financial_account_category_text" [url]="comboCategory" [(ngModel)]="dataItem.financial_account_category_id" label="Categoria:" name="financial_account_category_id"></dm-combo>
                </div> 
                <div class="col-md-3">
                      <dm-combo (updateDataItem)="_changePerson($event)" [text]="dataItem.person_name" [url]="comboPerson" [(ngModel)]="dataItem.person_id" label="Fornecedor / Cliente:" name="person_id"></dm-combo>
                </div> 
                <div class="col-md-3">
                  <dm-combo (updateDataItem)="_changePartner($event)" [text]="dataItem.partner_name" [url]="comboPartner" [(ngModel)]="dataItem.partner_id" label="Parceiro:" name="partner_id"></dm-combo>
                </div>   
            </div>
            <div class="page-filter-footer">
                <div class="content">
                    <button type="submit" class="btn-one">
                        <i class="fas fa-search"></i>
                        <span>Buscar</span>
                    </button>
                    <button type="button" class="btn-two" (click)="_clear()">
                        <span>Limpar</span>
                    </button> 
                </div> 
            </div>    
        </form>     
    </div>     
</div> 

import { Directive, HostListener, Input,ElementRef, EventEmitter, Output, Renderer2,forwardRef } from '@angular/core';
import { 
  NG_VALUE_ACCESSOR,DefaultValueAccessor
} from '@angular/forms';

const MASK_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MaskDirective),
  multi: true,
};

@Directive({
  selector: '[mask]',
  providers: [
    MASK_INPUT_CONTROL_VALUE_ACCESSOR,
  ],
})
export class MaskDirective extends DefaultValueAccessor{

  onTouched: any;
  onChange: any;
  public code = null;

  @Input('mask') mask: string;
  @Input('maskValue') maskValue;
  @Output("maskChange") maskChange = new EventEmitter();
 
  constructor(
    private renderer2: Renderer2,
    private el: ElementRef) { 
    
      super(renderer2,el,false);
  
  }
  @HostListener('keydown',['$event']) 
  onKeyDown($event: any){

    this.code = $event.keyCode;


  }
  @HostListener('input',['$event']) 
  onInput($event: any){

    return this.formatValue($event) 
    
  }
  private formatValue($event: any){

    var valor = $event.target.value.replace(/\D/g, '');
    var pad = this.mask.replace(/\D/g, '').replace(/9/g, '_');
    var valorMask = valor + pad.substring(0, pad.length - valor.length);
 
    if (this.code === 8) {
      this.onChange(valor);
      return;
    }
    if (valor.length <= pad.length) {
      this.onChange(valor);
    }
    

    var valorMaskPos = 0;
    valor = '';
    for(var i = 0; i < this.mask.length; i++) {
      
      if(isNaN(parseInt(this.mask.charAt(i)))) {
        valor += this.mask.charAt(i);
      }else{
        valor += valorMask[valorMaskPos++];
      }

    }
    if(valor.indexOf('_') > -1){
      valor = valor.substr(0, valor.indexOf('_'));
    }
      
    super.writeValue(valor);
    this.onChange(valor);
  }

  @HostListener('blur', ['$event']) 
  onBlur($event: any) {
    
    if ($event.target.value.length === this.mask.length) {
      return;
    }
    this.onChange('');
    super.writeValue('');
    $event.target.value = '';
    this.maskChange.emit(null);
 
  }


}

<div class="menu-default">
  <div class="header">
    <logo></logo>
  </div> 
  <ul class="nav-items"> 
    <li class="nav-item">
      <a routerLink="/" title="Visão Geral" class="nav-link">
        <span class="material-icons">insights</span>
        Visão Geral
      </a>
    </li> 
    <li class="nav-item nav-expand">
      <a href="javascript::void();" title="Cadastros" class="nav-link nav-expand-link">
        <span class="center">
          <i class="material-icons">people</i>
          Usuários
        </span>  
      </a>
      <ul class="nav-items nav-expand-content">
        <li class="nav-link nav-link-title">
          <a href="javascript::void();" title="Usuários do Sistema">
            Usuários do Sitema
          </a>
        </li>
        <li class="nav-item">  
          <a routerLink="/user/users" title="Usuários" class="nav-link">
            <span class="center"> 
              <i class="material-icons">person</i>
              Usuários
            </span>    
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item nav-expand">
      <a href="javascript::void();" title="Cadastros" class="nav-link nav-expand-link">
        <span class="center">
          <i class="material-icons">edit</i>
          Cadastros
        </span>
      </a>
      <ul class="nav-items nav-expand-content">
        <li class="nav-link nav-link-title">
          <a href="javascript::void();" title="Cadastros">
            Cadastros
          </a>
        </li>
        <li class="nav-item">  
          <a class="nav-link" routerLink="/register/overview" title="Visão Geral">
            <span class="material-icons"> 
              assessment
            </span>
            Visão Geral
          </a>
        </li>
        <li class="nav-item nav-expand">  
          <a href="javascript::void();" title="Cadastros" class="nav-link nav-expand-link">
            <span class="center">
              <i class="material-icons">checklist</i>
              Produtos
            </span>   
          </a>
          <ul class="nav-items nav-expand-content">
            <li class="nav-link nav-link-title">
              <a href="javascript::void();" title="Produtos">
                Produtos
              </a>
            </li>
            <li class="nav-item"> 
              <a class="nav-link" routerLink="/register/product/product" title="Produtos">
                <span class="material-icons"> 
                  inventory_2
                </span>
                Produto
              </a>
            </li>
            <li class="nav-item"> 
              <a class="nav-link" routerLink="/register/product/category" title="Categoria de Produto">
                <span class="material-icons"> 
                  category
                </span>
                Categoria
              </a>
            </li>
            <li class="nav-item"> 
              <a class="nav-link" routerLink="/register/product/collection" title="Coleção de Produto">
                <span class="material-icons">  
                  stars
                </span>
                Coleção  
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item"> 
          <a class="nav-link" routerLink="/register/person/person" title="Pessoas (Clientes, fornecedores, etc)">
            <span class="material-icons"> 
              people
            </span>
            Pessoas
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item nav-expand">
      <a href="javascript::void();" title="Estoque" class="nav-link nav-expand-link">
        <span class="center">
          <i class="material-icons">inventory</i>
          Estoque
        </span>
      </a>
      <ul class="nav-items nav-expand-content">
        <li class="nav-link nav-link-title"> 
          <a href="javascript::void();" title="Estoque">
            Estoque
          </a>
        </li>
        <li class="nav-item"> 
          <a class="nav-link" routerLink="/inventory/inventory" title="Situação do Estoque">
            <span class="material-icons"> 
              fact_check 
            </span>
            Situação do Estoque 
          </a>
        </li>
        <li class="nav-item"> 
          <a class="nav-link" routerLink="/inventory/inventory-movement" title="Movimentação de Estoque">
            <span class="material-icons"> 
              trending_up
            </span>
            Movimentação
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item nav-expand">
      <a href="javascript::void();" title="Financeiro" class="nav-link nav-expand-link">
        <span class="center">
          <i class="material-icons">paid</i>
          Financeiro
        </span>
      </a>
      <ul class="nav-items nav-expand-content">
        <li class="nav-link nav-link-title">
          <a href="javascript::void();" title="Financeiro">
            Financeiro
          </a>
        </li>
        <li class="nav-item">  
          <a class="nav-link" routerLink="/financial/overview" title="Visão Geral">
            <span class="material-icons"> 
              assessment
            </span>
            Visão Geral
          </a>
        </li>
        <li class="nav-item"> 
          <a class="nav-link" routerLink="/financial/account-movement/pay" title="Contas à Pagar">
            <span class="material-icons"> 
              money_off_csred
            </span>
            Contas à Pagar
          </a>
        </li>
        <li class="nav-item"> 
          <a class="nav-link" routerLink="/financial/account-movement/receive" title="Contas à Receber">
            <span class="material-icons"> 
              attach_money
            </span>
            Contas à Receber
          </a>
        </li>
        <li class="nav-item"> 
          <a class="nav-link" routerLink="/financial/account-movement/category" title="Categoria de Conta">
            <span class="material-icons"> 
              category
            </span>
            Categoria de Conta
          </a>
        </li>
        <li class="nav-item"> 
          <a class="nav-link" routerLink="/financial/bank" title="Bancos">
            <span class="material-icons"> 
              account_balance
            </span>
            Bancos
          </a>
        </li>
        <li class="nav-item"> 
          <a class="nav-link" routerLink="/financial/bank-account" title="Contas Bancárias">
            <span class="material-icons"> 
              account_balance_wallet
            </span>
            Contas Bancárias
          </a>
        </li>
        <li class="nav-item"> 
          <a class="nav-link" routerLink="/financial/account-extract" title="Extrato Bancário">
            <span class="material-icons">  
              paid
            </span>
            Extrato  
          </a>
        </li>
      </ul>
    </li>
    <li class="nav-item nav-expand">
      <a href="javascript::void();" title="Vendas" class="nav-link nav-expand-link">
        <span class="center">
          <i class="material-icons">local_atm</i>
          Vendas
        </span>
      </a>
      <ul class="nav-items nav-expand-content">
        <li class="nav-link nav-link-title">
          <a href="javascript::void();" title="Vendas">
            Vendas
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/sale/products" title="Produtos para Venda">
            <span class="material-icons"> 
              production_quantity_limits 
            </span>
            Produtos
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/sale/sale" title="Vendas">
            <span class="material-icons"> 
              money_off_csred
            </span>
            Vendas
          </a>
        </li>
      </ul>
    </li> 
    <li class="nav-item nav-expand">
      <a href="javascript::void();" title="Vendas" class="nav-link nav-expand-link">
        <span class="center">
          <i class="material-icons">people</i>
          CRM
        </span>
      </a>
      <ul class="nav-items nav-expand-content">
        <li class="nav-link nav-link-title">
          <a href="javascript::void();" title="Cadastros">
            CRM
          </a>  
        </li>
        <li class="nav-item">  
          <a class="nav-link" routerLink="/crm/funnel/overview" title="Funil">
            <span class="material-icons"> 
              assessment 
            </span>
            Visão Geral
          </a>
        </li>
        <li class="nav-item nav-expand">
          <a href="javascript::void();" title="Cadastros" class="nav-link nav-expand-link">
            <span class="center">
              <i class="material-icons">people</i>
              Contatos
            </span>
          </a>
          <ul class="nav-items nav-expand-content">
            <li class="nav-link nav-link-title">
              <a href="javascript::void();" title="Contatos">
                CRM / Contatos
              </a>
            </li>
            <li class="nav-item"> 
              <a class="nav-link" routerLink="/crm/contact/contact" title="Contatos">
                <span class="material-icons"> 
                  people
                </span>
                Contatos
              </a>
            </li>
            <li class="nav-item"> 
              <a class="nav-link" routerLink="/crm/contact/contact-origin" title="Contatos - Canais de Origem">
                <span class="material-icons"> 
                  location_on
                </span>
                Canais de Origem 
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item nav-expand">
          <a href="javascript::void();" title="Cadastros" class="nav-link nav-expand-link">
            <span class="center">
              <i class="material-icons">filter_alt</i>
              Funil
            </span>
          </a>
          <ul class="nav-items nav-expand-content">
            <li class="nav-link nav-link-title">
              <a href="javascript::void();" title="Funil">
                CRM / Funil
              </a>
            </li>
            <li class="nav-item">  
              <a class="nav-link" routerLink="/crm/funnel/funnel" title="Funil">
                <span class="material-icons"> 
                  filter_alt
                </span>
                Gestão de Funil
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
    <li class="nav-item nav-expand">
      <a href="javascript::void();" title="Relatórios" class="nav-link nav-expand-link">
        <span class="center">
          <i class="material-icons">equalizer</i>
          Relatório
        </span>
      </a>
    </li>
  </ul>  
</div>

<div class="md-select-custom not-placeholder form-custom">
    <label *ngIf="label != null">{{label}}</label>
    <select [(ngModel)]="value" #select name="_value">
      <option *ngFor="let p of parcelas" [value]="p">
        {{p}}x de {{(total / p) | currency:'R$ '}}
      </option> 
    </select>
  </div> 
  
  
  
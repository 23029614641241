import { Directive, forwardRef, Renderer2, ElementRef } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ApiService } from '../services/api.service';
import { AppService } from '../services/app.service';

const INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ApelidoDirective),
  multi: true,
};

@Directive({
  selector: '[apelido]',
  providers: [
    INPUT_CONTROL_VALUE_ACCESSOR,
  ],
  host: {
    '(input)': 'onInput($event.target.value)',
    '(blur)': 'onTouched()',
  }
})
export class ApelidoDirective extends DefaultValueAccessor{

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef,
    private app: AppService,
    private api: ApiService
  ) {
    super(renderer2,el,false);
  }
  /**
   * 
   * On Input
   * 
   */
  onInput(){
    
    let value = this.formatValue(this.el.nativeElement.value);
    
    super.writeValue(value);
    this.onChange(value);

  }
  /**
   * 
   * Format value
   * 
   * @param value 
   * 
   */
  private formatValue(value: any):any{

    return this.formatTag(value);

  }
  formatTag(value){ 

    if(value != null && typeof(value) != "undefined"){
      
      let valueOut = "";
      
      for (let index = 0; index < value.length; index++) {
              
        let current = value[index];
        let next    = value.length < (index+1) ? null : value[index+1];
      
        if((current == " " && next == "-") || (current == "-" && next == " ")){
          valueOut += ""; 
        }else{
          valueOut += current;
        }
            
      }
      
      value = valueOut;
      
    }
      
    value = value.toLowerCase();
    value = value.replace(new RegExp(/['~:;^`´'".,]/g),"");
    value = value.replace(new RegExp(/[' ']/g),"-");
    value = value.replace(new RegExp(/[áãâà]/g),"a");
    value = value.replace(new RegExp(/[íìî]/g),"i");
    value = value.replace(new RegExp(/[éèê]/g),"e")
    value = value.replace(new RegExp(/[òóõô]/g),"o") 
    value = value.replace(new RegExp(/[úùû]/g),"u") 
          
    return value;

  }

}

<div class="md-select-custom not-placeholder form-custom">
    <label *ngIf="label != null">{{label}}</label>
    <select [(ngModel)]="value" #select name="_value">
      <option [value]="value"  *ngIf="data.length == 0 && value != null">
        {{text}}
      </option>
      <option value="">----</option> 
      <option *ngFor="let d of data" [value]="d._id">
        {{d.name}} {{d.last_name}}
      </option> 
    </select>
</div> 
  
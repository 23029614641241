import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'cart-delete-btn',
  templateUrl: './cart-delete-btn.component.html',
  styleUrls: ['./cart-delete-btn.component.scss']
})
export class CartDeleteBtnComponent implements OnInit {

  @Input("name") name = null;
  @Input("id") id = null;

  constructor(
    private app: AppService,
    private modal: ModalService,
    private cart: CartService
  ) { }
  
  /**
   * 
   * Open
   * 
   */
  open(){

    this.cart.deleteModal(this.name,this.id);

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

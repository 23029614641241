<div class="dm-modal">
    <div class="modal-title">
      <span class="text">Atenção</span>
    </div>  
    <div class="modal-body">
      <div class="content-error">
        <div class="error-icon">
          <i class="material-icons">error</i>
        </div>  
        <article class="description" [innerHtml]="title"></article>
      </div>  
      <div class="content-detail" *ngIf="detail != null && detail != ''">
        <div class="header" (click)="_showMore()" [ngClass]="{'show': showMore}">
          <span>Ver detalhes do erro</span> 
          <div class="icon"> 
            <i class="material-icons">expand_more</i>
          </div>  
        </div>
        <div class="content" *ngIf="showMore">
          <article [innerHtml]="detail"></article>
        </div>
      </div> 
    </div>
    <div class="modal-footer">
      <div class="center">
        <button type="button" class="btn btn-one btn-icon" (click)="close()">
            <i class="material-icons">check_circle</i>
            <span>OK</span>
        </button> 
      </div>
    </div>   
</div>
  
<div class="descricao" [ngClass]="{'bg-success': data.paid,'bg-danger':data.is_later && !data.paid}">
    <span class="text">{{data.description}}</span>
    <span class="parcela" *ngIf="data.repeat">
      {{data.installment_number}}/{{data.occurrences}}
    </span>
    <span class="pago-recebido" *ngIf="data.paid && data.type == 1">
      Pago
    </span>
    <span class="pago-recebido" *ngIf="data.paid && data.type == 2">
      Recebido
    </span> 
</div> 
      
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dm-input-multiple-select',
  templateUrl: './dm-input-multiple-select.component.html',
  styleUrls: ['./dm-input-multiple-select.component.scss']
})
export class DmInputMultipleSelectComponent implements OnInit {

  @Input("data") data   = [];
  @Input("type") type   = 1;
  @Input("value") value = [];

  constructor(){}

  /**
   * 
   * Select
   * 
   * @param id 
   * 
   **/
  _select(dataItem){

    let index = this.getIndex(dataItem.value);

    if(index == null){
      this.value.push({
        value: dataItem.value,
        text: dataItem.text
      });
    }else{

      if(typeof(index.dataItem.id) == "undefined"){
        this.value.splice(index.index,1);  
      }else{

        if(typeof(index.dataItem.delete) == "undefined"){
          this.value[index.index].delete = true;
        }else{ 
          this.value[index.index].delete = this.value[index.index].delete == true ? false : true;
        }

      }

    }

  }
  /**
   * 
   * Is check
   * 
   */
   getIndex(id){

    let i = null;

    for (let index = 0; index < this.value.length; index++) {
      
      if(id == this.value[index].value){
        i = {
          index: index,
          dataItem: this.value[index]
        };
        break;
      }
      
    }

    return i; 

  }
  /**
   * 
   * Is check
   * 
   */
  isCheck(id){

    let status = false;

    for (let index = 0; index < this.value.length; index++) {
      
      if(id == this.value[index].value){
        if(typeof(this.value[index].delete) == "undefined"){
          status = true;
        }else{
          if(this.value[index].delete == false){
            status = true;
          }
        }
        break;
      }
      
    }

    return status;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

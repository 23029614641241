import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'financial-modal-confirm-update',
  templateUrl: './financial-modal-confirm-update.component.html',
  styleUrls: ['./financial-modal-confirm-update.component.scss']
})
export class FinancialModalConfirmUpdateComponent implements OnInit {

  public option = '1';

  constructor(
    public dialog: MatDialogRef<FinancialModalConfirmUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ){}

  /**
   * 
   * Confirma
   * 
   */
  confirm(){
    this.close();
    this.data.fnCallback(this.option,this.data.params.close,this.data.params.resetForm,this.data.self);
  }
  /**
   * 
   * Fecha o modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){}

}

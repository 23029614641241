import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from './../../../../services/api.service';
import { AppService } from './../../../../services/app.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GridService } from 'src/app/services/grid.service';
import { ModalService } from 'src/app/services/modal.service';

declare var $:any;

@Component({
  selector: 'dm-grid-delete',
  templateUrl: './dm-grid-delete.component.html',
  styleUrls: ['./dm-grid-delete.component.scss']
})

export class DmGridDeleteComponent implements OnInit {
  
  @Input("tipo") tipo                = 1;
  @Input("url") url                  = null;
  @Input("id")  id                   = 0;
  @Input("grid") gridView:any        = null;
  @Output("reloadGrid") reloadGrid   = new EventEmitter();
  public loader       = false;
  public modal:MatDialogRef<any> = null;
  public ids = [];
  
  constructor(
    public app: AppService,
    public grid: GridService,
    public api: ApiService,
    public modalService: ModalService 
  ) { }

  /**
   * 
   * Deleta 
   * 
   */
  delete(data){
    
    data.loader = true;
    var message = Object.assign({},data);
    data.message = "Deletando... Aguarde!";

    data.self.api.request().delete(data.self.url,{
      id: data.self.id,
      ids: JSON.stringify(data.self.ids), 
      tipo: data.self.tipo
    }).subscribe(response => {

      data.loader  = false;
      data.message = message.message;

      if(response.status == 1){
        data.self.modal.close(); 
        data.self.reloadGrid.emit();
      }else{

        let message = response.data;
        data.self.modalService.open(message);

      }

    },(response) =>{

      data.loader  = false;
      let error = data.self.api.formatError(response);
      data.self.modalService.open(error.message);

    });

  }
  /**
   * 
   * Modal para a confirmação do delete
   * 
  */
  confirmDelete(){

    try{

      if(this.tipo == 2){

        this.setIdsCheckeds();
        if(this.ids.length == 0){
          this.modalService.open("Marque pelo menos 1 item na grid.");
          return false;
        }
        
      } 
      
      this.modal = this.grid.confirmDelete({
        delete: this.delete,
        self: this,
        message: "Deseja fazer essa exlusão?",
        loader: false
      });


    }catch(e){
      this.app.info(e.message);
    }

  }
  /**
   * 
   * Seta os IDs que foram marcados na GRID
   * 
   * 
   */
  setIdsCheckeds(){


    let el:Element = this.gridView.content.nativeElement; 
    let ids = [];

    $(el.innerHTML).find(".checkBoxDeleteItem.mat-checkbox-checked input").map(function(key,value){
      value = $(value).val(); 
      ids.push(value); 
    });

    this.ids = ids;

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit() {

  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'payment-file-error-modal',
  templateUrl: './payment-file-error-modal.component.html',
  styleUrls: ['./payment-file-error-modal.component.scss']
})
export class PaymentFileErrorModalComponent implements OnInit {

  public detalhes = null;

  constructor(
    public dialog: MatDialogRef<PaymentFileErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public d
  ){

    this.detalhes = typeof(d.detalhes) ? d.detalhes : null; 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

import { Component, OnInit, Output, Input, EventEmitter, OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'dm-grid-paginate',
  templateUrl: './grid-paginate.component.html',
  styleUrls: ['./grid-paginate.component.scss']
})
export class DmGridPaginateComponent implements OnInit,OnChanges,AfterViewInit{

  @Input("pageLimit") pageLimit:any   = 10;
  @Input("pageNumber") pageNumber  = null;
  @Input("total") total            = null;
  @Output("changePage") changePage = new EventEmitter();
  @Output("changePageLimit") changePageLimit = new EventEmitter();
  public pages          = [];
  public number         = null;
  public itemsLimit = [5,10,20,30,50,100,200];

  constructor(){}  
  
  /**
   * 
   * Seta as páginas
   * 
   */
  setPages(){

    if(this.pageLimit != null && this.pageNumber != null && this.total != null){

      this.pages = [];
      let numberPages = Math.ceil(this.total / this.pageLimit);

      for (var index = 0; index < numberPages; index++) {
        
        this.pages.push({
          number: index,
          text: index+1
        });
        
      }

    }

  }
  /**
   * 
   * Acionado quando o usuário clica no link
   * 
   */
  onClick(number){
    
    if(number != this.pageNumber){
      this.changePage.emit(number);
    }
    
  }
  /**
   * 
   * Set Page Limit
   * 
   */
  onChangePageLimit(){
    this.changePageLimit.emit(this.pageLimit);
  }
  /**
   * 
   * Vai para a página anterior
   * 
   */
  onPrev(){

    if(this.pageNumber > 0){
      this.onClick(this.pageNumber-1); 
    }

  }
  /**
   * 
   * Vai para a próxima página
   * 
   */
  onNext(){

    if(!(this.pageNumber == (this.pages.length-1))){
      this.onClick(this.pageNumber+1); 
    }

  }
  /**
   * 
   * @param page 
   * 
   */
  displayLi(page){

    if(this.pageNumber == 0){
      return page.number == this.pageNumber || (page.number == (this.pageNumber+1)) || (page.number == (this.pageNumber+2));
    }else if((this.pages.length-1) == this.pageNumber){
      return page.number == this.pageNumber || (page.number == (this.pageNumber-1)) || (page.number == (this.pageNumber-2));
    }else{
      return page.number == this.pageNumber || (page.number == (this.pageNumber-1)) || (page.number == (this.pageNumber+1));
    }
    

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

      this.setPages();

  }
  ngOnChanges(changes: any): void {
    
    if(changes.total || changes.pageNumber || changes.pageLimit){
      this.setPages();
    }
    if(changes.pageNumber){
      this.number = changes.pageNumber.currentValue;
    }

  }
  /**
   * 
   * After init
   * 
   */
  ngAfterViewInit(): void {
    

  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sale-order-report-btn',
  templateUrl: './sale-order-report-btn.component.html',
  styleUrls: ['./sale-order-report-btn.component.scss']
})
export class SaleOrderReportBtnComponent implements OnInit {

  @Input("id") id = null;

  constructor() { }

  /**
   * 
   * Open
   * 
   */
  _open(type){

    window.open(`/api/order/order/report/${this.id}/${type}`,"_blank");

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

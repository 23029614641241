import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'cart-close-quote-btn',
  templateUrl: './cart-close-quote-btn.component.html',
  styleUrls: ['./cart-close-quote-btn.component.scss']
})
export class CartCloseQuoteBtnComponent implements OnInit {

  @Input("classCustom") classCustom = "";
  public loader = false;

  constructor(
    private api: ApiService,
    private modal: ModalService,
    private router: Router,
    private cart: CartService
  ){}

  /**
   * 
   * Open
   * 
   * 
   */
  open(){

    this.modal.confirm(
      "Deseja fechar esta cotação ?",
      this,
      {
        fnCallback: this.clear
      }
    )

  }
  /**
   * 
   * Init
   * 
   */
  clear(status,self){

    try{

      self.loader = true; 

      self.api.sale().cart().new().subscribe(response => {

        self.loader = false;

        if(response.status == 1){

          self.cart.updateTotal.emit({
            total: 0,
            qtd: 0
          }); 
          self.router.navigate(["/sale/products"],{queryParams:{new:true}});  

        }else{

          let error   = self.api.formatError(response);
          self.modal.open(error.message);

        }

      },(response) => {

        self.loader = false;
        let error   = self.api.formatError(response);

        self.modal.open(error.message);

      });

    }catch(e){

      self.loader = false;
      self.modal.open("Houve um erro: "+e.message);

    }


  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

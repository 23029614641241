import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'financial-movement-summary-total',
  templateUrl: './financial-movement-summary-total.component.html',
  styleUrls: ['./financial-movement-summary-total.component.scss']
})
export class FinancialMovementSummaryTotalComponent implements OnInit {

  @Input("params") params    = null;
  @Input("totais") totais    = null;
  @Input("type")       type  = 1;
  @Input("total")      total = 0; 
  
  constructor(){}

  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleAddComponent } from 'src/app/components/order/sale/sale-add/sale-add.component';
import { PartialModule } from '../../partial/partial.module';
import { SaleTotalBtnComponent } from 'src/app/components/order/sale/sale-total-btn/sale-total-btn.component';
import { SaleItemComponent } from 'src/app/components/order/sale/sale-item/sale-item.component';
import { SaleAddSuccessModalComponent } from 'src/app/components/order/sale/modal/sale-add-success-modal/sale-add-success-modal.component';

@NgModule({
  declarations: [
    SaleAddComponent,
    SaleTotalBtnComponent,
    SaleItemComponent,
    SaleAddSuccessModalComponent
  ],
  entryComponents: [  
    SaleAddSuccessModalComponent
  ],
  exports: [
    SaleAddComponent,
    SaleTotalBtnComponent,
    SaleItemComponent ,
    SaleAddSuccessModalComponent
  ],
  imports: [
    CommonModule,
    PartialModule
  ]
})
export class SaleModule { }

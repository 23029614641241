import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  
  public loader = false;
  public user   = null;

  constructor(
    private router: Router,
    private api: ApiService,
    private app: AppService,
    private storage: StorageService
  ){}

  /**
   * 
   * To route
   * 
   */
  toRoute(r){  

    this.router.navigateByUrl(r);  

  }
  /**
   * 
   * Logout
   * 
   */
  logout(){

    try{

      this.loader = true;

      this.api.auth().logout().subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.router.navigateByUrl("/login");

        }else{



        }

      },(response) => {

        this.loader = false;

      });

    }catch(e){


    }


  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.user = this.storage.getUser();
  }

}

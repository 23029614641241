import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from '../services/date.service';

@Pipe({
  name: 'dateExtenso'
}) 
export class DateExtensoPipe implements PipeTransform {

  constructor(
    public DateService: DateService
  ){}
  /***
   * 
   * @param value 
   * @param separator 
   * @returns 
   * 
   ***/
  transform(value: any,separator: any): any {
    
    let _separator = typeof(separator) != "undefined" ? separator : "-";

    return this.DateService.getDateExtensive(value,_separator);

  }

}

<div class="page-error box-sombra">
    <div class="header">
        <h3 class="title-h3">Atenção</h3>
    </div>    
    <div class="content">
        <div class="center">
            <p>Houve algo errado no carregamento do conteúdo.</p>
            <div class="detail" *ngIf="detail != null">
                <h4 class="title-h4">Detalhes: </h4>
                <p>{{detail}}</p>
            </div>  
        </div>  
    </div>
    <div class="footer">
        <div class="center">
            <button type="button" class="btn btn-one btn-icon" (click)="reload.emit()">
                <i class="material-icons">replay_circle_filled</i>
                <span>Recarregar</span>
            </button>   
        </div>
    </div>    
</div>    
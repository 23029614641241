import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'financial-description-col-grid',
  templateUrl: './financial-description-col-grid.component.html',
  styleUrls: ['./financial-description-col-grid.component.scss']
})
export class FinancialDescriptionColGridComponent implements OnInit {

  @Input("data") data = null;

  constructor(){}

  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    
  }
}

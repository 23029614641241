<div class="payment-order-link">
    <div class="box">
        <div class="header">
            <h3 class="title-h3">Dados dos Pagamento</h3>
        </div>    
        <div class="content">
            <div class="methods" *ngIf="methods.length == 1">
                <div [ngSwitch]="methods[0].payment_method_type_id">  
                    <div *ngSwitchCase="'1'">     
                        <payment-card [maxInstallments]="paymentData.max_installments" [total]="paymentData.total" [dataItem]="dataItem.payment.card"></payment-card>
                    </div> 
                </div>
            </div>   
            <div class="methods" *ngIf="methods.length > 1">
                <div class="methods-header">
                    <ul>
                        <li *ngFor="let m of methods" [ngClass]="{'active': m.id == dataItem.payment.payment_method_id}" (click)="_select(m)">
                            <strong>{{m.title}}</strong>  
                        </li>
                    </ul>    
                </div>   
                <div class="methods-content">
                    <div class="item" *ngFor="let m of methods">
                        <div [ngSwitch]="m.payment_method_type_id" *ngIf="m.id == dataItem.payment.payment_method_id">  
                            <div *ngSwitchCase="'1'">     
                                <payment-card [maxInstallments]="paymentData.max_installments" [total]="paymentData.total" [dataItem]="dataItem.payment.card"></payment-card>
                            </div>
                            <div *ngSwitchCase="'2'">     
                               Boleto (Função não implementada)
                            </div>
                            <div *ngSwitchCase="'3'">     
                                Transferência Eletrônica (Função não implementada)
                            </div> 
                            <div *ngSwitchCase="'4'">     
                                Cartão de Débito (Função não implementada)
                            </div> 
                            <div *ngSwitchCase="'5'">     
                                <p class="message-default">
                                    A reserva será realizada e o link de pagamento para enviar para o cliente será gerado.<br>
                                    Clique em <strong>Finalizar e Gerar link de Pagamento</strong>
                                </p>    
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div> 
    <div class="box">
        <div class="content">
            <div class="content-button center">
                <button type="button" class="btn btn-one btn-icon" (click)="send()">
                    <i class="material-icons">paid</i>
                    <span>Finalizar e Pagar o pedido</span>
                </button> 
            </div>   
        </div>
    </div>
</div>  
<loader [hidden]="!loader"></loader>   
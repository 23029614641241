<div class="dm-grid-confirm-delete">
  <div class="dm-grid-confirm-delete-title">
    <h4>Atenção</h4>
  </div>  
  <div class="dm-grid-confirm-delete-content">
    <article class="message alert" [ngClass]="{'alert-info':data.loader,'alert-warning':!data.loader}" [innerHtml]="data.message">
    </article>    
  </div>
  <div class="dm-grid-confirm-delete-footer">
    <button type="modal" mat-raised-button (click)="close()" [disabled]="data.loader">
      Não
    </button> 
    <button type="button" mat-raised-button (click)="sendDelete()" [disabled]="data.loader">
      Sim
    </button>
  </div>   
</div>

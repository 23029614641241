import { EventEmitter, Injectable } from '@angular/core';
import { Routes } from '../routes/routes';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  
  private menuData       = [];
  private permissoes:any = null;
  public openMenuEmitter = new EventEmitter(); 
  public openMenuContent = new EventEmitter(); 
  public openMenuStatus  = new EventEmitter(); 

  constructor() { }
  
  /**
   * 
   * Set status menu
   * 
   */
  setStatusMenu(value){

    if(typeof(sessionStorage) != "undefined"){

      sessionStorage.setItem("status_menu",value)

    }

  }
  /**
   * 
   * Set status menu
   * 
   */
   getStatusMenu(){

    if(typeof(localStorage) != "undefined"){

      let status = localStorage.getItem("status_menu");

      if(status != null){

        return status;

      } 

    }

    return true;

  }
  /**
   * 
   * f
   * 
   */
  getMenuHtml(permissoes=null){
    
    this.permissoes = permissoes; 
    let html        = "";
    this.menuData   = this.getMenu();
    let isPermited  = true;

    this.menuData.forEach(item => {

      if(item.item_pai == 0){

        isPermited = this.permissoes ==  null ? true : this.isPermited(item.apelido);

        if(isPermited){
          if(this.hasItens(item.id)){
            html += this.readItensParent(item);
          }else{
            html += this.itemHtml(item);
          }
        }  

      }
      
    });
  
    return html;

  } 
  /**
   * 
   * Verifica se o item tem sub-itens
   * 
   * @param id 
   * @param menuData 
   * 
   */
  hasItens(id){

    let status = false;
    let item   = null;

    for (var index = 0; index < this.menuData.length; index++) {
      
      item = this.menuData[index];

      if(id == item.item_pai){

        status = true;
        break;

      }
      
    }

    return status;

  }
  /**
   * 
   * Busca os SUB-ITENS
   * 
   * @param item 
   * 
   */
  readItensParent(item){
    
    let isPermited = this.permissoes ==  null ? true : this.isPermited(item.apelido);

    var html    = "";

    if(isPermited){
    
      let itens   = this.getItensParent(item.id);
      let itemSub = null;

      html += "<li class='sub-menu'>";
      html += "<a class='a-sub-itens' data-sub-itens>"; 
      if(item.icon != null){
        html += "<div class='icon'><i class='"+item.icon+"'></i></div>"
      }
      html += "<span>"+item.titulo+"</span>"; 
      html += "<i class='material-icons seta'>arrow_right</i>";
      html += "</a>";
      html += "<ul>";
      for (var index = 0; index < itens.length; index++) {
        
        item = itens[index];
        isPermited = this.permissoes ==  null ? true : this.isPermited(item.apelido);

        if(isPermited){
          if(this.hasItens(item.id)){
            html += this.readItensParent(item);        
          }else{
            html += this.itemHtml(item,"item-of-sub-menu");
          }
        }  

        
      }
      html += "</ul>";
      html += "</li>"; 

    }  
    return html;

  }
  /**
   * 
   * Retorna os sub itens de um item
   * 
   * @param id 
   * @param menuData
   *  
   */
  getItensParent(id,menu=null){

    let data     = [];
    let item     = null;
    let menuData = menu == null ? this.menuData : menu;

    for(var index = 0; index < menuData.length; index++) {
      
      item = this.menuData[index];

      if(id == item.item_pai){
        data.push(item);
      }
      
    }

    return data;

  }
  itemHtml(item,classCustom=""){

    let html  = ""; 
    html += "<li class='"+classCustom+"'>";
    html += "<a data-route='"+item.link+"'>";
    if(item.icon != null){
      html += "<div class='icon'><i class='"+item.icon+"'></i></div>"
    }
    html += "<span>"+item.titulo+"</span>";
    html += "</a>";
    html += "</li>";

    return html;

  }
  /**
   * 
   * Retorna o item da permissão baseado no apelido
   * 
   */
  isPermited(apelido){

    let status = true;

    for(var i = 0; i< this.permissoes.length;i++) {
      
      if(apelido == this.permissoes[i].apelido){
        status = this.permissoes[i].view;
        break;
      }
      
    }


    return status;


  }
  /**
   * 
   * Retorna os itens de um menu
   * 
   * 
   */
  getMenu(){
    
    let menu = [];

    menu.push({id:1,apelido:"home",link:Routes._BASE,icon:"fa-solid fa-house",titulo:"Visão Geral",title:"Visão Geral",item_pai:0,ordem:0});
    
    menu.push({id:2,apelido:"user",link:Routes._BASE+"user",icon:"fa-solid fa-user",titulo:"Usuários",title:"Usuários",item_pai:0,ordem:0});
    menu.push({id:3,apelido:"user.user",link:Routes._BASE+"user/users",icon:null,titulo:"Usuários",title:"Usuários",item_pai:2,ordem:0});
    menu.push({id:40,apelido:"user.group",link:Routes._BASE+"user/group",icon:null,titulo:"Grupo de Permissões",title:"Grupo de Permissões",item_pai:2,ordem:0});
   
    menu.push({id:4,apelido:"register",link:Routes._BASE+"register",icon:"fa-solid fa-file-pen",titulo:"Cadastros",title:"Cadastros",item_pai:0,ordem:0});
    menu.push({id:5,apelido:"register.overview",link:Routes._BASE+"register/overview",icon:null,titulo:"Visão Geral",title:"Visão Geral",item_pai:4,ordem:0});
    menu.push({id:6,apelido:"register.product",link:Routes._BASE+"register/product",icon:null,titulo:"Produtos",title:"Produtos",item_pai:4,ordem:0});
    menu.push({id:7,apelido:"register.product.product",link:Routes._BASE+"register/product/product",icon:null,titulo:"Produtos",title:"Produtos",item_pai:6,ordem:0});
    menu.push({id:8,apelido:"register.product.category",link:Routes._BASE+"register/product/category",icon:null,titulo:"Categoria",title:"Categoria",item_pai:6,ordem:0});
    menu.push({id:9,apelido:"register.product.collection",link:Routes._BASE+"register/product/collection",icon:null,titulo:"Coleção",title:"Coleção",item_pai:6,ordem:0});
    menu.push({id:35,apelido:"register.product.support",link:Routes._BASE+"register/product/support",icon:null,titulo:"Produto em Garantia",title:"Produtos em Garantia",item_pai:6,ordem:0});
    menu.push({id:10,apelido:"register.people",link:Routes._BASE+"register/person/person",icon:null,titulo:"Pessoas (Clientes, Forn.)",title:"Pessoas (Clientes, Forn.)",item_pai:4,ordem:0});
  
    menu.push({id:11,apelido:"inventory",link:Routes._BASE+"inventory",icon:"fa-solid fa-file",titulo:"Estoque",title:"Estoque",item_pai:0,ordem:0});
    menu.push({id:12,apelido:"inventory.inventory",link:Routes._BASE+"inventory/inventory",icon:null,titulo:"Situação do Estoque",title:"Situação do Estoque",item_pai:11,ordem:0});
    menu.push({id:13,apelido:"inventory.inventory-movement",link:Routes._BASE+"inventory/inventory-movement",icon:null,titulo:"Movimentação",title:"Movimentação",item_pai:11,ordem:0});
    
    menu.push({id:14,apelido:"financial",link:Routes._BASE+"financial",icon:"fa-solid fa-coins",titulo:"Financeiro",title:"Financeiro",item_pai:0,ordem:0});
    menu.push({id:15,apelido:"financial.overview",link:Routes._BASE+"financial/overview",icon:null,titulo:"Visão Geral",title:"Visão Geral",item_pai:14,ordem:0});
    menu.push({id:16,apelido:"financial.account-movement.pay",link:Routes._BASE+"financial/account-movement/pay",icon:null,titulo:"Contas à Pagar",title:"Contas à Pagar",item_pai:14,ordem:0});
    menu.push({id:17,apelido:"financial.account-movement.receive",link:Routes._BASE+"financial/account-movement/receive",icon:null,titulo:"Contas à Receber",title:"Contas à Receber",item_pai:14,ordem:0});
    menu.push({id:18,apelido:"financial.account-movement.category",link:Routes._BASE+"financial/account-movement/category",icon:null,titulo:"Categoria de Conta",title:"Categoria de Conta",item_pai:14,ordem:0});
    menu.push({id:19,apelido:"financial.bank",link:Routes._BASE+"financial/bank",icon:null,titulo:"Bancos",title:"Bancos",item_pai:14,ordem:0});
    menu.push({id:20,apelido:"financial.bank-account",link:Routes._BASE+"financial/bank-account",icon:null,titulo:"Contas Bancárias",title:"Contas Bancárias",item_pai:14,ordem:0});
    menu.push({id:21,apelido:"financial.account-extract",link:Routes._BASE+"financial/account-extract",icon:null,titulo:"Extrato Bancário",title:"Extrato Bancário",item_pai:14,ordem:0});

    menu.push({id:22,apelido:"sale",link:Routes._BASE+"sale",icon:"fa-solid fa-cart-shopping",titulo:"Vendas",title:"Vendas",item_pai:0,ordem:0});
    menu.push({id:23,apelido:"sale.products",link:Routes._BASE+"sale/products",icon:null,titulo:"Produtos",title:"Produtos",item_pai:22,ordem:0});
    menu.push({id:24,apelido:"sale.sale",link:Routes._BASE+"sale/sale",icon:null,titulo:"Vendas",title:"Vendas",item_pai:22,ordem:0}); 
    menu.push({id:39,apelido:"sale.order_seller",link:Routes._BASE+"sale/order-seller",icon:null,titulo:"Pedidos para Revendedores",title:"Pedidos para Revendedores",item_pai:22,ordem:0});
    menu.push({id:41,apelido:"sale.order_type",link:Routes._BASE+"sale/order-type",icon:null,titulo:"Tipo de Venda",title:"Tipo de Venda",item_pai:22,ordem:0});

    menu.push({id:25,apelido:"crm",link:Routes._BASE+"crm",icon:"fa-sharp fa-solid fa-chart-simple",titulo:"CRM",title:"CRM",item_pai:0,ordem:2});
    menu.push({id:26,apelido:"crm.funnel.overview",link:Routes._BASE+"crm/funnel/overview",icon:null,titulo:"Visão Geral",title:"Visão Geral",item_pai:25,ordem:0});
    menu.push({id:27,apelido:"crm.contact",link:Routes._BASE+"crm/contact",icon:null,titulo:"CRM / Contatos",title:"CRM / Contatos",item_pai:25,ordem:0});
    menu.push({id:28,apelido:"crm.contact.contact",link:Routes._BASE+"crm/contact/contact",icon:null,titulo:"Contatos",title:"Contatos",item_pai:27,ordem:0});
    menu.push({id:29,apelido:"crm.contact.contact-origin",link:Routes._BASE+"crm/contact/contact-origin",icon:null,titulo:"Canais de Origem",title:"Canais de Origem",item_pai:27,ordem:0});
    menu.push({id:30,apelido:"crm.funnel",link:Routes._BASE+"crm/funnel",icon:null,titulo:"Funil",title:"Funil",item_pai:25,ordem:0});
    menu.push({id:31,apelido:"crm.funnel.funnel",link:Routes._BASE+"crm/funnel/funnel",icon:null,titulo:"Gestão de Funil",title:"Gestão de Funil",item_pai:30,ordem:0});

    menu.push({id:32,apelido:"report",link:Routes._BASE+"report",icon:"fa-sharp fa-solid fa-file",titulo:"Relatórios",title:"Relatórios",item_pai:0,ordem:3});
    menu.push({id:33,apelido:"report.sale",link:Routes._BASE+"report/sale",icon:null,titulo:"Vendas",title:"Vendas",item_pai:32,ordem:0});
    menu.push({id:34,apelido:"report.financial",link:Routes._BASE+"report/financial",icon:null,titulo:"Financeiro",title:"Financeiro",item_pai:32,ordem:0});
    
    menu.push({id:36,apelido:"purchase",link:Routes._BASE+"purchase",icon:"fa-solid fa-bag-shopping",titulo:"Compras",title:"Compras",item_pai:0,ordem:0});
    menu.push({id:37,apelido:"purchase.orders",link:Routes._BASE+"purchase/orders",icon:null,titulo:"Pedidos",title:"Pedidos",item_pai:36,ordem:0});
    menu.push({id:38,apelido:"purchase.order_person",link:Routes._BASE+"purchase/order-person",icon:null,titulo:"Pedidos de Pessoas",title:"Pedidos de Pessoas",item_pai:36,ordem:0});
    

    return menu.sort(this.order);       
  
  }
  /**
   * 
   * Ordenação do Menu
   * 
   */
  order(a,b){

    var ordem_a = typeof(a.ordem) != "undefined" ? a.ordem : 0;
    var ordem_b = typeof(b.ordem) != "undefined" ? b.ordem : 0;

    if (ordem_a > ordem_b) {
      return 1;
    }
    if (ordem_a < ordem_b) {
      return -1;
    }
    return 0;

  }
  /***
   * 
   * Valid user permited view
   * 
   * 
   */
   isPermitedView(rules,url){


    let apelido = null;
    let menu    = this.getMenu();
    let status  = false;

    for (let index = 0; index < menu.length; index++) {
      
      if(menu[index].link == url){

        apelido = menu[index].apelido;
        break;
      
      }
      
    }

    for (let index = 0; index < rules.length; index++) {
      
      if(rules[index].apelido == apelido){

        status = rules[index].view;
        break;
      
      }
      
    }

    return status;

  }



}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Routes } from 'src/app/routes/routes';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'order-form-search-grid',
  templateUrl: './order-form-search-grid.component.html',
  styleUrls: ['./order-form-search-grid.component.scss']
})  
export class OrderFormSearchGridComponent implements OnInit {

  public dataItem          = null;
  public comboType         = null;
  public comboCategory     = null;
  public showFilter        = false;
  public comboPartner      = null;
  @Output("change") change = new EventEmitter();
  public comboCategoria    = null; 
  public comboUserManage   = null;

  public comboAtivo = [{
    value: "",
    text: "---"
  },{
    value: 'true',
    text: "Produtos Ativados"
  },{
    value: 'false',
    text: "Produtos desativados"
  }];
  public comboPayment = [{
    value: 'true',
    text: "Pago"
  },{
    value: 'false',
    text: "À Pagar"
  }];
  public user            = null;

  constructor(
    private storage: StorageService
  ){

    this.user = this.storage.getUser();

  } 
  /**
   * 
   * Clear
   * 
   */
  _clear(){

    this.initDataItem();
    this.sendFilter();

  }
  /**
   * 
   * Send emiter
   * 
   */
  sendFilter(){

    this.change.emit(this.dataItem);
    this.showFilter = false;

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){  

    this.dataItem = { 
      id: null,
      file: null,
      name: null,
      email: null,
      cpf: null,
      date_in: null,
      date_out: null,
      status_payment: null,
      user_id: null,
      user_name: ""
    }  

  }
  /**
   * 
   * Show
   * 
   */
  _showFilter(){

    this.showFilter = this.showFilter ? false : true;

  }
  /**
   * 
   * Change value
   * 
   * @param data
   * 
   */
  _changeValue(data){  

    this.dataItem.user_name = data.text;   

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
    this.comboUserManage = Routes.register.user.combo;
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  @Input("item") item         = null;
  @Input("template") template = 'default';
  public total                = 0;  

  constructor(){}

  /**
   * 
   * Calc
   * 
   */
  calcTotal(){

    if(this.item.type_hiring == 1){
      this.total = (parseFloat(this.item.tariff_adt)*this.item.qtd_adt)+(parseFloat(this.item.tariff_snr)*this.item.qtd_snr)+(parseFloat(this.item.tariff_chd)+this.item.qtd_chd);
    }else{ 
      this.total = this.item.tariff_service;
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.calcTotal();
  }

}

<div class="dm-modal-confirm">
    <div class="dm-modal-confirm-title">
      <h4>Atenção</h4>
    </div>  
    <div class="dm-modal-confirm-content">
      <h4>Você está prestes a alterar um lançamento recorrente. Informe uma opção:</h4>   
      <hr>
      <mat-radio-group [(ngModel)]="option" >
        <mat-radio-button value="1">Alterar apenas este lançamento.</mat-radio-button>
        <mat-radio-button value="2">Alterar este e os próximos lançamentos em aberto.</mat-radio-button>
        <mat-radio-button value="3">Alterar este e todos os lançamentos em aberto.</mat-radio-button>
      </mat-radio-group> 
    </div>
    <div class="dm-modal-confirm-footer">
      <button type="modal" mat-raised-button (click)="close()">
        NÃO
      </button> 
      <button type="modal" mat-raised-button (click)="confirm()">
        SIM
      </button> 
    </div>   
</div>
      
import { Component, OnInit, Input } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'topo-layout',
  templateUrl: './topo.component.html',
  styleUrls: ['./topo.component.scss'] 
})
export class TopoComponent implements OnInit {

  @Input("drawer") drawer:MatDrawer; 
  public icon = "arrow_back";
  private innerWidth:any;

  constructor(
    private menu: MenuService
  ) { } 

  /**
   * 
   * Habilita e desabilita o MENU
   * 
   * 
   */
  toggle(){

    this.drawer.toggle();
  
  }
  onData(){

    this.drawer.closedStart.subscribe(data => {
      this.icon = "menu";
      this.menu.openMenuStatus.emit(false);
      this.menu.setStatusMenu(false);
    });
    this.drawer.openedStart.subscribe(data => {
      this.icon = "close";
      this.menu.openMenuStatus.emit(true);
      this.menu.setStatusMenu(true);
    });

  }
  /**
   * 
   * Verifica de é MOBILE
   * 
   */
  verifyMobile(){

    if(this.innerWidth <= 959){

      this.drawer.close();
      this.menu.setStatusMenu(false);
      this.icon = "menu"; 

    }

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){
    this.innerWidth = window.innerWidth;
    this.onData();
    this.verifyMobile();
  }

}

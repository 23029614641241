import { Component, OnInit, Input, OnChanges, forwardRef, ElementRef,Renderer2, Output, EventEmitter, ViewChild } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { ApiService } from '../../../services/api.service';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor, FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DmComboCityComponent),
  multi: true
};

declare var $:any;

@Component({
  selector: 'dm-combo-city',
  templateUrl: './dm-combo-city.component.html',
  styleUrls: ['./dm-combo-city.component.scss'],
  providers: [
    VALUE_ACCESSOR
  ]
})
export class DmComboCityComponent extends DefaultValueAccessor implements OnInit, OnChanges {

  @Input("data") data     = [];
  @Input("label") label   = "Cidade:"; 
  @Input("ngModel") value = null;
  @Input("text") text     = null;
  @Input("name") name     = "value";
  @Input("type") type     = "request";
  public loader = false;
  @Input("stateId") stateId = null;
  @ViewChild("select") select:ElementRef;
  public comboCtrl    = new FormControl();
  public comboFilter: Observable<any>;
  public isOpenSelect = false;
  @Output("changeValue") changeValue = new EventEmitter();
  
  constructor(
    private renderer2: Renderer2,
    private el: ElementRef,
    private _app: AppService,
    private _api: ApiService 
  ){ 
    super(renderer2,el,false);
  }
  /**
   * 
   * Atualiza o valor na VIEW
   * 
   */
  onChangeValue(){
    
    super.writeValue(this.value);
    this.onChange(this.value);
    
  }
  /**
   * 
   * Retorna os dados
   * 
   */
  getData(){

    try{

      this.loader = true;

      this._api.register().ibge().city().combo(this.stateId).subscribe(resp => {

        let response:any = resp;

        this.loader = false;

        if(response.status == 1){

          this.data  = response.data;
          setTimeout(() => {
            this.initSelect();
          },200);
  
        }

      },(response) => {

        this.loader = false;
        let error   = this._api.formatError(response);
        this._app.info(error.message); 

      });

    }catch(e){

      this.loader = false;
      this._app.info("Houve um erro: "+e.message);

    }
    
  }
   /**
   * 
   * Get dataItem
   * 
   */
    _getDataItem(){

      let data = null;
  
      for(let index = 0; index < this.data.length; index++) {
  
        if(this.value == this.data[index]["id"]){
  
          data = this.data[index];
          break;
  
        }
        
      }
  
      return data;
  
  }
  /***
   * 
   * Verifica a mudança
   * 
   */
   _change(){

      
    let item = this._getDataItem();
    
    if(item != null){
      this.changeValue.emit(item);
    }
    this.onChangeValue();

  }
  /**
   * 
   * Init select
   * 
   */
   initSelect(){

    let self = this;

    if(typeof(this.select.nativeElement) != "undefined"){
      
      $(this.select.nativeElement).val(this.value).select2({
        allowClear: false
      });
      $(self.select.nativeElement).on("select2:select",function(e){
        
        let value = $(this).val();        

        self.value = value;
        self._change();  
      
      });

    }


  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){

  }
  /**
   * 
   * Verifica as mudanças
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any):void{
    
    if(changes.stateId){
      if(typeof(changes.stateId.currentValue) != "undefined"){
        if(changes.stateId.currentValue != null){
          this.getData();
        } 
      }
      if(typeof(changes.value) != "undefined"){   
        if(changes.value.currentValue == null || changes.value.currentValue == "null"){
          this.initSelect(); 
          this.data = [];
        }
      }
    }

  }
  /**
   * 
   * Init select
   * 
   */
  ngAfterViewInit(): void {
    this.initSelect();
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ModalService } from 'src/app/services/modal.service';
import { CartPaymentModalComponent } from '../../modal/cart-payment-modal/cart-payment-modal.component';

@Component({
  selector: 'cart-payment-btn',
  templateUrl: './cart-payment-btn.component.html',
  styleUrls: ['./cart-payment-btn.component.scss']
})
export class CartPaymentBtnComponent implements OnInit {

  @Input("id") id = null;
  public loader   = false;

  constructor(
    private modal: ModalService,
    private api: ApiService,
    private app: AppService,
    private dialog: MatDialog
  ){ }

  /**
   * 
   * Open
   * 
   */
  open(){

    this.dialog.open(
      CartPaymentModalComponent,{
        width: "800px",
        data: {
          id: this.id
        }
      }
    )

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}

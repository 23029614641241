<div class="sale-item box-sombra" *ngIf="template == null">
    <div class="photo sale-item-box">
        <img *ngIf="data.image == null" src="images/sem-foto/image.jpg" alt="Sem Foto" class="img-fluid" />
        <img *ngIf="data.image != null" [src]="data.image" [alt]="data.name" class="img-fluid" />
    </div>  
    <div class="content sale-item-box">
        <h3>{{data.name}}</h3>
        <div class="info">
            <div class="item">
                <strong>Quantidade:</strong>
                <div class="quantity-change">
                    <button type="button" (click)="less()">
                        <span>-</span>
                    </button>
                    <span class="value">{{data.quantity}}</span> 
                    <button type="button" (click)="more()">
                        <span>+</span> 
                    </button>
                </div>
            </div>
            <div class="item">
                <strong>Valor Unitário:</strong>
                <span>{{data.value | currency: " R$ "}}</span>
            </div>
            <div class="item">
                <strong>Subtotal:</strong>
                <span>{{data.total | currency: " R$ "}}</span>
            </div>     
        </div>
    </div>
    <div class="actions sale-item-box">
        <div class="box box-delete">
            <button type="button" class="btn-delete" (click)="_confirmDelete()">
                <i class="material-icons">delete</i>
            </button>  
        </div>   
    </div> 
    <loader classCustom="absolute" [hidden]="!loader"></loader>   
</div> 
<div class="sale-item template-2" *ngIf="template == 'two'">
    <div class="photo sale-item-box">
        <img *ngIf="data.image == null" src="images/sem-foto/image.jpg" alt="Sem Foto" class="img-fluid" />
        <img *ngIf="data.image != null" [src]="data.image" [alt]="data.name" class="img-fluid" />
    </div> 
    <div class="content sale-item-box">
        <h3>{{data.name}}</h3>
        <div class="info">
            <div class="item">
                <strong>Quantidade:</strong>  
                <div class="quantity-change">
                    <button type="button" (click)="less()">
                        <span>-</span>
                    </button>
                    <span class="value">{{data.quantity}}</span>
                    <button type="button" (click)="more()">
                        <span>+</span> 
                    </button>
                </div> 
            </div>
            <div class="item">
                <strong>Valor Unitário:</strong>
                <span>{{data.value | currency: " R$ "}}</span>
            </div>
            <div class="item">
                <strong>Subtotal:</strong>
                <span>{{data.total | currency: " R$ "}}</span>
            </div>     
        </div>
    </div> 
    <loader classCustom="absolute" [hidden]="!loader"></loader>
</div>

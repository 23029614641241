import { AppService } from './../../../services/app.service';
import { ApiService } from './../../../services/api.service';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { Component, OnInit, Input, forwardRef, Renderer2, ElementRef, ViewChild, EventEmitter, Output, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';

declare var $:any;

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DmComboComponent), 
  multi: true,
};

@Component({
  selector: 'dm-combo',
  templateUrl: './dm-combo.component.html',
  styleUrls: ['./dm-combo.component.scss'],
  providers: [
    VALUE_ACCESSOR
  ]
})
export class DmComboComponent extends DefaultValueAccessor implements OnInit,AfterViewInit,OnChanges{
  
  @Input("url") url                 = null;   
  @Input("label") label             = false; 
  @Input("ngModel") value           = null;
  @Input("text") text               = null;
  @Input("name") name               = "value";
  @Input("type") type               = "request";    
  @Input("initRequest") initRequest = false;
  @Input("add") add = false;   
  @Input("reload") reload           = false;   
  @Input("data") data               = [];
  @Input("dataParams") dataParams   = {};  
  public loader                     = false;    
  public comboCtrl                  = new FormControl();
  public comboFilter:Observable<any>;
  public isOpenSelect               = false;
  @Output("updateDataItem") updateDataItem = new EventEmitter();
  @Input("isImg") isImg              = false;
  @Input("imgWidth") imgWidth        = "50px";  
  @Output("changeValue") changeValue = new EventEmitter();
  @Input("disabled") disabled        = false;  
  @ViewChild("select") select:ElementRef;
  @Input("updateData") updateData     = new EventEmitter();  

  constructor(
    private renderer2: Renderer2,
    private el: ElementRef,
    private api: ApiService,
    private app: AppService,
    private modal: ModalService
  ) { 
    
    super(renderer2,el,false); 
      
  }
    
  /**
   * 
   * Retorna a DATA para o ComboBox
   * 
   * 
   */
  getData(open=true){

    try{

      if(this.disabled){
        return false;
      }

      this.loader = true;
      let request = null;

      if(this.type == "file"){
        request = this.api.files().combo(this.url);
      }else{
        request = this.api.request().get(this.url);
      }

      request.subscribe(data => {

        this.loader = false;
        this.data = typeof(data.data) != "undefined" ? data.data : data;
        
        setTimeout(() => {
          /*$(this.select.nativeElement).val(this.value).select2({
            allowClear: false 
          });*/
        },20);
        
        if(this.isOpenSelect){
          setTimeout(() => { 
            //this.initSelect(); 
            $(this.select.nativeElement).select2("close"); 
            if(open){
              $(this.select.nativeElement).select2("open"); 
            } 
          },100); 
        }  
        
      },(response) => {
       
        this.loader = false;
        let error   = this.api.formatError(response);
        this.modal.open(error.message);
      
      });

    }catch(e){

      this.loader = false;
      this.modal.open("Houve um erro: "+e.message); 

    }

  }
  /**
   * 
   * Click in data
   * 
   */
  clickGetData(e){
    
    if(this.data.length == 0){
      this.isOpenSelect = true;
      this.getData();
    }

  }
  /**
   * 
   * Atualiza o valor na VIEW 
   * 
   */
  onChangeValue(){
    
    super.writeValue(this.value);
    this.onChange(this.value);

    if(this.updateDataItem != null){
      this._updateDataItem(this.value);
    }
    
  }
  _updateDataItem(value){

    let item = null;
    
    for(let index = 0;index < this.data.length;index++) {
      
      if(value == this.data[index].value){
        item = this.data[index];
        break;
      } 
      
    }
    if(item != null){
      this.updateDataItem.emit(item);
    }else{
      this.updateDataItem.emit({
        text: null,
        value: null
      }); 
    }

  }
  /**
   * 
   * Filter
   * 
   */
  filteredData(){
 
    this.comboFilter = this.comboCtrl.valueChanges
    .pipe(
      startWith(''),
      map(state => state ? this._filterData(state) : this.data.slice())
    );

  }
  /**
   * 
   * Fitra
   * 
   */
  private _filterData(value: string) {
    
    const filterValue = this.app.removerAcentos(value.toLowerCase());

    return this.data.filter(data => this.app.removerAcentos(data.text).toLowerCase().indexOf(filterValue) != -1);
  
  }
  openedChange(opened: boolean) {
    
  }
  /**
   * 
   * Adiciona o item do retorno do btn add
   * 
   */
  putDataBtn(item){
    if(this.isOpenSelect){
      this.getData(false);
      //this.data.push(item);
    }
  }
  /**
   * 
   * Init select
   * 
   */
   initSelect(){

    let self = this;

    if(typeof(self.select) != "undefined"){ 

      if(!this.isImg){
    
        $(self.select.nativeElement).val(self.value).select2({
          allowClear: false
        });

      }else{

        $(self.select.nativeElement).val(self.value).select2({
          allowClear: false,
          templateResult: (state) => {

            if (!state.id) {
              return state.text;
            }
            var baseUrl = self.url;
            var $state = $(  
              '<span><img src="' + baseUrl + '/' + state.element.value + '" width="'+self.imgWidth+'" /> ' + state.text + '</span>'
            );
            return $state;

          }
        });

      }

      $(self.select.nativeElement).on("select2:select", function (e) {
        
        let value = $(this).val();        

        self.value = value; 
        self._change();  
      
      });

    }

  }
  /***
   * 
   * Verifica a mudança
   * 
   */
   _change(){

      
    let item = this._getDataItem();
    
    if(item != null){
      this.changeValue.emit(item);
    }else{
      this.changeValue.emit({
        text: null,
        value: null
      });  
    }
    this.onChangeValue();

  }
  /**
   * 
   * Get dataItem
   * 
   */
   _getDataItem(){

    let data = null;

    for(let index = 0; index < this.data.length; index++) {

      if(this.value == this.data[index]["id"]){

        data = this.data[index];
        break;

      }
      
    }

    return data;
  
  }
  /**
   * 
   * Update data
   * 
   */
  onData(){

    this.updateData.subscribe((data) => {

      alert(JSON.stringify(data));

    });

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){

    this.onData();
    if(this.initRequest){
      this.isOpenSelect = false; 
      this.getData();
    } 
    
  }
  /**
   * 
   * View loaded
   * 
   */
  ngAfterViewInit():void{
    this.initSelect();    
  }
  /**
   * 
   * On Changes
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any): void {

    if(changes.value){ 
      if(changes.value.currentValue == null || changes.value.currentValue == "null"){
        this.initSelect(); 
      }
    }

  }

}

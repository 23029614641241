<div class="body-content" [ngClass]="{'page-body-auth': isLogin}"> 
  <mat-drawer-container>
    <mat-drawer #drawer>
      <sidebar-default></sidebar-default>
    </mat-drawer>  
    <mat-drawer-content>
      <topo-layout [drawer]="drawer"></topo-layout>
      <div class="container-fluid">
        <router-outlet></router-outlet>  
      </div>  
    </mat-drawer-content>  
  </mat-drawer-container>
</div>
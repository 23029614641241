import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input("classCustom") classCustom = "";
  @Input("message") message = "";

  constructor(){ } 

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

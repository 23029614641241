import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';
import { FilterCustomPipe } from 'src/app/pipes/filter-custom.pipe';
import { TypePassengerTextPipe } from 'src/app/pipes/type-passenger-text.pipe';
import { EmptyPipe } from 'src/app/pipes/empty.pipe';
import { GetTarifaMinimaListaPipe } from 'src/app/pipes/get-tarifa-minima-lista.pipe';
import { FormatValorManagerPipe } from 'src/app/pipes/format-valor-manage.pipe';
import { DateExtensoPipe } from 'src/app/pipes/date-extenso.pipe';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    FilterCustomPipe,
    TypePassengerTextPipe,
    EmptyPipe,
    GetTarifaMinimaListaPipe,
    FormatValorManagerPipe,
    DateExtensoPipe
  ],
  exports: [
    SafeHtmlPipe,
    FilterCustomPipe,
    TypePassengerTextPipe,
    EmptyPipe,
    GetTarifaMinimaListaPipe,
    FormatValorManagerPipe,
    DateExtensoPipe 
  ],
  imports: [
    CommonModule
  ]
})
export class PipeModule { }

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'form-title',
  templateUrl: './form-title.component.html',
  styleUrls: ['./form-title.component.scss']
})
export class FormTitleComponent implements OnInit { 

  @Input("form") form:any;
  @Input("top") top            = false;
  @Output("reload") reload     = new EventEmitter();  
  @Input("isReload") isReload  = false;
  @Output("onClose")  onClose  = new EventEmitter();

  constructor(){ }  

  /**
   * 
   * Close
   * 
   */
  close(){

    this.form.dialog.close()

    this.onClose.emit(null);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){} 

}

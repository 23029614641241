<div class="dm-modal">
    <div class="dm-modal-header">
        <span *ngIf="!isLink">Não foi possível realizar o pagamento</span>
        <span *ngIf="isLink">Não foi possível gerar o link de pagamento</span>
    </div>    
    <div class="modal-body">
        <div class="icon-error">
            <i class="material-icons">cancel</i>
        </div> 
        <article class="file"> 
            <div class="center">  
              <span class="text">Nº da Reserva</span>
              <span class="number">{{file}}</span>
            </div>  
        </article>
        <article class="description" *ngIf="!isLink">
            A reserva foi gerada porém o(s) serviço(s) não serão agendados até a realização do pagamento. 
            Caso tenha dificuldades em realizar o pagamento, entre em contato conosco. 
        </article> 
        <article class="description" *ngIf="isLink">
            A reserva foi gerada porém não foi possível gerar o link para o pagamento. 
            Os serviço(s) não serão agendados até a realização do pagamento. 
            Caso tenha dificuldades em finalizar esta etapa, entre em contato conosco. 
        </article> 
        <article class="details" *ngIf="detalhes != null">
            <h3>Detalhes do erro:</h3>
            <p [innerHtml]="detalhes"></p>  
        </article>  
    </div>
    <div class="modal-footer">
      <div class="center">
        <button type="button" class="btn btn-one btn-icon" (click)="dialog.close()">
            <i class="material-icons">check_circle</i>
            <span>Tentar novamente</span>
        </button> 
      </div>
    </div>   
</div>
  
import { Component, Input, OnInit } from '@angular/core';

declare var CardInfo:any;

@Component({
  selector: 'payment-card',
  templateUrl: './payment-card.component.html',
  styleUrls: ['./payment-card.component.scss']
})
export class PaymentCardComponent implements OnInit {

  
  @Input("params") params:any       = {};
  @Input("total")   total           = 0;
  @Input("dataItem") dataItem:any   = null;
  @Input("maxInstallments") maxInstallments = null;  
  @Input("value") value             = null; 
  
  public brand = {
    logo: null,
    name: "",
    apelido: ""
  }

  constructor(
  ){}  

  /***
   * 
   * Inicializa o dataItem
   * 
   */
  initDataItem(){

    if(this.dataItem == null){
      
      this.dataItem = {
        number: "",  
        expiration_date: "",
        security_code: "",
        holder_name: "",
        installments: null
      }

    }  

  }
  /**
   * 
   * Change valor parcela
   * 
   */
  changeValueParcela(value){

    this.dataItem.installments = value; 

  }
  /**
   * 
   * Set max parceclas
   * 
   */
  setMaxParcelas(){

   

  }
  /**
   * 
   * Set brand
   * 
   */
  setBrand(){
  
    var cardInfo = new CardInfo(this.dataItem.number,{
      banksLogosPath: '/images/card-info/banks-logos/',
      brandsLogosPath: '/images/card-info/brands-logos/'
    });

    if(cardInfo != null){
      this.brand.logo    = cardInfo.brandLogoSvg;
      this.brand.name    = cardInfo.brandName;
      this.brand.apelido = cardInfo.brandAlias;
    }
     

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit():void{
    this.initDataItem(); 
  }

}

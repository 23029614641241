import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'user-form-search-grid',
  templateUrl: './user-form-search-grid.component.html',
  styleUrls: ['./user-form-search-grid.component.scss']
})
export class UserFormSearchGridComponent implements OnInit {

  public dataItem          = null;
  public comboType         = null;
  public comboCategory     = null;
  public showFilter        = false;
  public comboPartner      = null;
  @Output("change") change = new EventEmitter();
  public comboCategoria    = null; 
  public comboAtivo = [{
    value: "",
    text: "---"
  },{
    value: 'true',
    text: "Produtos Ativados"
  },{
    value: 'false',
    text: "Produtos desativados"
  }];
  public comboPayment = [{
    value: 'true',
    text: "Pago"
  },{
    value: 'false',
    text: "À Pagar"
  }];

  constructor(){} 

  /**
   * 
   * Clear
   * 
   */
  _clear(){

    this.initDataItem();
    this.sendFilter();

  }
  /**
   * 
   * Send emiter
   * 
   */
  sendFilter(){

    this.change.emit(this.dataItem);
    this.showFilter = false;

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){  

    this.dataItem = { 
      id: null,
      name: null,
      email: null
    }  

  }
  /**
   * 
   * Show
   * 
   */
  _showFilter(){

    this.showFilter = this.showFilter ? false : true;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }


}

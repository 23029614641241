import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialModalConfirmUpdateComponent } from 'src/app/components/financial/financial-modal-confirm-update/financial-modal-confirm-update.component';
import { PartialModule } from '../../partial/partial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormModule } from '../../form/form.module';
import { FinancialMovementSummaryTotalComponent } from 'src/app/components/financial/financial-movement-summary-total/financial-movement-summary-total.component';
import { FinancialMovementToolbarComponent } from 'src/app/components/financial/financial-movement-toolbar/financial-movement-toolbar.component';
import { FinancialAccountMovementSearchComponent } from 'src/app/components/grid/search/financial-account-movement-search/financial-account-movement-search.component';
import { PipeModule } from '../../pipe/pipe.module';
import { MaterialModule } from '../../material/material.module';
import { FinancialDescriptionColGridComponent } from 'src/app/components/financial/financial-description-col-grid/financial-description-col-grid.component';
import { FinancialMovementSituationComponent } from 'src/app/components/financial/financial-movement-situation/financial-movement-situation.component';


@NgModule({
  declarations: [
    FinancialDescriptionColGridComponent,
    FinancialModalConfirmUpdateComponent,
    FinancialMovementSummaryTotalComponent,
    FinancialMovementToolbarComponent,
    FinancialAccountMovementSearchComponent,
    FinancialMovementSituationComponent
  ],
  exports: [
    FinancialDescriptionColGridComponent,
    FinancialModalConfirmUpdateComponent,
    FinancialMovementSummaryTotalComponent,
    FinancialAccountMovementSearchComponent,
    FinancialMovementToolbarComponent,
    FinancialMovementSituationComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    FormsModule,
    ReactiveFormsModule,
    FormModule,
    PipeModule,
    MaterialModule 
  ]
})
export class FinancialModule { }

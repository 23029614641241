import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'cart-add-sucess-modal',
  templateUrl: './cart-add-sucess-modal.component.html',
  styleUrls: ['./cart-add-sucess-modal.component.scss']
})
export class CartAddSucessModalComponent implements OnInit {

  public name = null;

  constructor(
    public dialog: MatDialogRef<CartAddSucessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public router: Router
  ){

    this.name = this.data.name;

  }
  /**
   * 
   * To route
   * 
   */
  toRoute(){

    this.dialog.close();  
    this.router.navigateByUrl("/sale/cart");     

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

<div class="page-filter">
    <div class="page-filter-header" (click)="_showFilter()">
        <span>Filtrar</span>
        <i class="material-icons">open_with</i>
    </div>
    <div class="page-filter-content" *ngIf="showFilter">
        <form class="form form-custom" (submit)="sendFilter()">
            <div class="row">
                <div class="col-md-1">
                  <dm-input type="integer" [(ngModel)]="dataItem.id" label="Id:" name="id"></dm-input>
                </div> 
                <div class="col-md-1">
                    <dm-input type="integer" [(ngModel)]="dataItem.file" label="File:" name="file"></dm-input>
                </div>  
                <div class="col-md-2">
                    <dm-input [(ngModel)]="dataItem.name" label="Cliente:" name="name"></dm-input>
                </div>
                <div class="col-md-2">
                    <dm-input [(ngModel)]="dataItem.email" label="E-mail:" name="email"></dm-input>
                </div> 
                <div class="col-md-2">
                    <dm-input type="cpf" [(ngModel)]="dataItem.cpf" label="CPF:" name="cpf"></dm-input>
                </div> 
                <div class="col-md-2">  
                    <dm-input type="date" minDate="false" [maxDate]="dataItem.date_out" [(ngModel)]="dataItem.date_in" label="Data Inicial:" name="date_in"></dm-input>
                </div>
                <div class="col-md-2"> 
                    <dm-input type="date" [minDate]="dataItem.date_in" [(ngModel)]="dataItem.date_out" label="Data Final:" name="date_out"></dm-input>
                </div>  
            </div>
            <div class="row">
                <div class="col-md-2">     
                    <dm-combo-data [data]="comboPayment" [(ngModel)]="dataItem.status_payment" label="Status Pagamento:" name="status_payment"></dm-combo-data>
                </div> 
                <div class="col-md-3" *ngIf="user.acessoAdmin == 'true'">   
                    <dm-combo [text]="dataItem.user_name" (updateDataItem)="_changeValue($event)" [url]="comboUserManage" [(ngModel)]="dataItem.user_id" label="Vendedor / Usuário:" name="user_id"></dm-combo>  
                </div>    
            </div>    
            <div class="page-filter-footer">
                <div class="content">
                    <button type="submit" class="btn btn-one btn-icon">
                        <i class="material-icons">search</i>
                        <span>Buscar</span>
                    </button>
                    <button type="button" class="btn btn-two btn-icon" (click)="_clear()">
                        <i class="material-icons">clear</i>
                        <span>Limpar</span>
                    </button> 
                </div> 
            </div>    
        </form>     
    </div>     
</div> 

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'payment-error-modal',
  templateUrl: './payment-error-modal.component.html',
  styleUrls: ['./payment-error-modal.component.scss']
})
export class PaymentErrorModalComponent implements OnInit {

  public detalhes = null;
  public file     = null;
  public isLink   = false;

  constructor(
    public dialog: MatDialogRef<PaymentErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public d
  ){

    this.detalhes  = typeof(d.detalhes) ? d.detalhes : null; 
    this.file      = typeof(d.file) ? d.file : null; 
    this.isLink    = typeof(d.isLink) ? d.isLink : false; 

  }
  /***
   * 
   * Init
   * 
   * 
   **/
  ngOnInit():void{}

}

<div class="emails">
    <div class="content" *ngIf="data.length == 0">
        <span>Sem cadastro ainda.</span>
    </div>
    <div class="content" *ngIf="data.length > 0">
        <div class="telefone" *ngFor="let d of data">
            <span>{{d.phone}}</span>
            <span class="material-icons" (click)="copy(d.phone)" title="Clique para copiar esse telefone">
                file_copy
            </span>  
        </div>
    </div>
</div>    

import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-link',
  templateUrl: './app-payment-link.component.html',
  styleUrls: ['./app-payment-link.component.scss']
})
export class AppPaymentLinkComponent implements OnInit {

  public paymentLink = false;

  constructor(
    private elementRef:ElementRef,
  ){

    this.paymentLink = this.elementRef.nativeElement.getAttribute('paymentLink') ? this.elementRef.nativeElement.getAttribute('paymentLink') : false;

    alert(this.paymentLink); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { SaleService } from 'src/app/services/sale.service';
import { SaleAddSuccessModalComponent } from '../modal/sale-add-success-modal/sale-add-success-modal.component';

@Component({
  selector: 'sale-add',
  templateUrl: './sale-add.component.html',
  styleUrls: ['./sale-add.component.scss']
})
export class SaleAddComponent implements OnInit {

  @Input("id") id = null;
  public loader   = false;
  public dataItem:any = {
    id: null
  }

  constructor(
    private api: ApiService,
    private app: AppService,
    private sale: SaleService,
    private dialog: MatDialog
  ){}

  /**
   * 
   * Store
   * 
   */
  store(){

    try{

      
      this.loader = true;

      this.api.order().sale().store(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.sale.updateTotal.emit(response.data.sale);    
          this.dialog.open(
            SaleAddSuccessModalComponent,{
              width: "500px",
              data: { 
                name: response.data.product.name
              }
            } 
          );   

        }else if(response.status == 2){
          
          this.app.info("Esse produto não tem mais estoque.");

        }else{

          let error = this.api.formatError(response);
          this.app.info(error.message);

        }

      },(response) => {

        this.loader = false;

        let error = this.api.formatError(response);
        this.app.info(error.message);

      });

    }catch(e){

      this.loader = false;
      this.app.info(e.message);

    }

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      id: this.id 
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'cart-payment-modal',
  templateUrl: './cart-payment-modal.component.html',
  styleUrls: ['./cart-payment-modal.component.scss']
})
export class CartPaymentModalComponent implements OnInit {

  public id       = null;
  public loader   = false;
  public response = {
    data: null,
    status: null
  } 
  public cart    = null;
  public methods = null;

  constructor(
    public dialog: MatDialogRef<CartPaymentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private api: ApiService,
    private app: AppService
  ){

    this.id = this.data.id;

  }
  /**
   * 
   * Get data
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.sale().cart().get(this.id,true).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.cart            = response.data; 
          this.methods         = response.methods;
          this.response.status = 1;

        }else{

          this.response = {
            data: response.data,
            status: 500
          }

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response);
        this.response = {
          data: error.message,
          status: 500
        }

      });

    }catch(e){

      this.loader   = false;  
      this.response = {
        data: e.message,
        status: 1
      }

    }

  }
  /**
   * 
   * Payment success
   * 
   */
  _paymentSuccess(){

    this.dialog.close();

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.getData();  
  }

}

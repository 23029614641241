import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'financial-movement-situation',
  templateUrl: './financial-movement-situation.component.html',
  styleUrls: ['./financial-movement-situation.component.scss']
})
export class FinancialMovementSituationComponent implements OnInit {

  @Input("paid") paid = false;

  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

<div class="dm-combo-data form-custom">
    <label *ngIf="label">{{label}}</label>
    <select [(ngModel)]="value" name="_value" #select>
      <option [value]="value"  *ngIf="data.length == 0 && value != null">
        {{text}}
      </option>
      <option value="" *ngIf="showEmpty == true">----</option>
      <option *ngFor="let d of data" [value]="d.value">
        {{d.text}}
      </option>
    </select> 
</div>   
   
  
  
  
  
  
  
  
  
  
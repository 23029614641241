<div class="dm-modal">
    <div class="modal-title">
      <div class="dm-modal-close" (click)="dialog.close()">
        <i class="material-icons">cancel</i>
      </div>
    </div> 
    <div class="dm-modal-content">  
      <div class="modal-body">
        <p>
            O produto <strong class="bg-green">{{name}}</strong> foi adicionado ao carrinho.
        </p>  
        <br> 
        <p> 
            <strong>O que você deseja fazer agora?</strong>  
        </p>    
      </div>
      <div class="modal-footer">
        <div class="center">
            <button type="button" class="btn btn-three btn-icon" (click)="dialog.close()"> 
                <i class="material-icons">shopping_cart</i>
                <span>Continuar comprando</span> 
            </button> 
            <button type="button" class="btn btn-one btn-icon" (click)="toRoute('/sale/cart')"> 
                <i class="material-icons">attach_money</i>
                <span>Finalizar o Pedido</span> 
            </button>    
        </div>
      </div>   
    </div>
</div>
    
<div class="emails">
    <div class="content" *ngIf="data.length == 0">
        <span>Sem cadastro ainda.</span>
    </div>
    <div class="content" *ngIf="data.length > 0">
        <div class="email" *ngFor="let d of data">
            <span>{{d.email}}</span>
            <span class="material-icons" (click)="copy(d.email)" title="Clique para copiar esse email">
                file_copy
            </span>
        </div>
    </div>
</div>    

<div class="dm-modal">
    <div class="modal-body">
        <div class="icon-error">
            <i class="material-icons">cancel</i>
        </div> 
        <article class="description">
            A tentativa de gerar a reserva no sistema falhou.
        </article>
        <article class="details" *ngIf="detalhes != null">
            <h3>Detalhes do erro:</h3>
            <p [innerHtml]="detalhes"></p>  
        </article>  
    </div>
    <div class="modal-footer">
      <div class="center">
        <button type="button" class="btn btn-one btn-icon" (click)="dialog.close()">
            <i class="material-icons">check_circle</i>
            <span>Tentar novamente</span>
        </button> 
      </div>
    </div>   
</div>
  
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FinancialModalConfirmUpdateComponent } from '../components/financial/financial-modal-confirm-update/financial-modal-confirm-update.component';

@Injectable({
  providedIn: 'root'
})
export class FinancialService {

  constructor(
    private dialog: MatDialog
  ){}

   /**
   * 
   * Info
   * 
   */
  confirmUpdate( 
    fnCallback=null,
    params=null,
    self=null,
    width='500px',
    height='auto'
  ){
    
    this.dialog.open(FinancialModalConfirmUpdateComponent,{ 
      width: width,
      height: height,
      data:{
        self: self,
        params: params,
        fnCallback: fnCallback
      }
    });
    
  }

  
}

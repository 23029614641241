<section class="dm-grid grid-one {{classCustom}}" #content>
  <div class="dm-grid-toolbar">
     <button class="btn-grid-toolbar btn btn-grid-new" [hidden]="!configGrid.modal.content || !configGrid.actions.create" (click)="modal().new()">
        <i class="material-icons">
          add
        </i>
        <span>{{configGrid.toolbar.text_add}}</span>
     </button> 
     <dm-grid-delete *ngIf="configGrid.actions.delete" [url]="configGrid.url" tipo="2" [grid]="this" (reloadGrid)="getData()"></dm-grid-delete>
     <div *ngIf="configGrid.toolbar.templateHtml.length > 0">
        <div class="dm-toolbar-template-item" *ngFor="let d of configGrid.toolbar.templateHtml">
          <div [compileHtml]="d.html" [context]="d.context"></div>
        </div>  
     </div>
  </div>
  <div class="dm-grid-content form-custom"> 
    <div class="table-responsive"> 
      <table class="table">
        <thead>
          <tr>
            <th width="30px" *ngIf="configGrid.actions.delete">
              <mat-checkbox [(ngModel)]="checkAll" name="checkAll" class="dm-grid-mat-check"></mat-checkbox> 
            </th>  
            <th scope="col" *ngFor="let c of configGrid.colunas;let i=index" [width]="c.width">
              <span class="label" (click)="toOrder(c,i)">
                {{c.label}} 
                <i class="material-icons" *ngIf="c.order.ativo && c.order.dir == 'asc'">
                  arrow_drop_up
                </i>
                <i class="material-icons" *ngIf="c.order.ativo && c.order.dir == 'desc'">
                    arrow_drop_down
                </i>
              </span>  
              <span class="filter" [ngClass]="{'has-filter':hasFilter(c.field)}" *ngIf="c.filter" (click)="modal().filter(c)">
                <i class="material-icons">
                  filter_list
                </i>
              </span>  
            </th>
            <th></th>
          </tr>  
        </thead>
        <tbody>
          <tr *ngFor="let d of data;let i=index">
            <td vertical-align="center" *ngIf="configGrid.actions.delete"> 
              <mat-checkbox name="ids" [value]="d.id" class='checkBoxDeleteItem dm-grid-mat-check' [checked]="checkAll" #matCheckItem></mat-checkbox> 
            </td>   
            <td vertical-align="center" scope="row" *ngFor="let c of configGrid.colunas;">
              <span class="item" *ngIf="!c.template">{{d[c.field]}}</span>
              <div *ngIf="c.template"> 
                <div [compileHtml]="c.template" [grid]="this" [data]="d" [dataItem]="dataItemColuna(c.field,d[c.field],d,i)" [context]="c.context"></div>
              </div>
            </td>  
            <td width="100px" align="center">
              <div class="td-actions">
                <button class="btn-grid-toolbar btn btn-grid-edit" [hidden]="!configGrid.modal.content || !configGrid.actions.update" (click)="modal().edit(d)">
                  <i class="material-icons">
                    edit 
                  </i>
                </button> 
                <button class="btn btn-search btn-grid-toolbar btn-grid-search" [hidden]="!configGrid.modalView.content" (click)="modal().view(d)">
                  <i class="material-icons">
                    search
                  </i>
                </button> 
                <dm-grid-delete [url]="configGrid.url" [id]="d.id" (reloadGrid)="getData()" *ngIf="configGrid.actions.delete"></dm-grid-delete>
              </div>
            </td>  
          </tr>  
        </tbody>  
      </table>
    </div> 
  </div>
  <div class="dm-grid-footer">
    <div class="left">
      <dm-grid-paginate [pageLimit]="configGrid.paginate.pageLimit" [pageNumber]="configGrid.paginate.pageNumber" [total]="total" (changePage)="changePage($event)" (changePageLimit)="changePageLimit($event)"></dm-grid-paginate>
    </div>
    <div class="right">
      <div class="reload" (click)="getData()">
        <i class="material-icons">
          loop
        </i>
      </div>
      <div class="total">
        <span>{{(configGrid.paginate.pageLimit*configGrid.paginate.pageNumber)+1}}-{{((configGrid.paginate.pageLimit*configGrid.paginate.pageNumber)+configGrid.paginate.pageLimit)}} de  {{total}}</span>
      </div> 
    </div>   
  </div>  
</section>
<loader [hidden]="!loader"></loader>

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Input } from '@angular/core';

@Component({
  selector: 'dm-grid-form-filter',
  templateUrl: './dm-grid-form-filter.component.html',
  styleUrls: ['./dm-grid-form-filter.component.scss']
})
export class DmGridFormFilterComponent implements OnInit {
  
  public title        = "Filtro";
  public dataItem:any =  {}
  public dataFim:any  = null;

  constructor(
    public dialog:MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  /**
   * 
   * Seta o dataItem para o filtro
   * 
   * 
   */
  filtrar(){

    let dataItem = Object.assign({},this.dataItem); 
    this.data.self.setFilter(dataItem);

  }
  /**
   * 
   * Tira o dataItem do filtro
   * 
   */
  clear(){

    this.data.self.removeFilter(this.dataItem.field);

  }
  /**
   * 
   * Fecha o Modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa o dataItem
   * 
   * 
   */
  initDataItem(){

    let operador = null;
  
    switch (this.data.dataItem.type) {
      case "integer":
        operador = "=";
      break;
      case "text":
        operador = "%_%"; 
      break;
      case "data_cart":
        operador = ">=";
      break;
      default:
        operador = "=";
      break;
    }
    
    this.dataItem = {
      operador: operador, 
      value: null,
      field: this.data.dataItem.field,
      type: this.data.dataItem.type
    }

    if(this.data.dataItem.type == "data_cart"){
      
      this.dataItem.field    = "data_cart";
      this.dataItem.type     = "date";
      this.dataItem.operador = ">=";
      
      this.dataFim = { 
        operador: "<=", 
        value: null,
        field: "data_cart_fim",
        type: "date",
      }

    }  

    this.setDataItemByFilter();


  }
  /**
   * 
   * Verifica se tem valores no filter
   * 
   */
  setDataItemByFilter(){

    if(this.data.filters.length > 0){

      for(var i = 0; i < this.data.filters.length; i++) {
        if(this.data.dataItem.field == this.data.filters[i].field){
          this.dataItem = this.data.filters[i];
          break;
        }  
      }

    }

  }
  /**
   * 
   * Inicializaq as Funções
   * 
   * 
   */
  ngOnInit() {
    this.initDataItem();
  }

}

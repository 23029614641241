import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'cart-steps',
  templateUrl: './cart-steps.component.html',
  styleUrls: ['./cart-steps.component.scss']
})
export class CartStepsComponent implements OnInit {

  @Input("dataItem") dataItem = null;  
  public step   = 1;
  public loader = false;

  constructor(
    public api: ApiService,
    public app: AppService,
    public modal: ModalService
  ){}

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{} 

}

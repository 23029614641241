import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-message',
  templateUrl: './modal-message.component.html',
  styleUrls: ['./modal-message.component.scss']
})
export class ModalMessageComponent implements OnInit {

  public message:any = "";

  constructor(
    public dialog: MatDialogRef<ModalMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ){ 

    this.message = typeof(this.data.message) != "undefined" ? this.data.message : "";

  }
  /**
   * 
   * Close modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){}

}

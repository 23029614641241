<div class="fn-movimentacao-toolbar">
    <div class="row row-center">
        <div class="col-md-4">
          <div class="periodo"> 
            <strong>{{params.date_start | dateExtenso}}</strong>
            <span>à</span> 
            <strong>{{params.date_end | dateExtenso}}</strong>
          </div>
        </div>
        <div class="col-md-5">
          <form class="form form-4"> 
            <ul>
              <li>
                <dm-input minDate="false" maxDate="false" type="date" [(ngModel)]="dataItem.date_start" label="Data Início"  name="date_start"></dm-input>
              </li>
              <li>
                <dm-input minDate="false" maxDate="false" type="date" [(ngModel)]="dataItem.date_end" label="Data Fim"  name="date_end"></dm-input>
              </li>
              <li>
                <button class="btn-one" (click)="filtrar()">
                  <span>FILTRAR</span>
                  <i class="material-icons">search</i>
                </button>  
              </li>
            </ul>
          </form>  
        </div>  
        <div class="col-md-3">
          <div class="navigation">
            <div class="prev item">
              <button type="button" class="btn btn-two" (click)="clickNavigation('prev')">
                <mat-icon>skip_previous</mat-icon>
              </button>  
            </div> 
            <div class="next item">
              <button type="button" class="btn btn-two" (click)="clickNavigation('next')">
                <mat-icon>skip_next</mat-icon>
              </button>  
            </div>  
          </div>    
        </div>
  </div>
</div>
    
<div [ngClass]="{'is-button-add':add}" class="form-custom">
    <label *ngIf="label">{{label}}</label>
    <div class="content"> 
        <div class="combo-content-input" (click)="clickGetData($event)"> 
            <select [(ngModel)]="value" [name]="name" #select [disabled]="disabled">
                <option [value]="value"  *ngIf="data.length == 0 && value != null">
                    {{text}}
                </option>
                <option value="">
                    <span>----</span> 
                </option>
                <option *ngFor="let d of data" [value]="d.value">
                    <span *ngIf="!isImg">{{d.text}}</span>
                    <div *ngIf="isImg">  
                        <img src="{{url}}/{{d.value}}" [width]="imgWidth" />
                        <span>{{d.text}}</span> 
                    </div>
                </option>
            </select>
            <div class="alert alert-info loader-message-combo" *ngIf="loader"> 
                Carregando...
            </div>   
        </div>  
        <button type="button" class="btn btn-one btn-reload" (click)="getData()" *ngIf="reload">
            <i class="material-icons">refresh</i> 
        </button>
        <div [ngSwitch]="add" *ngIf="add" class="content-button">  
            <btn-user-group [dataParams]="dataParams" class="button-add" (stored)="putDataBtn($event)" *ngSwitchCase="'user-profile'"></btn-user-group> 
            <add-financial-account-category [dataParams]="dataParams" class="button-add" (stored)="putDataBtn($event)" *ngSwitchCase="'financial-account-category'"></add-financial-account-category>
            <add-person [dataParams]="dataParams" class="button-add" (stored)="putDataBtn($event)" *ngSwitchCase="'person'"></add-person>
            <add-bank [dataParams]="dataParams" class="button-add" (stored)="putDataBtn($event)" *ngSwitchCase="'bank'"></add-bank>
        </div>
    </div>
</div>    
   

  



  



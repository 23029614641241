import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

declare var $:any; 

@Component({
  selector: 'combo-installments',
  templateUrl: './combo-installments.component.html',
  styleUrls: ['./combo-installments.component.scss']
})
export class ComboInstallmentsComponent implements OnInit {

  @Input("label") label              = "Parcelas:";
  @Output("changeValue") changeValue = new EventEmitter();
  @Input("max") max                  = 12;
  @Input("total") total              = 0;
  @ViewChild('select') select:ElementRef;
  public data   = [];
  public loader = false;
  @Input("value") value  = null;
  public response = {
    data: "",
    status: null
  }
  public parcelas = [];

  constructor(){
     
  }
  
  /**
   * 
   * 
   * 
   */
  clickValue(parcela){
 
    this.changeValue.emit(parcela); 

  }
  /**
   * 
   * Populaas parcelas
   * 
   * 
   */
  populaParcelas(){
    
    this.parcelas = [];
    
    for (var index = 1; index <= this.max; index++) {
          
      this.parcelas.push(index);
         
    }
    
  }
  /**
   * 
   * Init select
   * 
   * 
   */
  initSelect(){

    let self = this;
    
    $(this.select.nativeElement).val(this.value).select2({
      allowClear: false
    });
    $(this.select.nativeElement).on("select2:select", function (e) {

      let value = $(this).val();        
      self.value = value;
      self._change();  
  
    });

  }
  /**
   * 
   * Get dataItem
   * 
   */
  _getDataItem(){

    let data = null; 

    for(let index = 0; index < this.data.length; index++) {

      if(this.value == this.data[index]["value"]){  

        data = this.data[index];
        break;

      }
      
    }

    return data;

  }
   /***
   * 
   * Verifica a mudança
   * 
   */
  _change(){

    this.changeValue.emit(this.value);

  }
  /**
   * 
   * Inicializa o dataItem
   * 
   * 
   */
  ngOnInit(){
    this.populaParcelas();
  }
  /***
   * 
   * After
   * 
   */
  ngAfterViewInit(): void {
    this.initSelect();
  }

}

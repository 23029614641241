export class Routes {

    public static BASE  = "/api";
    public static _BASE = "/";

    public static auth = {
        login: Routes.BASE+"/auth/login",
        logout: Routes.BASE+"/auth/logout"
    }
    public static logs = {
        log: {
            url: Routes.BASE+"/logs/log"
        }
    }
    public static flight = {
        airport: {
            url: Routes.BASE+"/flight/airport",
            combo: Routes.BASE+"/flight/airport/combo"
        },
        airline: {
            url: Routes.BASE+"/flight/airline",
            combo: Routes.BASE+"/flight/airline/combo"
        }
    }
    public static report = {
        dashboard: {
            financial: {
                movement_summary: {
                    url: Routes.BASE+"/report/dashboard/financial/movement-summary"  
                },
                movement_year: {
                    url: Routes.BASE+"/report/dashboard/financial/movement-year"  
                }
            },
            home: {
                url: Routes.BASE+"/report/dashboard/home"  
            },
            register: {
                url: Routes.BASE+"/report/dashboard/register"  
            },
            sale_month_year: { 
                url: Routes.BASE+"/report/dashboard/sale-month-year"     
            },
            sale_services: { 
                url: Routes.BASE+"/report/dashboard/sale-services"     
            },
            sale_categories: { 
                url: Routes.BASE+"/report/dashboard/sale-categories"     
            },
            sale_list_users: { 
                url: Routes.BASE+"/report/dashboard/sale-list-users"     
            }
        }
    }
    public static products = {
        product: {
            list: {
                url: Routes.BASE+"/products/list"
            },
            show: {
                url: Routes.BASE+"/products/product"
            },
            types: {
                url: Routes.BASE+"/products/types"
            },
            disponibility: {
                url: Routes.BASE+"/products/disponibility"
            },
            hotels: {
                url: Routes.BASE+"/products/hotels"
            }
        }
    }
    public static paymentLink = {
        order: {
            url: Routes.BASE+"/payment-link/order" 
        }
    }
    public static order = {
        product: {
            url: Routes.BASE+"/order/product"
        },
        order_seller: {
            url: Routes.BASE+"/order/order-seller"
        },
        order_seller_product: {
            url: Routes.BASE+"/order/order-seller-product"
        },
        order_seller_status: {
            combo: Routes.BASE+"/order/order-seller-status/combo"
        },
        order_type: {
            url: Routes.BASE+"/order/order-type",
            combo: Routes.BASE+"/order/order-type/combo"
        },
        sale: {
            url: Routes.BASE+"/order/sale"
        },
        order: {
            url: Routes.BASE+"/order/order"
        },
        order_product: {
            url: Routes.BASE+"/order/order-product"
        },
        order_situation: {
            url: Routes.BASE+"/order/order-situation",
            combo: Routes.BASE+"/order/order-situation/combo"
        }   
    }
    public static sale = {
        sale: {
            url: Routes.BASE+"/sale/sale"
        },
        payment_link: {
            url: Routes.BASE+"/sale/payment-link"
        },
        cart: {
            url: Routes.BASE+"/sale/cart",
            download: Routes.BASE+"/sale/cart/download",
            print: Routes.BASE+"/sale/cart/print",
            share_email: Routes.BASE+"/sale/cart/share/email",
            new: Routes.BASE+"/sale/cart/new"  
        },
        order: {
            order: {
                url: Routes.BASE+"/sale/order",
                reopen: Routes.BASE+"/sale/order/reopen",
                user_by_cpf: Routes.BASE+"/sale/order/user-by-cpf",
                voucher:{
                    download: Routes.BASE+"/sale/order/voucher/download",
                    email: Routes.BASE+"/sale/order/voucher/email"
                }
            },
            payment: { 
                url: Routes.BASE+"/sale/order/payment"
            }
        }
    }
    public static purchase = {
        order_person: {
            url: Routes.BASE+"/purchase/order-person"
        },
        order_person_status: {
            combo: Routes.BASE+"/purchase/order-person-status/combo"
        },
        order: {
            url: Routes.BASE+"/purchase/order"
        }
    }
    public static register = {
        payment: {
            paymentType: {
                combo: Routes.BASE+"/register/payment/payment-type/combo"
            }
        },
        user: {
            combo: Routes.BASE+"/register/user/combo", 
            show: Routes.BASE+"/register/user/show",
            profile: Routes.BASE+"/register/user/profile",
            url: Routes.BASE+"/register/user"
        },
        product: {
            category: {
                combo: Routes.BASE+"/register/product/category/combo", 
                url: Routes.BASE+"/register/product/category"
            },
            collection: {
                combo: Routes.BASE+"/register/product/collection/combo", 
                url: Routes.BASE+"/register/product/collection" 
            },
            product: {
                url: Routes.BASE+"/register/product/product", 
                combo: Routes.BASE+"/register/product/product/combo"
            },
            photo: {
                url: Routes.BASE+"/register/product/photo"
            },
            support: {
                url: Routes.BASE+"/register/product/support"
            },
            support_status: {
                combo: Routes.BASE+"/register/product/support-status/combo"
            }
        },
        person: {
            person: {
                combo: Routes.BASE+"/register/person/person/combo",
                combo_client: Routes.BASE+"/register/person/person/combo?id=1",
                combo_provider: Routes.BASE+"/register/person/person/combo?id=2",
                combo_transport: Routes.BASE+"/register/person/person/combo?id=3",
                combo_seller: Routes.BASE+"/register/person/person/combo?id=4",
                url: Routes.BASE+"/register/person/person"
            }
        },
        ibge: {
            city: {
                combo: Routes.BASE+"/register/ibge/city/combo"
            }
        }
    }
    public static financial = {
        accountMovement: {
            category: {
                combo: Routes.BASE+"/financial/account-category/combo",
                url: Routes.BASE+"/financial/account-category"
            },
            movement: {
                url: Routes.BASE+"/financial/account-movement"
            },
        },
        account_movements: {
            url: Routes.BASE+"/financial/account-movements" 
        },
        bank: {
            combo: Routes.BASE+"/financial/bank/combo",
            url: Routes.BASE+"/financial/bank"
        },
        bank_account: {
            combo_movement: Routes.BASE+"/financial/bank-account/combo-movement",
            combo: Routes.BASE+"/financial/bank-account/combo",
            url: Routes.BASE+"/financial/bank-account"
        }
    }
    public static files = {  
        upload: Routes.BASE+"/files/upload", // POST
        read: Routes.BASE+"/files", // GET
        delete: Routes.BASE+"/files", // DELETE
        combo: Routes.BASE+"/files/combo", // GET
        crop: Routes.BASE+"/files/crop" // POST
    } 
    public static notification = {  
        partner: Routes.BASE+"/notification/partner"  
    } 
    public static inventory = {
        inventory: {
            combo: Routes.BASE+"/inventory/inventory/combo",
            url: Routes.BASE+"/inventory/inventory"
        },
        inventory_movement: {
            url: Routes.BASE+"/inventory/inventory-movement"
        },
        inventory_movement_type: {
            combo: Routes.BASE+"/inventory/inventory-movement-type/combo"
        }
    }
    public static crm = {
        contact: {
            origin: {  
                combo: Routes.BASE+"/crm/contact/contact-origin/combo",
                url: Routes.BASE+"/crm/contact/contact-origin" 
            },
            contact: {
                combo: Routes.BASE+"/crm/contact/contact/combo",
                url: Routes.BASE+"/crm/contact/contact" 
            },
            activity: {
                combo: Routes.BASE+"/crm/contact/activity/combo",
                url: Routes.BASE+"/crm/contact/activity" 
            }
        },
        funnel: {
            contacts: {
                url: Routes.BASE+"/crm/funnel/contacts" 
            },
            stage:{
                combo: Routes.BASE+"/crm/funnel/funnel-stage/combo",
                url: Routes.BASE+"/crm/funnel/funnel-stage" 
            },
            funnel:{
                combo: Routes.BASE+"/crm/funnel/funnel/combo",
                url: Routes.BASE+"/crm/funnel/funnel" 
            }
        }
    }
    public static user = {
        user: {
            combo: Routes.BASE+"/user/user/combo",
            url: Routes.BASE+"/user/user"  
        },
        group: {
            combo: Routes.BASE+"/user/group/combo",
            url: Routes.BASE+"/user/group" 
        },
        level: {
            combo: Routes.BASE+"/user/level/combo",
            url: Routes.BASE+"/user/level" 
        }
    }

}
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { ModalService } from 'src/app/services/modal.service';
import { CartShareModalComponent } from '../../modal/cart-share-modal/cart-share-modal.component';

@Component({
  selector: 'cart-share-btn',
  templateUrl: './cart-share-btn.component.html',
  styleUrls: ['./cart-share-btn.component.scss']
})
export class CartShareBtnComponent implements OnInit {

  @Input("id") id = null;

  constructor(
    private modal: ModalService,
    private api: ApiService,
    private app: AppService,
    private dialog: MatDialog
  ){}  

  /**
   * 
   * Open
   * 
   */
  open(){

    this.dialog.open(CartShareModalComponent,{
      width: "650px",
      data: {
        id: this.id
      }
    });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

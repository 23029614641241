import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Input, Inject } from '@angular/core';

@Component({
  selector: 'dm-grid-confirm-delete',
  templateUrl: './dm-grid-confirm-delete.component.html',
  styleUrls: ['./dm-grid-confirm-delete.component.scss']
})
export class DmGridConfirmDeleteComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<DmGridConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  /**
   * 
   * Confirmed Delete
   * 
   */
  sendDelete(){

    this.data.delete(this.data);

  }
  /**
   * 
   * Close Modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Inicializa as Funções 
   * 
   */
  ngOnInit(){

  }

}

<div class="cart-item">
    <div class="box">
        <div class="image" *ngIf="item.image == null" [ngStyle]="{'background-image': 'url(/images/produto/sem-foto/brocker.jpg)'}">
        </div>
        <div class="image" *ngIf="item.image != null" [ngStyle]="{'background-image': 'url(' + item.image + ')'}">
        </div>    
    </div>
    <div class="box box-name center-flex">
        <div class="description">
            <h3 class="title-h3">{{item.name}}</h3>
            <span class="code">Código: {{item.manage_id}}</span>
        </div>
    </div>
    <div class="box center-flex">
        <div class="info">
            <div class="item">
                <i class="material-icons">event</i>
                <span>{{item.date}}</span>
            </div> 
            <div class="item" *ngIf="item.qtd_adt > 0">
                <i class="material-icons">person</i>
                <span>{{item.qtd_adt}} Adulto(s)</span>
            </div> 
            <div class="item" *ngIf="item.qtd_snr > 0">
                <i class="material-icons">person</i>
                <span>{{item.qtd_snr}} Melhor Idade</span>
            </div> 
            <div class="item" *ngIf="item.qtd_chd > 0">
                <i class="material-icons">person</i>
                <span>{{item.qtd_chd}} Criança(s)</span>
            </div>
            <div class="item" *ngIf="item.qtd_inf > 0">
                <i class="material-icons">person</i>
                <span>{{item.qtd_inf}} Cortesia</span>
            </div>    
        </div>
    </div> 
    <div class="box box-total center-flex">
        <div class="box-total-content">
            <div class="total">
                <span>Valor</span><br>
                <strong>{{item.total | currency: " R$ "}}</strong>
            </div>
            <cart-delete-btn *ngIf="template == 'default'" [id]="item.id" [name]="item.name"></cart-delete-btn>
        </div>   
    </div>    
</div>    

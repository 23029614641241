import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateService } from 'src/app/services/date.service';

@Component({
  selector: 'financial-movement-toolbar',
  templateUrl: './financial-movement-toolbar.component.html',
  styleUrls: ['./financial-movement-toolbar.component.scss']
})
export class FinancialMovementToolbarComponent implements OnInit {

  @Input("params") params                = null;  
  @Output("setNavigation") setNavigation = new EventEmitter();
  @Output("setDates") setDates           = new EventEmitter();
  public dataItem:any = {};
  
  constructor(
    private DateService: DateService 
  ){ 


  }
  /**
  * 
  * Formata as datas
  * 
  */
  formatDates(){

    this.dataItem            = Object.assign({},this.params);
    this.dataItem.date_start = this.DateService.format(this.params.date_start,2);
    this.dataItem.date_end   = this.DateService.format(this.params.date_end,2);

  }
  /**
   * 
   * @param option 
   * 
   */
  clickNavigation(option){

    this.setNavigation.emit(option);

  }
  /**
   * 
   * Filtra por periodo
   * 
   */
  filtrar(){

    this.setDates.emit(this.dataItem);

  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit() {
    this.formatDates();
  }
  /**
   * 
   * On changes
   * 
   * @param changes 
   * 
   */
  ngOnChanges(changes:any){

    if(changes.params){

      if(changes.params.currentValue){
        this.formatDates();
      }
      

    }

  }

}

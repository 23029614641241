import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmComboDataComponent } from 'src/app/components/combo/dm-combo-data/dm-combo-data.component';
import { DmComboComponent } from 'src/app/components/combo/dm-combo/dm-combo.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DmComboCityComponent } from 'src/app/components/combo/dm-combo-city/dm-combo-city.component';
import { DmComboStateComponent } from 'src/app/components/combo/dm-combo-state/dm-combo-state.component';

@NgModule({
  declarations: [
    DmComboDataComponent,
    DmComboComponent,
    DmComboCityComponent,
    DmComboStateComponent
  ],
  exports: [  
    DmComboDataComponent, 
    DmComboComponent,
    DmComboCityComponent,
    DmComboStateComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ComboModule { }

<div class="cart-add">
    <div class="content" [ngSwitch]="step">
        <div class="content-item" *ngSwitchCase="'2'">
            <h4 class="title-h4">Tipo de Serviço</h4>
            <div class="content-types" *ngIf="dataTypes.status == 1"> 
                <div class="linha"></div>  
                <div class="list-type" *ngIf="dataTypes.data.length > 0">
                    <div class="list-type-item" *ngFor="let item of dataTypes.data;let i=index;">
                        <div class="dm-check-box">
                            <span class="dm-check-box-input" [ngClass]="{'active':item.idTipoServico == dataItem.type_service_id}" (click)="dataItem.tipe_service_id=item.idTipoServico;dataItem.tipe_service=item.tipoServico;"></span>
                            <span class="dm-check-box-value"><strong>{{item.tipoServico}}</strong> <span class="dm-check-box-info">- A partir de: {{item.lista | getTarifaMinimaLista | currency: "R$ "}}</span></span>
                        </div> 
                        <div class="lista-tipo-veiculos" *ngIf="item.idTipoServico == 2 && dataItem.tipo_servico_id == 2">
                            <hr>
                            <div class="lista-veiculos">
                                <div class="veiculo" *ngFor="let veiculo of item.lista;">
                                  <div class="dm-check-box">
                                    <span class="dm-check-box-input" [ngClass]="{'active':veiculo.idTipoTransporte == dataItem.type_transport_id}" (click)="dataItem.type_transport_id=veiculo.idTipoTransporte;dataItem.type_transport=veiculo.tipoTransporte;dataItem.maximum_capacity=veiculo.lotacaoMaxima;"></span>
                                    <span class="dm-check-box-value">
                                      {{veiculo.tipoTransporte}} 
                                      <span class="dm-check-box-info"> 
                                        - Valor: {{veiculo.tarifaMinima | formatValorManager | currency:"R$ "}} - Lotação Máxima: {{veiculo.lotacaoMaxima}} lugares
                                      </span>  
                                    </span>
                                  </div>   
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cart-item-navigation"> 
                <div class="item"></div>
                <button type="button" title="Próximo" (click)="next()" class="btn btn-one">
                    <span>Próximo</span>
                    <i class="material-icons">navigate_next</i>
                </button> 
            </div>  
        </div> 
        <div class="content-item" *ngSwitchCase="'3'">
            <h4 class="title-h4">Local de encontro / Hospedagem</h4>
            <combo-product-hotels (changeValue)="_changeHotel($event)" [value]="dataItem.hotel_id" [text]="dataItem.hotel"></combo-product-hotels>
            <div class='input-address' *ngIf="dataItem.address_require">
                <div class="linha"></div>
                <dm-input [(ngModel)]="dataItem.address" label="Informe o Endereço:" name="address"></dm-input>
            </div>    
            <div class="cart-item-navigation">
                <button type="button" title="Anterior" (click)="prev()" class="btn btn-two btn-prev">
                    <i class="material-icons">arrow_back_ios</i>
                    <span>Anterior</span>
                </button> 
                <button type="button" title="Próximo" (click)="next()" class="btn btn-one">
                    <span>Próximo</span>
                    <i class="material-icons">navigate_next</i>
                </button> 
            </div> 
        </div> 
        <div class="content-item" *ngSwitchCase="'4'">
            <h4 class="title-h4">Escolha a Data de Utilização</h4>  
            <cart-date [disponibilidadeArray]="disponibilityArray" [value]="dataItem.date" (changeValue)="_changeDate($event)" [typeServiceId]="dataItem.type_service_id" [id]="product.id" [dateIn]="dateIn" [dateOut]='dateOut'></cart-date>
            <div class="cart-item-navigation">
                <button type="button" title="Anterior" (click)="prev()" class="btn btn-two btn-prev">
                    <i class="material-icons">arrow_back_ios</i>
                    <span>Anterior</span>
                </button> 
                <button type="button" title="Próximo" (click)="next()" class="btn btn-one">
                  <span>Próximo</span>
                  <i class="material-icons">navigate_next</i>
                </button> 
            </div> 
        </div>
        <div class="content-item content-flight" *ngSwitchCase="'5'">
            <h4 class="title-h4">Informações do Voo</h4>
            <div class="individual" *ngIf="!dataItem.service_in_out">  
                <cart-flight-data [airline]="dataItem.airline" [airlineCode]="dataItem.airline_code" [airport]="dataItem.airport" (changeFlightNumber)="changeFlightNumber($event)" [flightTime]="dataItem.flight_time" [flightNumber]="dataItem.flight_number" (changeAirline)="changeAirline($event)" (changeAirport)="changeAirport($event)" (changeFlightTime)="changeFlightTime($event)"></cart-flight-data>
            </div> 
            <div class="pacote" *ngIf="dataItem.service_in_out">
                <h4 class="title-h4">Dados de Chegada:</h4>
                <cart-flight-data [airline]="dataItem.airline" [airlineCode]="dataItem.airline_code" [airport]="dataItem.airport" (changeFlightNumber)="changeFlightNumber($event)" [flightTime]="dataItem.flight_time" [flightNumber]="dataItem.flight_number" (changeAirline)="changeAirline($event)" (changeAirport)="changeAirport($event)" (changeFlightTime)="changeFlightTime($event)"></cart-flight-data>
                <h4 class="title-h4">Dados de Partida:</h4>
                <cart-flight-data [showDate]="true" [minDate]="dataItem.date" (changeDate)="changeDate($event)" [date]="dataItem.date_out" [airline]="dataItem.airline_out" [airlineCode]="dataItem.airline_code_out" [airport]="dataItem.airport_out" (changeFlightNumber)="changeFlightNumber($event,2)" [flightTime]="dataItem.flight_time_out" [flightNumber]="dataItem.flight_number_out" (changeAirline)="changeAirline($event,2)" (changeAirport)="changeAirport($event,2)" (changeFlightTime)="changeFlightTime($event,2)"></cart-flight-data>
            </div>    
            <div class="cart-item-navigation">
                <button type="button" title="Anterior" (click)="prev()" class="btn btn-two btn-prev">
                    <i class="material-icons">arrow_back_ios</i>
                    <span>Anterior</span>
                </button> 
                <button type="button" title="Próximo" (click)="next()" class="btn btn-one">
                    <span>Próximo</span>
                    <i class="material-icons">navigate_next</i>
                </button> 
            </div> 
        </div> 
        <div class="content-item" *ngSwitchCase="'6'">
            <h4 class="title-h4">Informe a Quantidade de Passageiros</h4>
            <cart-passenger-qtd [dataItem]="dataItem" [product]="product" [qtd_adt]="dataItem.qtd_adt" [qtd_chd]="dataItem.qtd_chd" [qtd_snr]="dataItem.qtd_snr" [qtd_inf]="dataItem.qtd_inf" (changeValue)="_changeQtd($event)"></cart-passenger-qtd>
            <div class="cart-item-navigation">
                <button type="button" title="Anterior" (click)="prev()" class="btn btn-two btn-prev">
                    <i class="material-icons">arrow_back_ios</i>
                    <span>Anterior</span>
                </button> 
                <button type="button" title="Próximo" (click)="next()" class="btn btn-one">
                  <span>Próximo</span>
                  <i class="material-icons">navigate_next</i>
                </button> 
            </div> 
        </div> 
        <div class="content-item content-item-resumo" *ngSwitchCase="'7'">
            <h4 class="title-h4 title-resumo">Resumo da Venda</h4>
            <div class="box-resumo">
                <div class="item">
                    <div class="icon">
                        <i class="material-icons">local_shipping</i>
                    </div>    
                    <div class="text">
                        <span>Tipo de Serviço</span><br>
                        <strong>{{dataItem.type_service}}</strong>
                    </div>
                </div>
                <div class="item" *ngIf="!dataItem.hotel_fixed">
                    <div class="icon">
                        <i class="material-icons">bed</i>
                    </div>     
                    <div class="text">
                        <span>Hotel / Pousada</span><br>
                        <strong>{{dataItem.hotel}}</strong>
                    </div>
                </div>
                <div class="item">
                    <div class="icon">
                        <i class="material-icons">event</i>
                    </div>     
                    <div class="text">
                        <span>Data utilização</span><br>
                        <strong>{{dataItem.date}}</strong>
                    </div>
                </div>
                <div class="item" *ngIf="dataItem.airport !== '' && dataItem.airport !== null">
                    <div class="icon">
                        <i class="material-icons">flight_takeoff</i>
                    </div>  
                    <div class="text">
                        <span>Dados do Voo</span><br>
                        <strong>{{dataItem.airport}} - {{dataItem.airline}} - {{dataItem.flight_number}}<span *ngIf="dataItem.flight_time !== '' && dataItem.flight_time !== null"> - {{dataItem.flight_time}}</span></strong>
                    </div>
                </div> 
                <div class="item" *ngIf="dataItem.airport_out !== '' && dataItem.airport_out !== null">
                    <div class="icon">
                        <i class="material-icons">flight_takeoff</i>
                    </div>  
                    <div class="text">
                        <span>Dados do Voo de Partida</span><br>
                        <strong>{{dataItem.airport_out}} - {{dataItem.airline_out}} - {{dataItem.flight_number_out}}<span *ngIf="dataItem.flight_time_out !== '' && dataItem.flight_time_out !== null"> - {{dataItem.flight_time_out}}</span><span> - {{dataItem.date_out}}</span></strong>
                    </div>
                </div>    
                <div class="item">
                    <div class="icon">
                        <i class="material-icons">group</i>
                    </div>     
                    <div class="text">
                        <span>Passageiros</span><br>
                        <div class="passenger">
                            <div class="passenger-item" *ngIf="dataItem.qtd_adt > 0">
                                <strong>Adulto:</strong> <span>{{dataItem.qtd_adt}}</span>
                            </div> 
                            <div class="passenger-item" *ngIf="dataItem.qtd_snr > 0">
                                <strong>Melhor Idade:</strong> <span>{{dataItem.qtd_snr}}</span>
                            </div>  
                            <div class="passenger-item" *ngIf="dataItem.qtd_chd > 0">   
                                <strong>Criança:</strong> <span>{{dataItem.qtd_chd}}</span>
                            </div>
                            <div class="passenger-item" *ngIf="dataItem.qtd_inf > 0">
                                <strong>Cortesia:</strong> <span>{{dataItem.qtd_inf}}</span>
                            </div>     
                        </div>    
                    </div>
                </div>
                <div class="item">
                    <div class="icon"> 
                        <i class="material-icons">attach_money</i>
                    </div>     
                    <div class="text">
                        <span>Total do Investmento</span><br>
                        <strong>{{total | currency: " R$ "}}</strong>
                    </div>
                </div>
            </div>
            <div class="cart-item-navigation">
                <button type="button" title="Anterior" (click)="prev()" class="btn btn-two btn-prev">
                    <i class="material-icons">arrow_back_ios</i>
                    <span>Anterior</span>
                </button> 
                <button type="button" title="Próximo" (click)="store()" class="btn btn-one">
                    <i class="material-icons">shopping_cart</i>
                    <span>Adicionar ao Carrinho</span>
                </button> 
            </div>     
        </div>    
    </div>    
</div>    
<loader *ngIf="loader" [message]="message" classCustom="absolute"></loader>
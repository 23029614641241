import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'cart-delete-modal',
  templateUrl: './cart-delete-modal.component.html',
  styleUrls: ['./cart-delete-modal.component.scss']
})
export class CartDeleteModalComponent implements OnInit {

  public name = null;
  public id   = null;
  public loader = false;
  
  constructor(
    public dialog: MatDialogRef<CartDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private api: ApiService,
    private modal: ModalService,
    private cart: CartService
  ){

    this.name = this.data.name;
    this.id   = this.data.id;

  }

  /**
   * 
   * Deleting item cart
   * 
   */
  delete(){

    try{


      this.loader = true;

      this.api.sale().cart().delete(this.id).subscribe(response => {

        this.loader = false;

        if(response.status == 1){

          this.modal.open("O serviço "+this.name+" foi retirado do carrinho.");
          this.dialog.close();

          this.cart.updateData.emit(response.data);
          this.cart.updateTotal.emit(response.data.total);

        }else{

          let error = this.api.formatError(response);
          this.modal.open(error.message);

        }

      },(response) => {

        this.loader = false;

        let error = this.api.formatError(response);
        this.modal.open(error.message);

      });

    }catch(e){

      this.loader = false;
      this.modal.open("Houve um erro interno: "+e.message);

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}

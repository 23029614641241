<div class="page-filter">
    <div class="page-filter-header" (click)="_showFilter()">
        <span>Filtrar</span>
        <i class="material-icons">open_with</i>
    </div>
    <div class="page-filter-content" *ngIf="showFilter">
        <form class="form form-custom" (submit)="sendFilter()">
            <div class="row">
                <div class="col-md-1">
                  <dm-input type="integer" [(ngModel)]="dataItem.id" label="Id:" name="id"></dm-input>
                </div> 
                <div class="col-md-2">
                    <dm-input type="integer" [(ngModel)]="dataItem.file" label="File:" name="file"></dm-input>
                </div>  
                <div class="col-md-2">
                    <dm-input [(ngModel)]="dataItem.payment_id" label="Id do Pagamento:" name="payment_id"></dm-input>
                </div>
                <div class="col-md-4">
                    <dm-input type="uppercase" [(ngModel)]="dataItem.name" label="Cliente:" name="name"></dm-input>
                </div> 
                <div class="col-md-2">
                    <dm-combo-data (changeValue)="_changeStatus($event)" [data]="comboStatus" [(ngModel)]="dataItem.status" label="Status:" name="status"></dm-combo-data>
                </div>
            </div>    
            <div class="page-filter-footer">
                <div class="content">
                    <button type="submit" class="btn btn-one btn-icon">
                        <i class="material-icons">search</i>
                        <span>Buscar</span>
                    </button>
                    <button type="button" class="btn btn-two btn-icon" (click)="_clear()">
                        <i class="material-icons">clear</i>
                        <span>Limpar</span>
                    </button> 
                </div> 
            </div>    
        </form>     
    </div>     
</div> 

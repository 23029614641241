import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'sale-add-success-modal',
  templateUrl: './sale-add-success-modal.component.html',
  styleUrls: ['./sale-add-success-modal.component.scss']
})
export class SaleAddSuccessModalComponent implements OnInit {

  public name = null;

  constructor(
    public dialog: MatDialogRef<SaleAddSuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data, 
    public router: Router
  ){}

  /**
   * 
   * To route
   * 
   */
  toRoute(url){

    this.router.navigateByUrl(url); 
    this.dialog.close();

  }
  /**
   * 
   * Set Data
   * 
   * 
   */
  setData(){

    this.name = this.data.name; 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{

    this.setData();

  }

}

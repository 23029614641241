import { AppService } from './../../../services/app.service';
import { ApiService } from './../../../services/api.service';
import { DmGridComponent } from './../dm-grid/grid-one.component';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dm-grid-update-coluna',
  templateUrl: './dm-grid-update-coluna.component.html',
  styleUrls: ['./dm-grid-update-coluna.component.scss']
})
export class DmGridUpdateColunaComponent implements OnInit {

  @Input("url") url     = null;
  @Input("campo") campo = null; 
  @Input("value") value = null; 
  @Input("id") id       = null; 
  @Input("indexColuna") indexColuna   = null; 
  @Input("grid") grid:DmGridComponent = null;
  @Output("id") updateSucess = new EventEmitter();

  constructor(
    private api:ApiService,
    private app: AppService
  ) { }
  /**
   * 
   * 
   */
  update(value){

    try{
      
      this.grid.loader = true;
      let url = this.grid.configGrid.url;
      let dataItem = {
        id: this.id,
        in_grid: true
      };
      dataItem[this.campo] = value;

      this.api.request().update(url,dataItem).subscribe(resp => {

        this.grid.loader = false;
        let response:any = resp; 

        if(response.status == 1){
          this.value = value;
          this.grid.data[this.indexColuna][this.campo] = value;  
        }else{
          let error   = this.api.formatError(response);
          this.app.info(error.message);
        }


      },(response) => {

        this.grid.loader = false;
        let error   = this.api.formatError(response);
        this.app.info(error.message);

      });

    }catch(e){
      this.grid.loader = false;
    } 

  }
  /**
   * 
   * Inicializa o Data Item
   * 
   */
  ngOnInit(){
  
  }

}

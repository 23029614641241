import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as uuid from 'uuid';

@Component({
  selector: 'payment-order-passenger',
  templateUrl: './payment-order-passenger.component.html',
  styleUrls: ['./payment-order-passenger.component.scss']
})
export class PaymentOrderPassengerComponent implements OnInit {

  @Input("cart") cart = null;
  public items = [];
  public comboPassenger = {
    adt: [],
    chd: [],
    snr: [],
    inf: []
  }
  public ids = [];
  @Input("passengers") passengers = [];
  @Output("changeCombo") changeCombo = new EventEmitter();

  constructor(){}

  /***
   * 
   * Click
   * 
   **/    
   _clickPassenger(passenger,p){
   
    p.name      = passenger.name; 
    p.last_name = passenger.last_name; 
    p.document  = passenger.document; 

  }
  /***
   * 
   * Change passageiro
   * 
   */
   _changeInput(p){

    if(p.name !== "" && p.last_name !== "" && p.document !== ""){

      let status = false;
      let  i     = 0;

      for (let index = 0; index < this.comboPassenger[p.type].length; index++) {

        if(p._id == this.comboPassenger[p.type][index]._id){

          i      = index;
          status = true;

        }
        
      }
      if(status == true){
        this.comboPassenger[p.type][i] = p;
      }else{
        this.comboPassenger[p.type].push(p);
      }

      this.changeCombo.emit(this.comboPassenger);

    }

  }
  /**
   * 
   * Set titular
   * 
   */
   setTitular(passenger){

    for(let index = 0; index < this.passengers.length; index++) {
      
      for(let i = 0;i < this.passengers[index].passageiros.length;i++) {
        this.passengers[index].passengers[i].titular = false; 
      }

    }

    passenger.titular = true;    

  }
  /**
   * 
   * Set items of cart
   * 
   */
  setItems(){

    if(this.cart != null){

      this.items = this.cart.items;  

    }

  }
  /**
   * 
   * Set campos passageiros
   * 
   */
   setFieldsPassenger(){  

    let adt                  = 0;
    let snr                  = 0;
    let inf                  = 0;
    let chd                  = 0;
    let passengers           = [];
    let passengersCart       = [];
    let passenger            = null;
    let indexAdt             = 0;
    let indexChd             = 0;
    let indexSnr             = 0;
    let indexInf             = 0;

    for(let index = 0;index < this.cart.items.length;index++) {
      
      indexAdt  = 1;
      indexChd  = 1;
      indexSnr  = 1;
      indexInf  = 1; 

      this.ids              = [];
      passengers            = [];
      passenger             = null;
      passengersCart        = this.cart.items[index].passengers;
      adt                   = this.cart.items[index].qtd_adt;
      snr                   = this.cart.items[index].qtd_snr;
      inf                   = this.cart.items[index].qtd_inf;
      chd                   = this.cart.items[index].qtd_chd; 

      for(let index = 0;index < adt;index++) {
        
        passenger = this.getPassenger(passengersCart,"adt");

        if(passenger == null){
          
          passenger = {
            _id: uuid.v4(),
            name: "",
            last_name: "",
            phone: "",
            document: "",
            type: "adt",
            titular: false,
            index: indexAdt
          };

          passengers.push(passenger); 

        }else{
          passengers.push(passenger);
          this.ids.push(passenger.id); 
        }

        indexAdt++;

      }
      for(let index = 0;index < snr;index++) {

        passenger = this.getPassenger(passengersCart,"snr");
        
        if(passenger == null){
          
          passengers.push({
            name: "",
            last_name: "",
            phone: "",
            document: "",
            type: "snr",
            titular: false,
            index: indexSnr
          });

        }else{
          passengers.push(passenger);
          this.ids.push(passenger.id); 
        }  

        indexSnr++;
        
      }
      for(let index = 0;index < chd;index++) {

        passenger = this.getPassenger(passengersCart,"chd");

        if(passenger == null){
          
          passengers.push({
            name: "",
            last_name: "",
            phone: "",
            document: "",
            type: "chd",
            titular: false,
            index: indexChd
          });
        
        }else{
          passengers.push(passenger);
          this.ids.push(passenger.id); 
        }  

        indexChd++;
          
      }
      for(let index = 0;index < inf;index++) {

        passenger = this.getPassenger(passengersCart,"inf");

        if(passenger == null){

          passengers.push({
            name: "",
            last_name: "",
            phone: "",
            document: "",
            type: "inf",
            titular: false,
            index: indexInf
          });

        }else{
          passengers.push(passenger);
          this.ids.push(passenger.id); 
        }  

        indexInf++;
        
      }
     
      this.cart.items[index].passengers = passengers;   
      this.passengers.push({
        item: {
          name: this.cart.items[index].name,
          id: this.cart.items[index].id 
        },
        passengers: passengers   
      });

      this.setPassengerTitular();

    }

  }
  /**
   * 
   * Seta o passageiro titular
   * 
   */
   setPassengerTitular(){

    let ok = false;

    for (let index = 0; index < this.passengers.length; index++) {
      
      if(!ok){

        for (let i = 0;i < this.passengers[index].passengers.length;i++) {
          
          if(this.passengers[index].passengers[i].type == "adt" || this.passengers[index].passageiros[i].type == "snr"){
            this.passengers[index].passengers[i].titular = true;
            ok = true; 
          }
          if(ok){
            break; 
          }
          
        }     

      }else{
        break;
      }

      
    }


  }
  /**
   * 
   * Get passageiro
   * 
   */ 
   getPassenger(passageiros,type){

    let data = null;

    for(let index = 0;index < passageiros.length;index++) {
      
      if(passageiros[index].type == type){

        if(!this.isSelectedPassenger(passageiros[index].id)){ 

          data = passageiros[index];
          break;

        }

      }
      
    }

    return data;

  }
  /**
   * 
   * Passageiro selected
   * 
   */
   isSelectedPassenger(id){ 

    let status = false;

    for (let index = 0; index < this.ids.length; index++) {
      
      if(id == this.ids[index]){
        status = true;
        break;
      }
      
    }

    return status;
    
  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setItems();
    this.setFieldsPassenger();
  }

}

<div class="pagamento-cartao meio-pagamento form-custom">
    <div class="content-card">
        <div class="card card-frente">
            <div class="brand" *ngIf="brand.logo != null">
                <img [src]="brand.logo"  [alt]="brand.name" />
            </div>    
            <div class="footer">
                <div class="numero">
                    <span>{{dataItem.number}}</span>
                </div>  
                <div class="nome">
                    <span>{{dataItem.holder_name}}</span>
                </div>
                <div class="data-validade">
                    <span>{{dataItem.expiration_date}}</span>
                </div>
            </div>    
        </div> 
        <div class="card card-verso">
            <div class="faixa"></div>   
            <div class="code">
                <div class="code-faixa"></div>
                <span>{{dataItem.security_code}}</span>
            </div>    
        </div>    
    </div>    
    <div class="content">
        <form class="form-custom">
            <div class="input-box">
                <label class="label grey">Nome escrito no cartão</label>
                <input type="text" [(ngModel)]="dataItem.holder_name" name="holder_name" uppercase placeholder="Digite o nome escrito no cartão" /> 
            </div>     
            <div class="input-box">
                <label class="label grey">Número do cartão*</label>  
                <input type="text"[(ngModel)]="dataItem.number" name="number" integer placeholder="Digite o número do cartão" (input)="setBrand()" />
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="input-box">
                        <label class="label grey">Data de Validade</label>
                        <input type="text" [(ngModel)]="dataItem.expiration_date" name="expiration_date" placeholder="XX/XX" mask="99/99" /> 
                    </div> 
                </div>
                <div class="col-md-6">
                    <div class="input-box">
                        <label class="label grey">CVV</label>  
                        <input type="text"[(ngModel)]="dataItem.security_code" name="security_code" placeholder="XXX" integer maxlength="3" />
                    </div>
                </div>     
            </div>
            <div class="form-group">  
                <combo-installments [value]="dataItem.installments" [total]="total" (changeValue)="changeValueParcela($event)" [max]="maxInstallments"></combo-installments>
            </div> 
        </form>    
    </div> 
</div>


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';
import { SaleService } from 'src/app/services/sale.service';

@Component({
  selector: 'sale-item',
  templateUrl: './sale-item.component.html',
  styleUrls: ['./sale-item.component.scss']
})
export class SaleItemComponent implements OnInit {

  @Input("data") data:any            = null;
  @Input("template") template:any    = null;
  @Output("updateSale") updateSale   = new EventEmitter();
  @Output("updateTotal") updateTotal = new EventEmitter();
  public loader = false;

  constructor(
    private app: AppService,
    private api: ApiService,
    private sale: SaleService
  ){}

  /**
   * 
   * @param status 
   * 
   * @param self 
   * 
   **/
  deleteItem(status,self){

    self.loader = true;
    
    self.api.order().sale().cart().delete(self.data.id).subscribe(response => {

      self.loader = false;

      if(response.status = 1){

        self.sale.updateTotal.emit(response.data);  
        self.updateSale.emit(response.data); 

      }else{

        self.app.info("Houve algo errado ao excluir o item do carrinho. Tente novamente por favor.");     

      }

    },(response) => {

      self.loader = false;
      self.app.info("Houve algo errado ao excluir o item do carrinho. Tente novamente por favor.");      

    });


  }
  /***
   * 
   * Confirm delete
   *
   *  
   ***/
  _confirmDelete(){

    this.app.confirm(
      "Deseja excluir esse item do carrinho?",
      this,
      {
        fnCallback: this.deleteItem
      }
    );

  }
  /**
   * 
   * Update Quantity
   * 
   */
  updateQuantity(quantity){

    try{

      this.loader = true;

      this.api.order().sale().cart().updateQtd(this.data.id,quantity).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
            
          this.sale.updateTotal.emit(response.data.sale_total);  
          this.data.quantity = response.data.quantity;

        }else{

          let error   = this.api.formatError(response); 
          this.app.info(error.message);

        }

      },(response) => {

        this.loader = false;
        let error   = this.api.formatError(response); 

        this.app.info(error.message);

      });

    }catch(e){

      this.loader = false;
      this.app.info("Houve um erro: "+e.message);

    }

  }
  /**
   * 
   * More
   * 
   */
  more(){

    let quantity = this.data.quantity+1; 

    this.updateQuantity(quantity);

  }
  /**
   * 
   * Less
   * 
   **/
  less(){

    if(this.data.quantity > 1){

      let quantity = this.data.quantity-1; 

      this.updateQuantity(quantity);

    }else{

      this.app.info("A quantidade mínima deve ser 1."); 

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{} 

}

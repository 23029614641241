import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Routes } from 'src/app/routes/routes';

@Component({
  selector: 'financial-account-movement-search',
  templateUrl: './financial-account-movement-search.component.html',
  styleUrls: ['./financial-account-movement-search.component.scss']
})
export class FinancialAccountMovementSearchComponent implements OnInit {

  public dataItem          = null;
  public comboType         = null;
  public comboCategory     = null;
  public showFilter        = false;
  public comboPartner      = null;
  public comboPerson       = null;  
  @Output("change") change = new EventEmitter();

  constructor(){ }

  /**
   * 
   * Clear
   * 
   */
  _clear(){
  
    this.initDataItem();
    this.sendFilter();

  }
  /**
   * 
   * Send emiter
   * 
   */
  sendFilter(){

    this.change.emit(this.dataItem);
    this.showFilter = false;

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      id: null,
      name: "",
      partner_id: null,
      person_id: null,
      person_name: "",
      financial_account_category_id: null,
      financial_account_category_text: ""
    }

  }
  /**
   * 
   * Show
   * 
   */
  _showFilter(){

    this.showFilter = this.showFilter ? false : true;

  }
  /***
   * 
   * Change partner
   * 
   **/
  _changePartner(data){

    this.dataItem.partner_name = data.text;

  }
  /***
   * 
   * Change person
   * 
   **/
   _changePerson(data){

    this.dataItem.person_name = data.text;

  }
  /***
   * 
   * Change partner
   * 
   */
   _changeCategory(data){

    this.dataItem.financial_account_category_text = data.text;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    
    this.initDataItem();
    this.comboCategory = Routes.financial.accountMovement.category.combo;
    this.comboPerson   = Routes.register.person.person.combo;
  
  }

}

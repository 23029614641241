import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SaleService } from 'src/app/services/sale.service';

@Component({
  selector: 'sale-total-btn',
  templateUrl: './sale-total-btn.component.html',
  styleUrls: ['./sale-total-btn.component.scss']
})
export class SaleTotalBtnComponent implements OnInit {

  public qtd   = 0;
  public total = 0; 

  constructor(
    private router: Router,
    private sale: SaleService
  ){}

  /**
   * 
   * To cart
   * 
   */
  toCart(){

    this.router.navigateByUrl("/sale/cart"); 

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.sale.updateTotal.subscribe(data => {

      this.qtd   = data.quantity;
      this.total = data.total;   

    });
    
  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.onData();
  }

}

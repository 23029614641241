import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DmGridComponent } from 'src/app/components/grid/dm-grid/grid-one.component';
import { DmGridFormFilterComponent } from 'src/app/components/grid/dm-grid-form-filter/dm-grid-form-filter.component';
import { DmGridPaginateComponent } from 'src/app/components/grid/dm-grid-paginate/grid-paginate.component';
import { DmGridUpdateColunaComponent } from 'src/app/components/grid/dm-grid-update-coluna/dm-grid-update-coluna.component';
import { DmGridDeleteComponent } from 'src/app/components/grid/delete/dm-grid-delete/dm-grid-delete.component';
import { DmGridConfirmDeleteComponent } from 'src/app/components/grid/delete/dm-grid-confirm-delete/dm-grid-confirm-delete.component';
import { FormModule } from '../form/form.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PartialModule } from '../partial/partial.module';
import { MaterialModule } from '../material/material.module';
import { DirectiveModule } from '../directive/directive.module';
import { OrderFormSearchGridComponent } from '../../components/grid/search/order-form-search-grid/order-form-search-grid.component';
import { ComboModule } from '../combo/combo/combo.module';
import { UserFormSearchGridComponent } from '../../components/grid/search/user-form-search-grid/user-form-search-grid.component';
import { PaymentLinkStatusBtnComponent } from 'src/app/components/sale/payment/btn/payment-link-status-btn/payment-link-status-btn.component';
import { PaymentLinkSearchGridComponent } from '../../components/grid/search/payment-link-search-grid/payment-link-search-grid.component';
import { PersonEmailListComponent } from 'src/app/components/person/person-email-list/person-email-list.component';
import { PersonPhoneListComponent } from 'src/app/components/person/person-phone-list/person-phone-list.component';
import { ProductSupportStatusComponent } from 'src/app/components/product/product-support-status/product-support-status.component';
import { PurchaseOrderPersonStatusComponent } from 'src/app/components/purchase/purchase-order-person-status/purchase-order-person-status.component';
import { SaleOrderReportBtnComponent } from 'src/app/components/order/sale/sale-order-report-btn/sale-order-report-btn.component';

@NgModule({
  declarations: [
    DmGridComponent,
    DmGridFormFilterComponent,
    DmGridPaginateComponent,
    DmGridDeleteComponent,
    DmGridConfirmDeleteComponent,
    OrderFormSearchGridComponent,
    UserFormSearchGridComponent,
    PaymentLinkStatusBtnComponent,
    PaymentLinkSearchGridComponent,
    DmGridUpdateColunaComponent,
    PersonEmailListComponent,
    PersonPhoneListComponent,
    ProductSupportStatusComponent,
    PurchaseOrderPersonStatusComponent,
    SaleOrderReportBtnComponent
  ],
  exports: [
    DmGridComponent,
    DmGridFormFilterComponent, 
    DmGridPaginateComponent,
    DmGridDeleteComponent,
    DmGridConfirmDeleteComponent,
    OrderFormSearchGridComponent,
    UserFormSearchGridComponent,
    PaymentLinkStatusBtnComponent,
    PaymentLinkSearchGridComponent,
    DmGridUpdateColunaComponent,
    PersonEmailListComponent,
    PersonPhoneListComponent,
    ProductSupportStatusComponent,  
    PurchaseOrderPersonStatusComponent,
    SaleOrderReportBtnComponent
  ],
  imports: [
    MaterialModule,
    PartialModule,
    CommonModule,
    FormModule,
    FormsModule,
    ReactiveFormsModule,
    DirectiveModule,
    ComboModule 
  ],
  providers: [] 
})
export class GridModule { }

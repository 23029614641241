<div class="dm-modal">
  <div class="modal-title">
    <span class="text">Pedido: {{id}}</span>
    <div class="item item-close">
      <i class="material-icons" (click)="dialog.close()">close</i> 
    </div>
  </div> 
  <div class="modal-body" *ngIf="response.status == 1">
    <payment-order (success)="_paymentSuccess()" [methods]="methods" [cart]="cart"></payment-order> 
  </div>
  <div class="modal-body" *ngIf="response.status == 500">
    <page-error [detail]="response.data" (reload)="getData()"></page-error>
  </div>
</div>
<loader *ngIf="loader"></loader>
<div class="dm-combo md-select-custom not-placeholder form-custom" (click)="_getData()">
  <label>{{label}}</label>
  <div class="combo-content-input">
    <select [(ngModel)]="value" #select name="_value">
        <option [value]="value"  *ngIf="data.length == 0 && value != null">
          {{text}}
        </option>
        <option value="">----</option>
        <option *ngFor="let d of data" [value]="d.idHotelPousada">
          {{d.hotelPousada}}
        </option>
    </select>  
    <div class="loader-message-combo" *ngIf="loader">
      Carregando... Aguarde!
    </div>
  </div>
</div>  

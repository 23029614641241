import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'product-support-status',
  templateUrl: './product-support-status.component.html',
  styleUrls: ['./product-support-status.component.scss']
})
export class ProductSupportStatusComponent implements OnInit {

  @Input("id") id:any = null; 
  @Input("text") text:any = null;

  constructor(){}


  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
  }

}

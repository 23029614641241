<div class="form-toolbar">
    <button type="button" (click)="save()" class="btn-grid-one"> 
      <i class="material-icons">edit</i>
      <span>Salvar</span>
    </button> 
    <button type="button" (click)="save(true,false)"> 
      <i class="material-icons">edit</i>
      <span>Salvar E Fechar</span>
    </button> 
    <button type="button" (click)="save(false,true)" *ngIf="create" class="btn-grid-three">  
      <i class="material-icons">edit</i>
      <span>Salvar e Novo</span>
    </button> 
    <button type="button" class="btn-grid-four"  (click)="form.initDataItem()" [hidden]="form.data.actionForm == 'create'" *ngIf="create"> 
      <i class="material-icons">save</i>
      <span>Novo</span>
    </button> 
    <button type="button" (click)="close()" class="btn-close"> 
      <i class="material-icons">close</i>
      <span>Fechar</span>
    </button>  
</div>
  
<div class="dm-modal">
    <div class="modal-body">
        <div class="icon">
            <i class="material-icons">
                offline_pin
            </i>
        </div>
        <div class="content">  
            <h4 class="title-h4">O serviço</h4>  
            <h5 class="title-h3">{{name}}</h5>
            <h4 class="title-h4">foi adicionado ao carrinho :)</h4>
        </div>
        <div class="actions">
            <div class="item">
                <button type="button" class="btn btn-one" (click)="dialog.close()">
                    <i class="material-icons">shopping_cart</i>
                    <span>Continuar comprando</span>
                </button>    
            </div> 
            <div class="item">
                <button type="button" class="btn btn-two" (click)="toRoute()">
                    <i class="material-icons">paid</i>
                    <span>Finalizar a compra</span>
                </button>    
            </div>    
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-category-item',
  templateUrl: './product-category-item.component.html',
  styleUrls: ['./product-category-item.component.scss']
})
export class ProductCategoryItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

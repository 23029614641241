import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
 
  constructor(){}

  /***
   * 
   * Year
   * 
   * 
   ***/
  year(){

    let date = new Date();

    return date.getFullYear(); 

  }
  /**
   * 
   *  Retorna a data atual
   * 
   */
   now(separator="-"){

    let date = new Date();
    let dia  = date.getUTCDate() < 10 ? "0"+date.getUTCDate() : date.getUTCDate();
    let mes:any  = date.getUTCMonth()+1;
    mes  = mes < 10 ? "0"+mes : mes;

    return dia+separator+mes+separator+date.getFullYear();

  }
  /**
   * 
   * Format
   * 
   * @param date 
   * 
   * @param option 
   * @param separator 
   * @returns 
   * 
   */
  format(date,option=1,separator="-"){

    var d = date.split(separator);
    
    var ano = "";
    var mes = "";
    var dia = "";

    if(d[0].length == 4){
      ano = d[0];
      mes = d[1];
      dia = d[2];
    }else{
      ano = d[2];
      mes = d[1];
      dia = d[0];  
    }

    if(option == 1){
      return ano+separator+mes+separator+dia;
    }else{
      return dia+separator+mes+separator+ano;
    }

  }
  /**
     * 
     * Retorna a data por extenso
     * 
     */
   getDateExtensive(date,separator="-"){
        
    var d   = date.split(separator); 
    var ano = "";
    var mes = "";
    var dia = "";

    if(d[0].length == 4){
      ano = d[0];
      mes = d[1];
      dia = d[2];
    }else{
      ano = d[2];
      mes = d[1];
      dia = d[0];  
    }
      
    return dia+" "+this.getMonthExtensive(mes,true)+" "+ano;

  }
  /**
   * 
   * Retorna o mês por extenso
   * 
   * 
   */
   getMonthExtensive(mes,reduzido=false){


    mes = parseInt(mes)-1;

    var months = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ];

    if(reduzido == false){
      return months[mes];
    }else{
      return months[mes].substr(0,3);
    }

  }
  /**
   * 
   * Add Months
   * 
   */
  addMonths(dateValue=null,months,separator="/"){

    let _date = null;
    let year  = null;
    let month = null;
    let day   = null;

    if(dateValue != null){

      _date = dateValue.split("/");

      year  = _date[2];
      month = parseInt(_date[1])-1;
      day   = _date[0];
            
    }

    let date = _date == null ? new Date() : new Date(year,month,day);
    date.setMonth(date.getMonth()+months);   

    let dia      = date.getUTCDate() < 10 ? "0"+date.getUTCDate() : date.getUTCDate();
    let mes:any  = date.getUTCMonth()+1;
    mes          = mes < 10 ? "0"+mes : mes;

    return dia+separator+mes+separator+date.getFullYear();  

  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'modal-passenger-error',
  templateUrl: './modal-passenger-error.component.html',
  styleUrls: ['./modal-passenger-error.component.scss']
})
export class ModalPassengerErrorComponent implements OnInit {

  public data = {};

  constructor(
    public dialog: MatDialogRef<ModalPassengerErrorComponent>,
    @Inject(MAT_DIALOG_DATA) public d
  ){ 

    this.data = d.data;

  }
  /**
   * 
   * Close modal
   * 
   */
  close(){
    this.dialog.close();
  }
  /**
   * 
   * Get array
   * 
   */
  getArray(err){


    let result = [];

    for(let k in err){
      result.push(err[k][0]);
    }

    return result;
    
  }
  /**
   * 
   * Inicializa as Funções
   * 
   */
  ngOnInit(){}

}

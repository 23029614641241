import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'payment-link-status-btn',
  templateUrl: './payment-link-status-btn.component.html',
  styleUrls: ['./payment-link-status-btn.component.scss']
})
export class PaymentLinkStatusBtnComponent implements OnInit {

  @Input("status") status = 1;

  constructor() { }

  /**
   * 
   * Init 
   * 
   */
  ngOnInit():void{}

}
